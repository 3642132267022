import { fromJS, List, Map, MapOf } from 'immutable';
import {
  Cart,
  CART_TYPE,
  CartItemWithOffer,
  Customer,
} from 'mapado-ticketing-js-sdk';
import {
  generateOfferClusterMap,
  OfferCluster,
} from '../../../components/common/offerCluster';
import {
  CART_BOOKING_ERROR,
  CART_RECEIVED,
  DISCARD_CART,
  DISMISS_CART_BOOKING_ERROR,
  FETCH_CART_ENDED,
  FETCH_CART_STARTED,
  FETCH_CART_UPDATE,
  RESET_PENDING_TICKET_PRICE_QUANTITIES,
  SET_HAS_CART_ID_IN_STORAGE,
  SET_TICKET_PRICE_QUANTITY,
  UPDATE_COMMENT,
  UPDATE_CUSTOMERS,
} from '../action/CartActionTypes';
import { CartAtomId, ORIGIN_TYPE_ENV } from '../constants';
import {
  convertCartToTicketPriceQuantity,
  processTicketPriceQuantities,
  TicketPriceQuantity,
  TicketPriceQuantityInput,
  TicketPriceQuantityParameter,
} from '../utils/ticketPriceQuantities';

type BaseCartStateObjectType = Pick<
  BookingStateObject,
  | 'currentTicketPriceQuantities'
  | 'pendingTicketPriceQuantities'
  | 'currentCart'
  | 'currentCartUpdating'
  | 'offerClusterList'
  | 'noOfferCartItemWithOfferList'
  | 'convertToReservation'
  | 'hasCartIdInStorage'
  | 'bookingError'
  | 'mapado:error'
>;

const baseCartState = Map<BaseCartStateObjectType>({
  currentTicketPriceQuantities: List(),
  pendingTicketPriceQuantities: List(),
  currentCart: null,
  currentCartUpdating: false,
  offerClusterList: List(),
  noOfferCartItemWithOfferList: List(),
  convertToReservation: false,
  hasCartIdInStorage: null,
  bookingError: null,
  'mapado:error': null,
});

export const baseInnerState = Map({}).merge(
  baseCartState
) as InnerBookingStoreType;

function cartReceived(
  state: InnerBookingStoreType,
  receivedCart: Cart,
  originTypeEnv: ORIGIN_TYPE_ENV | null
): InnerBookingStoreType {
  // if a reservation has been taken online, the cart should be discarded.
  // a resetCart redux event has already been dispatched, but was handled in the
  // cart-checkout redux store, and not in the current store.
  // the issue only affects minisite. We should not send such a resetCart event
  // from the desk
  if (
    document.getElementById('contentContainerBottom') &&
    !document.getElementById('mpd-cart-checkout') &&
    receivedCart &&
    receivedCart.type === CART_TYPE.RESERVATION
  ) {
    return resetCart(state);
  }

  let cart = receivedCart;

  if (!cart.cartItemWithOfferList) {
    cart = cart.set('cartItemWithOfferList', List());
  }

  // @ts-expect-error Possible missunderstanding with `getCartDerivedState`
  const newState: InnerBookingStoreType = state.merge({
    currentCartUpdating: false,
    bookingError: null,
    'mapado:error': null,
    currentCart: cart,
    ...getCartDerivedState(originTypeEnv, cart),
  });

  return newState;
}

function resetCart(state: InnerBookingStoreType): InnerBookingStoreType {
  return state.set('currentCart', null);
}

function getCartDerivedState(
  originTypeEnv: ORIGIN_TYPE_ENV | null,
  cart: null | Cart
):
  | Record<string, never>
  | Pick<
      BookingStateObject,
      | 'currentTicketPriceQuantities'
      | 'offerClusterList'
      | 'noOfferCartItemWithOfferList'
    > {
  if (!cart) {
    return {};
  }

  const currentTicketPriceQuantities = convertCartToTicketPriceQuantity(
    cart,
    originTypeEnv
  );

  const offerClusterMap = generateOfferClusterMap(cart);

  return {
    currentTicketPriceQuantities,
    offerClusterList: offerClusterMap.get('offerClusterList'),
    noOfferCartItemWithOfferList: offerClusterMap.get(
      'noOfferCartItemWithOfferList'
    ),
  };
}

function setTicketPriceQuantity(
  state: InnerBookingStoreType,
  ticketPriceQuantityList:
    | Array<TicketPriceQuantityParameter>
    | List<TicketPriceQuantityParameter>,
  convertToReservation = false
): InnerBookingStoreType {
  const immutableTicketPriceQuantityMaybeObjectList: List<TicketPriceQuantityParameter> =
    !List.isList(ticketPriceQuantityList)
      ? List(ticketPriceQuantityList)
      : ticketPriceQuantityList;

  const immutableTicketPriceQuantityList: List<TicketPriceQuantityInput> =
    immutableTicketPriceQuantityMaybeObjectList.map(
      (item: TicketPriceQuantityParameter): TicketPriceQuantityInput =>
        (Map.isMap(item) ? item : fromJS(item)) as TicketPriceQuantityInput
    ) as List<TicketPriceQuantityInput>;

  const pendingTicketPriceQuantities: List<TicketPriceQuantityInput> = state
    .get('pendingTicketPriceQuantities', List<TicketPriceQuantityInput>())
    .concat(immutableTicketPriceQuantityList);

  const currentTicketPriceQuantities = state.get(
    'currentTicketPriceQuantities'
  );

  const newTicketPriceQuantities: List<TicketPriceQuantityInput> =
    currentTicketPriceQuantities.concat(immutableTicketPriceQuantityList);

  const flattenedTicketPriceQuantities = processTicketPriceQuantities(
    newTicketPriceQuantities
  );

  const newState = state
    .set('currentTicketPriceQuantities', flattenedTicketPriceQuantities)
    .set('pendingTicketPriceQuantities', pendingTicketPriceQuantities)
    .set('currentCartUpdating', true)
    .set('convertToReservation', convertToReservation);

  return newState;
}

type BookingStateObject = {
  currentTicketPriceQuantities: List<TicketPriceQuantity>;
  pendingTicketPriceQuantities: List<TicketPriceQuantityInput>;
  currentCart: null | Cart;
  currentCartUpdating: boolean;
  offerClusterList: List<OfferCluster>;
  noOfferCartItemWithOfferList: List<CartItemWithOffer>;
  cartId: null | string;
  convertToReservation: boolean;
  hasCartIdInStorage: null | boolean;
  bookingError: null | string;
  'mapado:error': null | Map<string, unknown>;
};

export type InnerBookingStoreType = MapOf<BookingStateObject>;

/**
 * If an action does implements this interface, then the sub-reducer will not be the one
 * of the state.booking.currentCartAtomId, but the one of the given cartAtomId
 */
export interface HasCartAtomId {
  cartAtomId: CartAtomId;
}

export interface FETCH_CART_UPDATE_ACTION extends HasCartAtomId {
  type: typeof FETCH_CART_UPDATE;
}
interface CART_RECEIVED_ACTION extends HasCartAtomId {
  type: typeof CART_RECEIVED;
  cart: Cart;
}
interface CART_BOOKING_ERROR_ACTION extends HasCartAtomId {
  type: typeof CART_BOOKING_ERROR;
  error: null | string;
  'mapado:error'?: null | Record<string, unknown>;
}
interface DISMISS_CART_BOOKING_ERROR_ACTION extends HasCartAtomId {
  type: typeof DISMISS_CART_BOOKING_ERROR;
}
interface RESET_PENDING_TICKET_PRICE_QUANTITIES_ACTION extends HasCartAtomId {
  type: typeof RESET_PENDING_TICKET_PRICE_QUANTITIES;
}

export interface SET_TICKET_PRICE_QUANTITY_ACTION extends HasCartAtomId {
  type: typeof SET_TICKET_PRICE_QUANTITY;
  ticketPriceList:
    | Array<TicketPriceQuantityParameter>
    | List<TicketPriceQuantityParameter>;
  convertToReservation?: boolean;
}

interface DISCARD_CART_ACTION extends HasCartAtomId {
  type: typeof DISCARD_CART;
}
interface UPDATE_COMMENT_ACTION extends HasCartAtomId {
  type: typeof UPDATE_COMMENT;
  comment: null | string;
}

interface UPDATE_CUSTOMERS_ACTION extends HasCartAtomId {
  type: typeof UPDATE_CUSTOMERS;
  customer: Customer;
  contact: Customer | null;
}
export interface FETCH_CART_STARTED_ACTION extends HasCartAtomId {
  type: typeof FETCH_CART_STARTED;
}
interface FETCH_CART_ENDED_ACTION extends HasCartAtomId {
  type: typeof FETCH_CART_ENDED;
}
interface SET_HAS_CART_ID_IN_STORAGE_ACTION extends HasCartAtomId {
  type: typeof SET_HAS_CART_ID_IN_STORAGE;
  hasCartIdInStorage: boolean;
}

export type InnerBookingActionTypes =
  | FETCH_CART_UPDATE_ACTION
  | CART_RECEIVED_ACTION
  | CART_BOOKING_ERROR_ACTION
  | DISMISS_CART_BOOKING_ERROR_ACTION
  | RESET_PENDING_TICKET_PRICE_QUANTITIES_ACTION
  | SET_TICKET_PRICE_QUANTITY_ACTION
  | DISCARD_CART_ACTION
  | UPDATE_COMMENT_ACTION
  | UPDATE_CUSTOMERS_ACTION
  | FETCH_CART_STARTED_ACTION
  | FETCH_CART_ENDED_ACTION
  | SET_HAS_CART_ID_IN_STORAGE_ACTION;

/**
 * This is the old reducer that did work fine with only one cart.
 * As the new reducer is multi-cart, we keep this one and decorate it with the
 * new `bookingReducer` method.
 * Everything in here still works fine on one cart
 */
export default function innerBookingReducer(
  state: InnerBookingStoreType,
  action: InnerBookingActionTypes,
  originTypeEnv: ORIGIN_TYPE_ENV | null
): InnerBookingStoreType {
  switch (action.type) {
    case FETCH_CART_UPDATE:
      return state.set('pendingTicketPriceQuantities', List());

    case CART_RECEIVED:
      return cartReceived(state, action.cart, originTypeEnv);

    case CART_BOOKING_ERROR: {
      const bookingErrorState: Partial<BookingStateObject> = {
        bookingError: action.error,
        'mapado:error': action['mapado:error']
          ? (fromJS(action['mapado:error']) as Map<string, unknown>)
          : null,
        currentCartUpdating: false,
        ...getCartDerivedState(originTypeEnv, state.get('currentCart')),
      };

      // @ts-expect-error issue with Map.merge type definition
      return state.merge(bookingErrorState);
    }

    case DISMISS_CART_BOOKING_ERROR:
      // @ts-expect-error issue with Map.merge type definition
      return state.merge({
        bookingError: null,
        'mapado:error': null,
      });

    case RESET_PENDING_TICKET_PRICE_QUANTITIES:
      return state.set('pendingTicketPriceQuantities', List());

    case SET_TICKET_PRICE_QUANTITY:
      return setTicketPriceQuantity(
        state,
        action.ticketPriceList,
        action.convertToReservation
      );
    case DISCARD_CART:
      // @ts-expect-error issue with Map merge type definition
      return state.merge(baseCartState);
    case UPDATE_COMMENT:
      return state.setIn(['currentCart', 'comment'], action.comment);
    case UPDATE_CUSTOMERS:
      return state
        .setIn(['currentCart', 'customer'], action.customer)
        .setIn(['currentCart', 'contact'], action.contact);
    case FETCH_CART_STARTED:
      return state.set('currentCartUpdating', true);
    case FETCH_CART_ENDED:
      return state.set('currentCartUpdating', false);

    case SET_HAS_CART_ID_IN_STORAGE: {
      if (action.hasCartIdInStorage) {
        return state.set('hasCartIdInStorage', action.hasCartIdInStorage);
      }

      // @ts-expect-error issue with Map merge type definition
      const stateWithBaseCartState: InnerBookingStoreType =
        state.merge(baseCartState);

      return stateWithBaseCartState.set(
        'hasCartIdInStorage',
        action.hasCartIdInStorage
      );
    }

    default:
      return state;
  }
}
