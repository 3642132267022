import React from 'react';
import cn from 'classnames';
import { generateRandomID } from '../../js-libs/utils';

type Props = {
  id?: string;
  label?: string;
  required?: boolean;
};

function InputLabel({
  id,
  label,
  required = false,
}: Props): JSX.Element | null {
  const labelId = id ?? generateRandomID();

  if (!label) {
    return null;
  }

  return (
    <label htmlFor={labelId} className={cn(required && 'required')}>
      {label}
    </label>
  );
}

export default InputLabel;
