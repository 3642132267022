import React, { PureComponent, ReactNode, ReactElement } from 'react';
import { createPortal } from 'react-dom';
import Slidebox from '../js-libs/slidebox';
import MpdIcon from './MpdIcon';

type Props = {
  options: {
    onOpen: () => void;
    onClose: () => void;
    onAfterClose?: () => void;
    boxClassName?: string;
    animationType?: string;
  };
  closeBtnText: string;
  children: ReactNode;
};

type State = {
  isSlideboxOpened: boolean;
};

class MpdSlidebox extends PureComponent<Props, State> {
  #slideBoxRef: Element | null;

  constructor(props: Props) {
    super(props);

    this.state = {
      isSlideboxOpened: false,
    };

    this.#slideBoxRef = null;
  }

  componentDidMount(): void {
    const { options } = this.props;
    const slideboxOptions = {
      ...options,
      closeElementHtml: '',
      isReactPortal: true,
      onOpen: (url: string, element: Element) => {
        if (typeof options.onOpen === 'function') {
          options.onOpen();
        }

        this.#slideBoxRef = element;
        this.setState({
          isSlideboxOpened: true,
        });
      },
      onClose: () => {
        if (typeof options.onClose === 'function') {
          options.onClose();
        }
      },
    };

    Slidebox.openSlideboxElement(slideboxOptions);
  }

  componentWillUnmount(): void {
    Slidebox.destroy();
  }

  close(): void {
    Slidebox.close();
  }

  render(): ReactElement | null {
    const { isSlideboxOpened } = this.state;

    if (isSlideboxOpened === null || !this.#slideBoxRef) {
      return null;
    }

    return createPortal(
      <>
        <button
          type="button"
          className="mclosebtn mpd-slidebox__close"
          onClick={Slidebox.close}
        >
          <MpdIcon icon="cross" height="20" width="20" />
          {this.props.closeBtnText}
        </button>
        {this.props.children}
      </>,
      this.#slideBoxRef
    );
  }
}

export default MpdSlidebox;
