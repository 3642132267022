import URI from 'urijs';
import { Iri } from '../entity';
import PaymentAttempt from '../entity/PaymentAttempt';
import { TSMetadata } from '../mapping';
import getFieldsString from '../utils';
import AbstractClient, { FieldsParameter } from './AbstractClient';

class PaymentAttemptClient extends AbstractClient<
  TSMetadata['paymentAttempt']
> {
  getPathBase(): string {
    return '/v1/payment_attempts';
  }

  getToken(
    values: {
      order: Iri<'Order'>;
      amount: number;
      subscriptionMaturityList: Array<Iri<'SubscriptionMaturity'>>;
      redirectBaseUrl: string;
      cancelBaseUrl: string;
    },
    fields: FieldsParameter
  ): Promise<PaymentAttempt> {
    const url = URI(this.getPathBase());
    url.addSearch({ fields: getFieldsString(fields) });

    return this.deserializeResponse(
      this.authorizedFetch(url, {
        method: 'POST',
        body: JSON.stringify(values),
      }),
      'item'
    );
  }
}

export default PaymentAttemptClient;
