import '../styles/components/Legend.scss';
import React from 'react';
import cn from 'classnames';
// @ts-expect-error -- See https://github.com/microsoft/TypeScript/issues/33079#issuecomment-911893337
import { $primaryBlue } from '@mapado/makeup/variables';
import { List } from 'immutable';
import { MpdIcon } from '@mapado/makeup';
import { SeatGroupType } from '../propTypes';

type Props = {
  seatGroupList: List<SeatGroupType>;
  className?: string;
};

function SeatGroupList({ seatGroupList, className }: Props) {
  return (
    <>
      {seatGroupList.map((seatGroup) => (
        <div key={seatGroup['@id']} className={cn('legend-item', className)}>
          <MpdIcon
            icon="seat-filled"
            width="12"
            color={seatGroup.seatColor || $primaryBlue}
          />
          <span className="bold small ml2">{seatGroup.label}</span>
        </div>
      ))}
    </>
  );
}

export default SeatGroupList;
