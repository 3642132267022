import React, { ReactNode, useState } from 'react';
import {
  assertRelationIsListOfObject,
  assertRelationIsNullOrObject,
  assertRelationIsObject,
  assertRelationIsString,
  AvailableSeat,
  Cart,
  TicketPrice,
} from 'mapado-ticketing-js-sdk';
import { List } from 'immutable';
import { MpdIcon, MpdPopover } from '@mapado/makeup';
import {
  DOMAIN_CONTEXT,
  FormattedTicketPriceFacialValue,
  getCustomerFullname,
  getEntityId,
  useDomainContext,
} from '@mapado/js-component';
import cn from 'classnames';
import SidebarTemplate from './SidebarTemplate';
import '../../styles/components/PlanPurchaseSidebar.scss';
import { cartItemIsForEventDate } from '../../utils/cart';
import {
  getSeatGroupColorMap,
  getSeatGroupLabelMap,
} from '../../utils/selectors';
import { useSeatingSelector } from '../../reducers/typedFunctions';
import { useTranslation } from '../../i18n';
import { useViewContext, View } from '../../contexts/ViewContext';

interface Props {
  cart: Cart | null;
  eventDateId: number;
  getTicketPriceListBySeatGroupAndStockContingent: (
    seatGroup: string,
    stockContingent: string | null
  ) => List<TicketPrice>;
  handleChangeTicketPrice: (
    availableSeat: AvailableSeat,
    oldTicketPrice: TicketPrice,
    newTicketPrice: TicketPrice
  ) => void;
  sidebarPrevAction?: ReactNode;
  sidebarNextAction?: ReactNode;
  locale: string;
}

function SeatingPlanPurchaseSidebar({
  cart,
  getTicketPriceListBySeatGroupAndStockContingent,
  handleChangeTicketPrice,
  sidebarPrevAction,
  sidebarNextAction,
  eventDateId,
  locale,
}: Props) {
  const { t } = useTranslation();
  const { selectedView } = useViewContext();
  const { currentDomain } = useDomainContext();
  const [forceVisibleSidebar, setForceVisibleSidebar] = useState(false);
  const seatGroupLabelMap = useSeatingSelector(getSeatGroupLabelMap);
  const seatGroupColorMap = useSeatingSelector(getSeatGroupColorMap);

  if (!cart) {
    return null;
  }

  assertRelationIsListOfObject(cart.cartItemList, 'cart.cartItemList');

  const cartItemList = cart.cartItemList.filter((cartItem) =>
    cartItemIsForEventDate(cartItem, eventDateId)
  );

  if (!cartItemList.size) {
    return null;
  }

  const sidebarClassNames = cn('plan-purchase-sidebar', {
    'plan-purchase-sidebar--force-visible': forceVisibleSidebar,
  });

  return (
    <>
      {!forceVisibleSidebar && (
        <div className="plan-purchase-responsive-bar">
          {sidebarPrevAction}
          <button
            type="button"
            className="mpd-btn mpd-btn--primary"
            onClick={() => setForceVisibleSidebar(true)}
          >
            {t('next')}
          </button>
        </div>
      )}
      <SidebarTemplate
        className={sidebarClassNames}
        action={
          (sidebarPrevAction || sidebarNextAction) && (
            <>
              {!forceVisibleSidebar ? (
                sidebarPrevAction
              ) : (
                <button
                  type="button"
                  className="mpd-btn"
                  onClick={() => setForceVisibleSidebar(false)}
                >
                  {t('seating.plan_purchase.back_plan')}
                </button>
              )}
              {sidebarNextAction}
            </>
          )
        }
      >
        <div className="plan-purchase-sidebar__ticket-prices">
          <h3 className="mb3 txtcenter">
            {t('seating.plan_purchase.sidebar.title')}
          </h3>
          {cartItemList
            .filter((item) => {
              assertRelationIsListOfObject(
                item.availableSeatList,
                'item.availableSeatList'
              );

              const firstAvailableSeat = item.availableSeatList.first();
              // ignore cart items with no available seats (for example ticket price that does not generate a ticket)

              return !!firstAvailableSeat;
            })
            .map((cartItem) => {
              const { ticketPrice, stockContingent } = cartItem;
              const availableSeat = cartItem.availableSeatList?.first();

              assertRelationIsObject(ticketPrice, 'ticketPrice');
              assertRelationIsObject(availableSeat, 'availableSeat');

              const { seat, seatGroup } = availableSeat;

              assertRelationIsString(seatGroup, 'availableSeat.seatGroup');
              assertRelationIsObject(seat, 'availableSeat.seat');

              const ticketPriceList =
                getTicketPriceListBySeatGroupAndStockContingent(
                  seatGroup,
                  getEntityId(stockContingent)
                );

              assertRelationIsNullOrObject(
                cartItem.stockContingent,
                'cartItem.stockContingent'
              );

              const cartItemContingent =
                cartItem.stockContingent?.contingent ?? null;

              assertRelationIsNullOrObject(
                cartItemContingent,
                'cartItem.stockContingent?.contingent'
              );
              assertRelationIsNullOrObject(
                cartItem.participant,
                'cartItem.participant'
              );

              const seatParticipant = getCustomerFullname(cartItem.participant);
              const seatLabel = seat.label;
              const seatGroupLabel = seatGroupLabelMap.get(seatGroup);
              const seatGroupColor =
                seatGroupColorMap.get(seatGroup) ?? undefined;
              const seatContingentLabel = cartItemContingent?.name;
              const seatContingentColor =
                cartItemContingent?.color ?? undefined;

              return (
                <div key={cartItem['@id']} className="mb3">
                  <div className="mb1 plan-purchase-sidebar__seat-infos small mpd-color-gray">
                    {seatParticipant && (
                      <span className="ellipsis">{seatParticipant}</span>
                    )}
                    {seatLabel && <span>{seatLabel}</span>}
                    {selectedView === View.Contingent &&
                      currentDomain === DOMAIN_CONTEXT.DESK &&
                      !!seatContingentLabel && (
                        <span className="ellipsis">
                          <MpdIcon
                            icon="seat-filled"
                            width="14"
                            color={seatContingentColor}
                            className="mr1"
                          />
                          {seatContingentLabel}
                        </span>
                      )}
                    {(selectedView === View.SeatGroup ||
                      currentDomain === DOMAIN_CONTEXT.MINISITE) &&
                      !!seatGroupLabel && (
                        <span className="ellipsis">
                          <MpdIcon
                            icon="seat-filled"
                            width="14"
                            color={seatGroupColor}
                            className="mr1"
                          />
                          {seatGroupLabel}
                        </span>
                      )}
                  </div>
                  <MpdPopover
                    className="mpd-card ticket-price"
                    displayOnLeft
                    icon="arrow-down"
                    label={
                      <>
                        <span className="block mpd-color-black">
                          {ticketPrice.name}
                        </span>
                        <span className="h3-like">
                          <FormattedTicketPriceFacialValue
                            ticketPrice={ticketPrice}
                            locale={locale}
                          />
                        </span>
                      </>
                    }
                    labelClassName="mr2"
                  >
                    {ticketPriceList
                      // remove ticket prices that will not generate ticket (you can not assign a seat on them)
                      // and remove options ticket prices
                      .filter((tp) => {
                        assertRelationIsObject(
                          tp.bookingTrack,
                          'tp.bookingTrack'
                        );

                        return tp.willGenerateTicket === true;
                      })
                      .map((tp) => (
                        <button
                          key={tp.get('@id')}
                          type="button"
                          className="mpd-popover__link"
                          disabled={tp.remainingStock === 0}
                          onClick={() =>
                            handleChangeTicketPrice(
                              availableSeat,
                              ticketPrice,
                              tp
                            )
                          }
                        >
                          <div>{tp.name}</div>
                          <span className="h3-like">
                            <FormattedTicketPriceFacialValue
                              ticketPrice={tp}
                              locale={undefined}
                            />
                          </span>
                        </button>
                      ))}
                  </MpdPopover>
                </div>
              );
            })}
        </div>
      </SidebarTemplate>
    </>
  );
}

export default SeatingPlanPurchaseSidebar;
