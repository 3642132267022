import React, { FunctionComponent } from 'react';
import { components, ControlProps } from 'react-select';
import { SelectGroupedOption, SingleSelectOption } from '../common/utils';
import MpdIcon from '../../../MpdIcon';

type ControlPropsType = ControlProps<
  SingleSelectOption,
  false,
  SelectGroupedOption<SingleSelectOption>
>;

const Control: FunctionComponent<ControlPropsType> = (
  props: ControlPropsType
) => {
  const {
    children,
    selectProps: { autocomplete },
  } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.Control {...props}>
      {autocomplete && !props.selectProps.value && (
        <MpdIcon
          className="mpd-single-select-control__icon ml1"
          icon="magnifying-glass"
        />
      )}
      {children}
    </components.Control>
  );
};

export default Control;
