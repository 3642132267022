import { List } from 'immutable';
import { HttpError } from 'rest-client-sdk';
import { getEntityId } from '@mapado/js-component';
import TicketingSdk, {
  assertRelationIsDefined,
  assertRelationIsListOfObject,
  assertRelationIsNullOrObject,
  assertRelationIsObject,
  AvailableSeat,
  Cart,
  CartItem,
  CartItemWithOffer,
  CART_TYPE,
  Customer,
  Iri,
  CART_STATUS,
} from 'mapado-ticketing-js-sdk';
import {
  getAtomCart,
  getAtomTicketPriceQuantities,
  getAtomPendingTicketPriceQuantities,
} from '../../../selector';
import { CartAtomId, CartId } from '../constants';
import type { BookingActionTypes, BookingThunkAction } from '../reducer';
import {
  TicketPriceQuantity,
  TicketPriceQuantityInputAsObject,
  TicketPriceQuantityParameter,
} from '../utils/ticketPriceQuantities';
import {
  CART_BOOKING_ERROR,
  CART_RECEIVED,
  DISCARD_CART,
  RESET_PENDING_TICKET_PRICE_QUANTITIES,
  SET_TICKET_PRICE_QUANTITY,
  UPDATE_COMMENT,
  FREE_CART_ID,
  DISMISS_CART_BOOKING_ERROR,
  UPDATE_CUSTOMERS,
} from './CartActionTypes';
import { CREATE_UPDATE_CART_FIELDS } from './fields';

export function setTicketPriceQuantity(
  cartAtomId: CartAtomId,
  ticketPriceList:
    | Array<TicketPriceQuantityParameter>
    | List<TicketPriceQuantityParameter>,
  convertToReservation = false
): BookingActionTypes {
  return {
    type: SET_TICKET_PRICE_QUANTITY,
    ticketPriceList,
    convertToReservation,
    cartAtomId,
  };
}

export function removeCartItemFromCurrentCart(
  cartAtomId: CartAtomId,
  cartItemWithOfferList: List<CartItemWithOffer>
): BookingActionTypes {
  return setTicketPriceQuantity(
    cartAtomId,
    cartItemWithOfferList
      .map((ciwo): TicketPriceQuantityInputAsObject => {
        assertRelationIsObject(ciwo.ticketPrice, 'ciwo.ticketPrice');

        return {
          ticketPrice: ciwo.ticketPrice,
          quantity: 0,
        };
      })
      .toArray()
  );
}

const isHttpError = (e: Error | HttpError): e is HttpError =>
  'baseResponse' in e;

function cartError(
  cartAtomId: CartAtomId,
  error: Error | HttpError
): BookingThunkAction<Promise<BookingActionTypes>> {
  return async (dispatch) => {
    let resBody: null | Record<string, unknown> = null;
    let message: null | string = null;
    let mapadoError: null | Record<string, unknown> = null;

    if (isHttpError(error) && error.baseResponse) {
      if (!error.baseResponse.bodyUsed) {
        resBody = await error.baseResponse.json();
      } else {
        const { body } = error.baseResponse;

        // @ts-expect-error body is a string (is it ?)
        resBody = body;
      }

      if (resBody) {
        message = resBody['hydra:description'] as string;
        mapadoError = resBody['mapado:error'] as null | Record<string, unknown>;
      }
    } else {
      message = error.name;
    }

    return dispatch({
      type: CART_BOOKING_ERROR,
      cartAtomId,
      error: message,
      'mapado:error': mapadoError,
    });
  };
}

export function receiveCart(
  newCart: Cart,
  cartAtomId: CartAtomId
): BookingActionTypes {
  return {
    type: CART_RECEIVED,
    cart: newCart,
    cartAtomId,
  };
}

function resetCouponCodeListForCart(
  cart: Cart,
  cartAtomId: CartAtomId
): BookingThunkAction<void> {
  return (dispatch, getState, extraArguments) => {
    const func = cart.get('@id') ? 'update' : 'create';

    // @ts-expect-error window.ticketingSdk should be set
    window.ticketingSdk.cart[func](cart.set('couponCodeList', null), {
      fields: CREATE_UPDATE_CART_FIELDS,
    })
      .then((newCart: Cart) => {
        return dispatch(receiveCart(newCart, cartAtomId));
      })
      .catch((err2: Error | HttpError) =>
        cartError(cartAtomId, err2)(dispatch, getState, extraArguments)
      );
  };
}

/**
 * @param {TicketingSdk} ticketingSdk Should be defined. Fallback to `window.ticketingSdk` but this is not deprecated.
 */
export function createOrUpdateCart(
  cart: Cart,
  cartAtomId: CartAtomId,
  ticketingSdk?: undefined | TicketingSdk
): Promise<BookingActionTypes> {
  if (!ticketingSdk) {
    console.warn('ticketingSdk should be injected into `createOrUpdateCart`');
  }

  // @ts-expect-error window.ticketingSdk should be set
  const innerTicketingSdk = ticketingSdk || window.ticketingSdk;
  let couponedCart = cart;
  const couponCodeList =
    cart.couponCodeList && cart.couponCodeList.size > 0
      ? cart.couponCodeList
      : null;

  if (couponCodeList && couponCodeList.size > 0) {
    couponedCart = couponedCart.set('couponCodeList', couponCodeList);
  }

  const func = couponedCart.get('@id') ? 'update' : 'create';

  return innerTicketingSdk
    .getRepository('cart')
    [func](couponedCart, {
      fields: CREATE_UPDATE_CART_FIELDS,
    })
    .then((newCart: Cart) => receiveCart(newCart, cartAtomId))
    .catch((error: Error | HttpError) => {
      if (couponedCart.get('couponCodeList')) {
        return resetCouponCodeListForCart(couponedCart, cartAtomId);
      }

      return cartError(cartAtomId, error);
    });
}

export async function splitCart(
  parentCart: Cart,
  newCartItemList: List<CartItem>,
  sellingDeviceId: string,
  ticketingSdk: TicketingSdk,
  selectedCustomerId: Iri<'Customer'>,
  selectedContactId: Iri<'Customer'> | null
): Promise<Cart> {
  const currency = parentCart.get('currency');
  const customer = parentCart.get('customer');
  const cartItemList = newCartItemList.map((cartItem) => cartItem.get('@id'));

  assertRelationIsNullOrObject(customer, 'parentCart.customer');

  const newCart = new Cart({
    currency,
    type: CART_TYPE.RESERVATION,
    status:
      parentCart.type === CART_TYPE.CART
        ? CART_STATUS.VALIDATED // same behavior then "put aside". A cart become a "validated" reservation
        : parentCart.status, // if cart already was a reservation we keep it status
    customer: selectedCustomerId,
    contact: selectedContactId,
    // @ts-expect-error toJS is too lazy
    cartItemList: cartItemList.toJS(),
    couponCodeList: parentCart.couponCodeList,
    reservationName: parentCart.reservationName,
    comment: parentCart.comment,
    tagList: parentCart.tagList,
    sellingDevice: sellingDeviceId,
    parent: parentCart.get('@id'),
  });

  const createdCart = await ticketingSdk.getRepository('cart').create(newCart, {
    fields: '@id',
  });

  return createdCart;
}

export function processPendingTicketPriceQuantities(
  cartAtomId: CartAtomId
): BookingThunkAction<void> {
  return (dispatch, getState) => {
    const state = getState();
    const pendingTicketPriceQuantities =
      getAtomPendingTicketPriceQuantities(state, cartAtomId) ?? List();

    if (pendingTicketPriceQuantities.size === 0) {
      return null;
    }

    dispatch({
      type: RESET_PENDING_TICKET_PRICE_QUANTITIES,
      cartAtomId,
    });

    return dispatch(
      setTicketPriceQuantity(cartAtomId, pendingTicketPriceQuantities)
    );
  };
}

export function discardCart(cartAtomId: CartAtomId): BookingActionTypes {
  return {
    type: DISCARD_CART,
    cartAtomId,
  };
}

export function freeCartId(cartAtomId: CartId): BookingActionTypes {
  return {
    type: FREE_CART_ID,
    cartAtomId,
  };
}

export function discardOrDeleteCart(
  cartAtomId: CartAtomId
): BookingThunkAction<BookingActionTypes> {
  return (dispatch, getState) => {
    const state = getState();
    const cart = getAtomCart(state, cartAtomId);

    dispatch(discardCart(cartAtomId));

    if (cart) {
      const { type } = cart;
      const hasCartItemList =
        cart.cartItemWithOfferList && cart.cartItemWithOfferList.size > 0;

      if (
        !(type === CART_TYPE.RESERVATION && hasCartItemList) &&
        cart?.get('@id')
      ) {
        // @ts-expect-error window.ticketingSdk should be set
        return window.ticketingSdk
          .getRepository('cart')
          .delete(cart)
          .catch((err: Error) => {
            // eslint-disable-next-line no-console
            console.error(err.message);
          });
      }
    }

    return Promise.resolve();
  };
}

export function updateComment(
  cartAtomId: CartAtomId,
  comment: null | string
): BookingActionTypes {
  return { type: UPDATE_COMMENT, cartAtomId, comment };
}

export function updateCustomers(
  cartAtomId: CartAtomId,
  customer: Customer,
  contact?: Customer | null
): BookingActionTypes {
  return {
    type: UPDATE_CUSTOMERS,
    cartAtomId,
    customer,
    contact: contact ?? null,
  };
}

function cartItemIsForEventDate(
  cartItem: CartItem,
  eventDateId: number
): boolean {
  assertRelationIsObject(cartItem.ticketPrice, 'cartItem.ticketPrice');

  const { eventDate } = cartItem.ticketPrice;

  assertRelationIsObject(eventDate, 'cartItem.ticketPrice.eventDate');

  return eventDate.getShortId() === eventDateId.toString();
}

function ticketPriceQuantityIsForEventDate(
  ticketPriceQuantity: TicketPriceQuantity,
  eventDateId: number
): boolean {
  const ticketPrice = ticketPriceQuantity.get('ticketPrice');

  assertRelationIsObject(ticketPrice.eventDate, 'ticketPrice.eventDate');

  return ticketPrice.eventDate.getShortId() === eventDateId.toString();
}

interface AvailableSeatAndCartItem {
  availableSeat: AvailableSeat;
  cartItem: CartItem;
}

/**
 * This helper function inform `setTicketPriceQuantity` to lock all available seats
 * for the given event date id.
 * This way, no automatic replacement will happen.
 * This helper might be moved to js-component (and cart when actions are moved there) directly as an autonomous action
 * and might be handled in the reducer.
 */
export function lockSeatsForEventDate(
  cartAtomId: CartAtomId,
  eventDateId: number
): BookingThunkAction<Promise<BookingActionTypes> | BookingActionTypes> {
  return (dispatch, getState) => {
    const state = getState();

    // get only ticketPriceQuantities for current eventDate
    const currentTicketPriceQuantitiesForEventDate =
      getAtomTicketPriceQuantities(state, cartAtomId).filter(
        (ticketPriceQuantity) =>
          ticketPriceQuantityIsForEventDate(ticketPriceQuantity, eventDateId)
      );

    const cart = getAtomCart(state, cartAtomId);

    if (!cart) {
      // no cart : we can't lock anything
      return Promise.resolve() as unknown as BookingActionTypes;
    }

    assertRelationIsListOfObject(cart.cartItemList, 'cart.cartItemList');

    // get only cart items for current eventDate
    const cartItemListForEventDate = cart.cartItemList.filter((cartItem) =>
      cartItemIsForEventDate(cartItem, eventDateId)
    );

    // get ticket price quantity that have an available seat, ie. those who are explicitly locked
    const lockedTicketPriceQuantityList =
      currentTicketPriceQuantitiesForEventDate
        .map((ticketPriceQuantity) => ticketPriceQuantity.get('availableSeat'))
        .filter(
          (availableSeat: string | null): availableSeat is string =>
            availableSeat !== null
        );

    // extract all availableSeat from the list of cart item, return an object containing the availableSeat and the cart item
    const cartItemByAvailableSeat: List<AvailableSeatAndCartItem> =
      cartItemListForEventDate.flatMap((cartItem) => {
        const { availableSeatList } = cartItem;

        if (!availableSeatList || availableSeatList.size === 0) {
          // if there is no available seat, just return an empty list that will be filtered by the `flatMap` method
          return List<AvailableSeatAndCartItem>();
        }

        assertRelationIsListOfObject(
          availableSeatList,
          'cartItem.availableSeatList'
        );

        return availableSeatList.map((availableSeat) => ({
          availableSeat,
          cartItem,
        }));
      });

    const ticketPriceIdWithAvailableSeats = cartItemByAvailableSeat.map(
      ({ cartItem }) => {
        assertRelationIsObject(cartItem.ticketPrice, 'cartItem.ticketPrice');
        assertRelationIsDefined(cartItem.ticketPrice['@id'], 'ticketPrice.@id');

        return cartItem.ticketPrice['@id'];
      }
    );

    // get al lticket price quantity that are not already locked
    const notLockedTicketPriceQuantityList =
      currentTicketPriceQuantitiesForEventDate.filter((ticketPriceQuantity) => {
        const ticketPrice = ticketPriceQuantity.get('ticketPrice');

        assertRelationIsDefined(ticketPrice['@id'], 'ticketPrice.@id');

        return (
          !ticketPriceQuantity.get('availableSeat') &&
          ticketPriceIdWithAvailableSeats.includes(ticketPrice['@id'])
        );
      });

    // this is the number of ticket price quantity that are not locked, as we do not want to
    // lock extra cart items that might have been removed (the `currentTicketPriceQuantities` is the source of thruth here)
    const nbNotLockedTicketPriceQuantityList: number =
      notLockedTicketPriceQuantityList.reduce(
        (acc: number, ticketPriceQuantity: TicketPriceQuantity): number =>
          acc + ticketPriceQuantity.get('quantity'),
        0
      );

    // format the ticketPriceQuantityList that will be dispatched
    const ticketPriceQuantityList: Array<TicketPriceQuantityParameter> = [
      // unset all not locked ticket price quantity
      ...notLockedTicketPriceQuantityList.map((ticketPriceQuantity) => ({
        ticketPrice: ticketPriceQuantity.get('ticketPrice'),
        quantity: 0,

        groupKey: ticketPriceQuantity.get('groupKey'),
        stockContingent: ticketPriceQuantity.get('stockContingent'),
        data: ticketPriceQuantity.get('data'),
        participant: ticketPriceQuantity.get('participant'),
        availableSeat: null,
      })),

      // and lock them
      ...cartItemByAvailableSeat
        // we want only items that are not already locked
        .filter(({ availableSeat }: AvailableSeatAndCartItem): boolean => {
          assertRelationIsDefined(availableSeat['@id'], 'availableSeat.@id');

          return !lockedTicketPriceQuantityList.includes(availableSeat['@id']);
        })
        // do not take more available seats than the number of items that are not locked
        .slice(0, nbNotLockedTicketPriceQuantityList)
        // and convert that into a proper TicketPriceQuantityParameter
        .map(({ cartItem, availableSeat }): TicketPriceQuantityParameter => {
          const { ticketPrice, groupKey, stockContingent, data, participant } =
            cartItem;

          assertRelationIsObject(ticketPrice, 'cartItem.ticketPrice');
          assertRelationIsNullOrObject(
            stockContingent,
            'cartItem.stockContingent'
          );

          return {
            ticketPrice,
            quantity: 1,
            groupKey,
            stockContingent,
            data,
            participant: getEntityId(participant),
            availableSeat: availableSeat['@id'],
          };
        }),
    ];

    if (!ticketPriceQuantityList.length) {
      // do not dispatch if the list is empty
      return Promise.resolve() as unknown as BookingActionTypes;
    }

    // finaly, dispatch the action
    return dispatch(
      setTicketPriceQuantity(cartAtomId, ticketPriceQuantityList)
    );
  };
}

export function dismissCartBookingError(
  cartAtomId: CartAtomId
): BookingActionTypes {
  return { type: DISMISS_CART_BOOKING_ERROR, cartAtomId };
}
