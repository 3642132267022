import React, { FunctionComponent } from 'react';
import { components, GroupBase, ValueContainerProps } from 'react-select';
import { SingleSelectOption } from '../common/utils';
import MpdIcon from '../../../MpdIcon';

const ValueContainer: FunctionComponent<
  ValueContainerProps<SingleSelectOption, false, GroupBase<SingleSelectOption>>
> = (props: ValueContainerProps<SingleSelectOption>) => {
  const { selectProps } = props;
  const value = selectProps?.value as SingleSelectOption;
  const inputValue = selectProps?.inputValue;

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <components.ValueContainer {...props}>
        <div className="mpd-single-select-label">
          {value?.iconName && !inputValue && (
            <MpdIcon
              icon={value.iconName}
              color={value.iconColor}
              width="12"
              className="ml1 mr2"
            />
          )}
          {value?.imageUrl && !inputValue && (
            <img
              className="mr2"
              src={value?.imageUrl}
              loading="lazy"
              title={value?.imageUrl}
              alt={value?.imageUrl}
              width={20}
            />
          )}
          {props.children}
        </div>
        {value?.asideLabel && !inputValue && (
          <span className="mpd-single-select-value-aside-label">
            {value.asideLabel}
          </span>
        )}
      </components.ValueContainer>
    </>
  );
};

export default ValueContainer;
