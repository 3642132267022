import { GroupBase, Theme } from 'react-select';
import type {} from 'react-select/base'; // This import is necessary for module augmentation. It allows us to extend the 'Props' interface in the 'react-select/base' module and add our custom properties to it.
import type { MpdIconType } from '../../../../types/MpdIconType';

declare module 'react-select/base' {
  // cf. https://react-select.com/typescript#custom-select-props
  export interface Props<
    Option,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    IsMulti extends boolean,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Group extends GroupBase<Option>,
  > {
    isGrouped?: boolean;
    autocomplete?: boolean;
  }
}

export type SingleSelectOption = {
  label: string;
  value: string | null;
  disabled?: boolean;
  asideLabel?: string;
  subLabel?: string;
} & (
  | {
      iconName?: never;
      iconColor?: never;
      imageUrl?: never;
    }
  | {
      iconName: MpdIconType;
      iconColor: string;
      imageUrl?: never;
    }
    | {
      imageUrl: string;
      iconName?: never;
      iconColor?: never;
    }
);

export type MultiSelectOption = SingleSelectOption & {
  backgroundColor?: string;
};

export interface SelectGroupedOption<T> extends GroupBase<T> {
  groupCountLabel?: string;
}

export function isGroupedOption(
  option:
    | SingleSelectOption
    | MultiSelectOption
    | SelectGroupedOption<SingleSelectOption | MultiSelectOption>
): option is SelectGroupedOption<SingleSelectOption | MultiSelectOption> {
  return (
    (option as SelectGroupedOption<SingleSelectOption | MultiSelectOption>)
      .options !== undefined
  );
}

export const secondaryLightGray = '#d2d7dc';

const secondaryWhite = '#f7f5f7';
const alertRed = '#e90000';

export const getSelectTheme = (
  theme: Theme,
  errorMessage: string | boolean | null | undefined
): Theme => {
  const borderColor = errorMessage ? alertRed : secondaryLightGray;

  return {
    ...theme,
    borderRadius: 2,
    colors: {
      ...theme.colors,
      neutral20: borderColor,
      neutral30: borderColor,
      primary: borderColor,
      primary25: secondaryWhite,
      primary50: secondaryLightGray,
    },
    spacing: {
      ...theme.spacing,
      controlHeight: 60,
    },
  };
};
