import React, { useCallback } from 'react';
import { Set } from 'immutable';
import { StockContingent } from 'mapado-ticketing-js-sdk';
import { TOAST_TYPE, useMpdToast } from '@mapado/makeup';
import { SeatConfigBlockType, AvailableSeatType } from '../../propTypes';
import type { RootState } from '../../reducers';
import {
  addSeatToSelectedSeats,
  dismissOrRestore,
  removeSeatFromSelectedSeats,
  resetSelectedSeats,
  selectBatchOfSeats,
} from '../../actions/SeatActions';
import {
  useStockManagement,
  STOCK_MANAGEMENT_TABS,
} from '../../contexts/StockManagementContext';
import { getSeatIdForAvailableSeat } from '../../utils/entity';
import Seating from '../Seating';
import StockManagementSeat from '../Seat/StockManagementSeat';
import DrawButton from '../Toolbar/DrawButton';
import { useDrawingContext } from '../../contexts/DrawingContext';
import { useSelectedSeatGroupContext } from '../../contexts/SelectedSeatGroupContext';
import {
  useUpdateStockContingentOnChange,
  useUpdateSeatGroupOnChange,
  OnStockChangedType,
} from './hooks';
import { useSeatingDispatch } from '../../reducers/typedFunctions';
import { useTranslation } from '../../i18n';

type Props = {
  onGetSelectableSeats: (
    state: RootState,
    seatIdSet: Set<string>,
    selectedStockContingent?: string | StockContingent | null
  ) => Set<string>;
  seatConfigBlockList: SeatConfigBlockType[];
  onError: () => void;
  onStockChanged?: OnStockChangedType;
};

function StockManagementSeating({
  seatConfigBlockList,
  onGetSelectableSeats,
  onError,
  onStockChanged,
}: Props) {
  const { selectedStockContingent, selectedTab } = useStockManagement();
  const { selectedSeatGroup } = useSelectedSeatGroupContext();
  const { setSvgDrawerRef } = useDrawingContext();
  const toast = useMpdToast();
  const { t } = useTranslation();
  const dispatch = useSeatingDispatch();

  useUpdateStockContingentOnChange(onError, onStockChanged);

  useUpdateSeatGroupOnChange();

  const onClickSeat = useCallback(
    async (
      seatEntity: AvailableSeatType,
      isSelected: boolean,
      isSelectable: boolean
    ): Promise<void> => {
      if (!isSelectable) {
        return;
      }

      const seatId = getSeatIdForAvailableSeat(seatEntity);

      if (
        selectedTab === STOCK_MANAGEMENT_TABS.CONTINGENT &&
        selectedStockContingent
      ) {
        if (isSelected) {
          dispatch(removeSeatFromSelectedSeats(seatId));
        } else {
          dispatch(addSeatToSelectedSeats(seatId));
        }
      } else if (
        selectedTab === STOCK_MANAGEMENT_TABS.SEAT_GROUP &&
        selectedSeatGroup
      ) {
        dispatch(addSeatToSelectedSeats(seatId));
      } else if (selectedTab === STOCK_MANAGEMENT_TABS.GAUGE) {
        // select seat to highlight it while the request is pending
        dispatch(selectBatchOfSeats(Set([seatId])));

        await dispatch(
          dismissOrRestore(seatEntity, () => {
            toast({
              title: t('stock_gauge.update_error'),
              type: TOAST_TYPE.ERROR,
            });

            // wait for toast to be close to trigger `onError`
            window.setTimeout(() => {
              onError();
            }, 5000);
          })
        );

        if (onStockChanged) {
          onStockChanged(false);
        }

        // unselect seat to remove highlight
        dispatch(resetSelectedSeats());
      }
    },
    [
      dispatch,
      onError,
      selectedSeatGroup,
      selectedStockContingent,
      selectedTab,
      t,
      toast,
      onStockChanged,
    ]
  );

  const displayDrawButton =
    (selectedTab === STOCK_MANAGEMENT_TABS.CONTINGENT &&
      selectedStockContingent) ||
    (selectedTab === STOCK_MANAGEMENT_TABS.SEAT_GROUP && selectedSeatGroup) ||
    selectedTab === STOCK_MANAGEMENT_TABS.GAUGE;

  return (
    <div className="mpd-seating__app__event-svg-wrapper" ref={setSvgDrawerRef}>
      <Seating
        seatEntityType="AvailableSeat"
        SeatElement={StockManagementSeat}
        getSelectableSeatIdSet={(state, seatIdList) =>
          onGetSelectableSeats(state, seatIdList, selectedStockContingent)
        }
        onClickSeat={onClickSeat}
        seatConfigBlockList={seatConfigBlockList}
        isMovingSeat={false}
        actionButtons={displayDrawButton && <DrawButton />}
      />
    </div>
  );
}

export default StockManagementSeating;
