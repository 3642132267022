import { List, Map, MapOf } from 'immutable';
import { getEntityId } from '@mapado/js-component';
import {
  assertRelationIsDefined,
  assertRelationIsListOfObject,
  assertRelationIsNullOrObject,
  assertRelationIsObject,
  AvailableSeat,
  Cart,
  CartItemWithOffer,
  StockContingent,
  TicketPrice,
} from 'mapado-ticketing-js-sdk';
import { ORIGIN_TYPE_ENV } from '../constants';
import { filterInvalidCartItemExceptOnDesk } from './cart';

type MapFromObjectRecord<T> = T extends MapOf<infer R> ? R : never;
type KeyofMapFromObject<T> = keyof MapFromObjectRecord<T>;

type AvailableSeatAndQuantity = MapOf<{
  availableSeat: string;
  quantity?: string | number;
  quantityDelta?: string | number;
}>;

type ItemData = null | Map<string, unknown> | Record<string, unknown>;
type ItemDataNoObject = null | Map<string, unknown>;

export const NO_STOCK_CONTINGENT = 'NO_STOCK_CONTINGENT';

export type TicketPriceQuantityInputAsObject = {
  ticketPrice: TicketPrice;
  groupKey?: null | string;
  stockContingent?: null | StockContingent | typeof NO_STOCK_CONTINGENT;
  data?: ItemData;
  participant?: string | null;
  quantity?: string | number;
  quantityDelta?: string | number;
  availableSeat?: null | string;
};

export type TicketPriceQuantityInput = MapOf<TicketPriceQuantityInputAsObject>;

export type TicketPriceQuantityParameter =
  | TicketPriceQuantityInput
  | TicketPriceQuantityInputAsObject
  | AvailableSeatAndQuantity;

type TicketPriceQuantityInputWithQuantity = TicketPriceQuantityInput &
  MapOf<{
    quantity: string | number;
  }>;

export type TicketPriceQuantity = MapOf<{
  ticketPrice: TicketPrice;
  groupKey: null | string;
  stockContingent: null | StockContingent | typeof NO_STOCK_CONTINGENT;
  data: ItemDataNoObject;
  participant?: string | null;
  availableSeat: null | string;
  quantity: number;
}>;

function hasQuantity(
  item: TicketPriceQuantityInput
): item is TicketPriceQuantityInputWithQuantity {
  const quantity = item.get('quantity');

  return quantity !== null && typeof quantity !== 'undefined';
}

const getNumber = (v: string | number): number =>
  typeof v === 'string' ? parseInt(v, 10) : v;

function getQuantity(item: TicketPriceQuantityInput): number {
  const quantity = item.get('quantity');

  if (quantity === null || typeof quantity === 'undefined') {
    throw new Error(
      'getQuantity must be called with a TicketPriceQuantity that have a quantity. Did you called `hasQuantity` before ?'
    );
  }

  return getNumber(quantity);
}

function getQuantityDelta(item: TicketPriceQuantityInput): number {
  const quantityDelta = item.get('quantityDelta');

  if (!quantityDelta) {
    throw new Error(
      'getQuantityDelta must be called with a TicketPriceQuantity that have a quantityDelta. Did you called `hasQuantity` before ?'
    );
  }

  return getNumber(quantityDelta);
}

const getFromTicketPriceQuantityOrAccumulator = (
  key: KeyofMapFromObject<TicketPriceQuantity>,
  item: TicketPriceQuantityInput,
  acc: null | TicketPriceQuantity
): null | MapFromObjectRecord<typeof item>[typeof key] => {
  if (item.has(key)) {
    return item.get(key) ?? null;
  }

  return acc?.get(key) ?? null;
};

/**
 * Method called to reduce a list of TicketPriceQuantity. It does merge similiar items and set the reduced quantity.
 */
function reduceTicketPriceQuantities(
  acc: null | TicketPriceQuantity,
  item: TicketPriceQuantityInput
): TicketPriceQuantity {
  const accQuantity =
    acc &&
    acc.get('quantity') !== null &&
    typeof acc.get('quantity') !== 'undefined'
      ? acc.get('quantity')
      : 0;

  const quantity = item.get('quantity');
  const quantityDelta: string | number | undefined = item.get('quantityDelta');

  if (typeof quantity === 'undefined' && typeof quantityDelta === 'undefined') {
    throw new Error('quantity or quantityDelta is required');
  }

  const newQuantity: number = hasQuantity(item)
    ? getQuantity(item)
    : getQuantityDelta(item) + accQuantity;

  const availableSeat = item.get('availableSeat') || null;

  if (availableSeat) {
    // If we set a availableSeat, we want to erase all other values as it is a unique key generated by ther `group` function

    // @ts-expect-error -- issue with type variance in immutable type as availableSeat is string instead of string | null
    return Map({
      ticketPrice: getFromTicketPriceQuantityOrAccumulator(
        'ticketPrice',
        item,
        acc
      ),
      quantity: Math.min(newQuantity, 1),
      groupKey: getFromTicketPriceQuantityOrAccumulator('groupKey', item, acc),
      data: getFromTicketPriceQuantityOrAccumulator('data', item, acc),
      participant: getFromTicketPriceQuantityOrAccumulator(
        'participant',
        item,
        acc
      ),
      stockContingent: getFromTicketPriceQuantityOrAccumulator(
        'stockContingent',
        item,
        acc
      ),
      availableSeat,
    });
  }

  if (!acc) {
    // Accumulator is not set alreay : that is the first time that we encouter this key.
    // Let's create a full TicketPriceQuantity

    const tmpItemData: ItemData = item.get('data') ?? null;
    // @ts-expect-error issue in immutable type with Map.isMap. (weird as we have the exact same call elsewhere in this file !)
    const itemData: ItemDataNoObject =
      tmpItemData && !Map.isMap(tmpItemData) ? Map(tmpItemData) : tmpItemData;

    return Map({
      ticketPrice: item.get('ticketPrice'),
      quantity: newQuantity,
      groupKey: item.get('groupKey') || null,
      data: itemData,
      participant: getEntityId(item.get('participant')) || null,
      stockContingent: item.get('stockContingent') || null,
      availableSeat,
    });
  }

  // Accumulator is set already : juste update the quantity.
  return acc.set('quantity', newQuantity);
}

/**
 * Generate a key for a TicketPriceQuantity that will be used to fetch existing items in the ticketPriceQuantity list.
 *
 * @param {boolean} inDecrementMode If false, we will not include the stockContingent in the key. This is used to ignore item with contingent when no item has been found with the given contingent, like if we want to remove a ticket price, but there is only items with contingent.
 */
function getItemGroupByKey(
  item:
    | TicketPriceQuantity
    | TicketPriceQuantityInput
    | AvailableSeatAndQuantity,
  inDecrementMode = false
): string {
  // @ts-expect-error -- both three types have an `availableSeat` key, so we do not need a default value
  const availableSeat: string | null | undefined = item.get('availableSeat');

  if (availableSeat && !inDecrementMode) {
    // availableSeat is a unique key, so we do not need to have the complexity of the key
    return availableSeat;
  }

  const tmpItemData: ItemData = item.get('data', null);
  // convert item data to map if is in an object
  const itemData: ItemDataNoObject =
    tmpItemData && !Map.isMap(tmpItemData) ? Map(tmpItemData) : tmpItemData;

  const parts = [
    item.getIn(['ticketPrice', '@id']),
    item.get('groupKey', null),
    itemData && itemData.size > 0 ? JSON.stringify(itemData.toJS()) : '',
  ];

  if (!inDecrementMode) {
    parts.push(item.getIn(['stockContingent', '@id']));
  }

  return parts.join('|');
}

const groupItemByKey = (
  item: TicketPriceQuantityInput | AvailableSeatAndQuantity
): string => getItemGroupByKey(item, false);

const contingentThenAvailableSeat = (
  a: TicketPriceQuantity,
  b: TicketPriceQuantity
): number => {
  if (a.get('stockContingent') && !b.get('stockContingent')) {
    return -1;
  }

  if (!a.get('stockContingent') && b.get('stockContingent')) {
    return 1;
  }

  // either none or both have a stockContingent
  if (a.get('availableSeat') && !b.get('availableSeat')) {
    return -1;
  }

  if (!a.get('availableSeat') && b.get('availableSeat')) {
    return 1;
  }

  return 0;
};

/**
 * Does handle ticket price quantity
 * If two TicketPriceQuantities have the same keys, they will be grouped together.
 * ```
 * [
 *   { ticketPrice: '/v1/ticket_prices/1', quantity: 2 },
 *   { ticketPrice: '/v1/ticket_prices/1', quantityDelta: -1 },
 *   { ticketPrice: '/v1/ticket_prices/1', groupKey: 'ABC', quantityDelta: 2 },
 * ]
 * ```
 * The first two objects will be grouped together, the third will have its own key.
 * This allow the reducer to have the following output:
 * ```
 * [
 *   { ticketPrice: '/v1/ticket_prices/1', quantity: 1 },
 *   { ticketPrice: '/v1/ticket_prices/1', groupKey: 'ABC', quantity: 2 },
 * ]
 * ```
 *
 */
export function processTicketPriceQuantities(
  ticketPriceQuantities: List<
    TicketPriceQuantityInput | AvailableSeatAndQuantity
  >
) /* : List<TicketPriceQuantity> */ {
  // group by key, get a list of Inputs
  const groupedTicketPriceQuantities =
    ticketPriceQuantities.groupBy(groupItemByKey);

  // for each key, reduce the list to have only one TicketPriceQuantityInput
  const reducedTicketPriceQuantities = groupedTicketPriceQuantities.map(
    (
      itemList: List<TicketPriceQuantityInput | AvailableSeatAndQuantity>
    ): TicketPriceQuantity => {
      // @ts-expect-error -- issue with covariance between TicketPriceQuantityInput and AvailableSeatAndQuantity
      const out = itemList.reduce(reduceTicketPriceQuantities, null);

      if (out === null) {
        throw new Error(
          'reduced TicketPriceQuantityList should not output a `null` value.'
        );
      }

      return out;
    }
  );

  return reducedTicketPriceQuantities
    .reduce(
      (
        acc: Map<string, TicketPriceQuantity>,
        item: TicketPriceQuantity,
        key: string
      ): Map<string, TicketPriceQuantity> => {
        const quantity = item.get('quantity');

        if (quantity >= 0) {
          // if quantity is set
          return acc.set(key, item);
        }

        // negative quantities may be due to confusing situation when users are decrementing quantities
        // without knowing witch stock contingent is currently selected. So, we try to impact
        // another group of item as a second line measure
        const keyInDecrementMode = getItemGroupByKey(item, true);

        const found = acc
          .sort(contingentThenAvailableSeat)
          .findEntry(
            (entry) => getItemGroupByKey(entry, true) === keyInDecrementMode
          );

        if (!found) {
          // we are making sure not to send negative quantities to the API
          const updatedItem = item.set('quantity', 0);

          return acc.set(key, updatedItem);
        }

        const [foundKey, secondLineItem] = found;

        // let secondLineItem = acc.get(foundKey);
        const secondLineQuantity = secondLineItem.get('quantity');
        const newQuantity = quantity + secondLineQuantity;

        return acc.set(foundKey, secondLineItem.set('quantity', newQuantity));
      },
      Map<string, TicketPriceQuantity>()
    )
    .filter((item) => item.get('quantity') > 0)
    .toList();
}

function explodeMultipleAvailableSeat(
  cartItemWithOffer: CartItemWithOffer
): Array<CartItemWithOffer> {
  const { availableSeatList } = cartItemWithOffer;

  if (!availableSeatList || availableSeatList.size <= 0) {
    return [cartItemWithOffer.set('availableSeatList', List())];
  }

  assertRelationIsListOfObject(
    availableSeatList,
    'cartItemWithOffer.availableSeatList'
  );

  let outManuallyAssigned = List<CartItemWithOffer>();
  let outAutoAssigned = cartItemWithOffer
    .set('quantity', 0)
    .set('availableSeatList', List<AvailableSeat>());

  availableSeatList.forEach((availableSeat) => {
    if (availableSeat.manuallyAssigned) {
      outManuallyAssigned = outManuallyAssigned.push(
        cartItemWithOffer
          .set('quantity', 1)
          .set('availableSeatList', List([availableSeat]))
      );
    } else {
      outAutoAssigned = outAutoAssigned.set(
        'quantity',
        (outAutoAssigned.quantity as number) + 1
      );
    }
  });

  if ((outAutoAssigned.quantity as number) > 0) {
    return outManuallyAssigned.unshift(outAutoAssigned).toArray();
  }

  return outManuallyAssigned.toArray();
}

/**
 * Will take a cart and convert it to a processable list of TicketPriceQuantity. It is used when we have a cart and refresh the page or when a cart is updated, we update the ticketPriceQuantities from the updated cart.
 * CartItems are directly mapped to TicketPriceQuantity.
 *
 * @param {string} originTypeEnv if originTypeEnv is 'desk', ther we won't filter invalid cart items as invalid carts can still be processed by the pro.
 */
export function convertCartToTicketPriceQuantity(
  cart: Cart,
  originTypeEnv: null | ORIGIN_TYPE_ENV
): List<TicketPriceQuantity> {
  assertRelationIsListOfObject(
    cart.cartItemWithOfferList,
    'cart.cartItemWithOfferList'
  );

  const flattenedTicketPriceQuantities: List<TicketPriceQuantityInput> =
    cart.cartItemWithOfferList
      .filter(filterInvalidCartItemExceptOnDesk(originTypeEnv))

      // if we have an availableSeatList, let's explode this list to have only one quantity and one availableSeat
      .flatMap(explodeMultipleAvailableSeat)
      .map((cartItemWithOffer: CartItemWithOffer): TicketPriceQuantityInput => {
        const ticketPrice = cartItemWithOffer.get('ticketPrice');

        assertRelationIsObject(ticketPrice, 'cartItemWithOffer.ticketPrice');

        const quantityDelta = cartItemWithOffer.get('quantity');

        assertRelationIsDefined(quantityDelta, 'cartItemWithOffer.quantity');

        const groupKey = cartItemWithOffer.get('groupKey') ?? undefined;

        const cartItem = cartItemWithOffer.get('cartItem');

        assertRelationIsObject(cartItem, 'cartItemWithOffer.cartItem');

        const stockContingent = cartItem.get('stockContingent');

        assertRelationIsNullOrObject(
          stockContingent,
          'cartItemWithOffer.cartItem.stockContingent'
        );

        const availableSeatList =
          cartItemWithOffer.get('availableSeatList') || List();

        assertRelationIsListOfObject(
          availableSeatList,
          'cart.availableSeatList'
        );

        const availableSeat = availableSeatList.first();

        const dataInput = cartItem.get('data');
        const data: ItemDataNoObject =
          dataInput && !Map.isMap(dataInput)
            ? Map(dataInput)
            : // Issue with `isMap` returning Map<unknown, unknown>
              (dataInput as Map<string, unknown>);

        return Map({
          ticketPrice,
          quantityDelta,
          groupKey,
          stockContingent,
          data,
          participant: getEntityId(cartItem.get('participant')) ?? null,
          availableSeat: availableSeat?.get('@id'),
        });
      });

  return processTicketPriceQuantities(flattenedTicketPriceQuantities);
}

export const testables = {
  reduceTicketPriceQuantities,
  getItemGroupByKey,
  explodeMultipleAvailableSeat,
};
