import { assertRelationIsDefined } from 'mapado-ticketing-js-sdk';
import panzoom, { PanZoom as PanZoomType } from 'panzoom';

export type SvgPanZoom = PanZoomType & {
  zoomBy: (scale: number, x: number, y: number) => void;
  zoomIn: () => void;
  zoomOut: () => void;
  disablePan: () => void;
  enablePan: () => void;
  destroy: () => void;
};

const panZoomRatio = 1.6;
let instance: SvgPanZoom | null = null;

export function getSvgPanZoomInstance(): SvgPanZoom {
  if (!instance) {
    throw new Error(
      'Unable to get SvgPanZoomInstance. Did you forgot to call `initSvgPanZoom` ?'
    );
  }

  return instance;
}

function initSvgPanZoom(element: HTMLElement | SVGElement): SvgPanZoom {
  instance = panzoom(element, {
    zoomSpeed: 0.1, // 10% per mouse wheel event
    maxZoom: 3,
    minZoom: 0.01,
    autocenter: true,
    bounds: true,
    smoothScroll: false,
    beforeWheel: (event: WheelEvent) => {
      // disable wheelevent in panzoom in order to prevent the change of the scale value when moving top/bottom with the trackpad
      return Math.abs(event.deltaY) < 100;
    },
  }) as SvgPanZoom;

  const svgContainer = document.querySelector('.draggableSVG');

  assertRelationIsDefined(svgContainer, 'svgContainer');

  const rect = svgContainer.getBoundingClientRect();
  const cx = rect.width / 2;
  const cy = rect.height / 2;

  /* eslint-disable func-names */
  instance.zoomBy = function (scale: number, x: number, y: number): void {
    this.zoomTo(x, y, scale);
  };

  instance.zoomIn = function (): void {
    // Zoom factor greater than 1 to zoom in
    this.smoothZoom(cx, cy, 1 * panZoomRatio);
  };

  instance.zoomOut = function (): void {
    // Zoom factor less than 1 to zoom out
    this.smoothZoom(cx, cy, 1 / panZoomRatio);
  };

  instance.disablePan = function (): void {
    this.pause();
  };

  instance.enablePan = function (): void {
    this.resume();
  };

  instance.destroy = function (): void {
    this.dispose();
  };
  /* eslint-enable func-names */

  return instance;
}

export function disablePan(): void {
  const svgPanZoomInstance = getSvgPanZoomInstance();

  svgPanZoomInstance.disablePan();
}

export function enablePan(): void {
  const svgPanZoomInstance = getSvgPanZoomInstance();

  svgPanZoomInstance.enablePan();
}

export default initSvgPanZoom;
