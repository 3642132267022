import React from 'react';
import { components, DropdownIndicatorProps } from 'react-select';
import MpdIcon from '../../../MpdIcon';
import { MultiSelectOption, SingleSelectOption } from './utils';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const DropdownIndicator = (
  props: DropdownIndicatorProps<SingleSelectOption | MultiSelectOption>
) => {
  const { menuIsOpen } = props.selectProps;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.DropdownIndicator {...props}>
      <MpdIcon
        icon={menuIsOpen ? 'arrow-top' : 'arrow-down'}
        width="24"
        className="mpd-color-blue ml1"
      />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
