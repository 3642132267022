import { Dispatch } from 'redux';
import { List } from 'immutable';
import {
  EventDate,
  assertRelationIsString,
  assertRelationIsObject,
  Contract,
} from 'mapado-ticketing-js-sdk';
import { DOMAIN_CONTEXT } from '@mapado/js-component';
import { initBatchOfSeatsFromSeatList, resetState } from './SeatActions';
import TicketingSdkInstance from '../TicketingSdkInstance';
import {
  setSeatConfigData,
  getSeatConfigData,
  getSeatConfigDataForEventDateId,
} from './SeatConfigAction';
import {
  SET_EVENTDATE_DATA,
  SET_STOCK_CONTINGENT_LIST,
  SET_AVAILABLE_SEAT_LIST,
  SET_CONTRACT,
} from '../reducers/types';
import { SeatActionTypes, SeatThunkAction } from '../reducers/SeatReducer';
import getAvailableSeatListForEventDate, {
  GetAvailableSeatListForEventDate,
} from './getAvailableSeatListForEventDate';
import {
  AvailableSeatType,
  EventDateType,
  Id,
  isSeatConfigType,
  SeatConfigEntity,
} from '../propTypes';
import {
  getLogicalSeatConfigData,
  setLogicalSeatConfigData,
} from './LogicalSeatConfigAction';
import { EVENT_DATE_DATA_DEFAULT_FIELDS } from './fields';
import { isMovableSeat } from '../utils/seat';

function fetchStockContingentList(
  eventDateId: Id
): SeatThunkAction<Promise<void>> {
  return (dispatch: Dispatch): Promise<void> => {
    return TicketingSdkInstance.getSdk()
      .getRepository('stockContingent')
      .findBy(
        {
          user: 'me',
          eventDate: eventDateId,
          draft: false,
          'order[contingent.name]': 'ASC',
          itemsPerPage: 9999,
        },
        [
          'totalStock',
          'bookableStock',
          'bookedTickets',
          'availableSeatList',
          'draft',
          { contingent: ['@id', 'name', 'color'] },
        ]
      )
      .then((stockContingentCollection) =>
        stockContingentCollection.getMembers()
      )
      .then((stockContingentList) => {
        dispatch({
          type: SET_STOCK_CONTINGENT_LIST,
          stockContingentList,
        });
      });
  };
}

export function getEventDateData(
  eventDateId: Id,
  callback?: () => void
): SeatThunkAction<Promise<EventDateType>> {
  return (dispatch) => {
    const ticketingSdk = TicketingSdkInstance.getSdk();

    return ticketingSdk
      .getRepository('eventDate')
      .find(eventDateId, EVENT_DATE_DATA_DEFAULT_FIELDS, {})
      .then((immutablEventDate: EventDate) => {
        const eventDate = immutablEventDate.toJS();

        dispatch(fetchStockContingentList(eventDateId));

        return getAvailableSeatListForEventDate(eventDateId).then(
          (availableSeatList) => {
            const eventDateWData = {
              ...eventDate,
              availableSeatList,
            } as EventDateType;

            dispatch({
              type: SET_AVAILABLE_SEAT_LIST,
              availableSeatList: List<AvailableSeatType>(availableSeatList),
            });

            dispatch({
              type: SET_EVENTDATE_DATA,
              eventDate: eventDateWData,
            });

            if (callback) {
              callback();
            }

            return eventDateWData;
          }
        );
      });
  };
}

export function autoSelectAvailableSeatList(
  availableSeatList: List<AvailableSeatType>
): SeatThunkAction {
  return (dispatch) => {
    const seatList = availableSeatList
      .filter((avs) => isMovableSeat(avs))
      .map((avs) => {
        assertRelationIsObject(avs.seat, 'sr.seat');

        return avs.seat;
      });

    return dispatch(initBatchOfSeatsFromSeatList(seatList));
  };
}

function setAvailableSeatListForEventDate(
  eventDateId: Id,
  options?: GetAvailableSeatListForEventDate
): SeatThunkAction<Promise<SeatActionTypes>> {
  return (dispatch) =>
    getAvailableSeatListForEventDate(eventDateId, options).then(
      (availableSeatList) =>
        dispatch({
          type: SET_AVAILABLE_SEAT_LIST,
          availableSeatList,
        })
    );
}

function setSeatConfigDataForEventDateId(eventDateId: Id): SeatThunkAction {
  return (dispatch) =>
    getSeatConfigDataForEventDateId(eventDateId).then(
      (seatConfigOrLogicalSeatConfig: null | SeatConfigEntity) => {
        if (!seatConfigOrLogicalSeatConfig) {
          throw new Error(
            `Unable to find seatConfig or logicalSeatConfig for eventDate with id ${eventDateId}. You probably tried to load the seating component for an event date without seatConfig.`
          );
        }

        if (isSeatConfigType(seatConfigOrLogicalSeatConfig)) {
          return dispatch(setSeatConfigData(seatConfigOrLogicalSeatConfig));
        }

        return dispatch(
          setLogicalSeatConfigData(seatConfigOrLogicalSeatConfig)
        );
      }
    );
}

export function initCartReplacement(
  cartId: Id,
  eventDateId: Id
): SeatThunkAction<Promise<unknown>> {
  return (dispatch) => {
    dispatch(resetState());

    const promiseList = [
      // cartId covid19 context
      dispatch(
        setAvailableSeatListForEventDate(eventDateId, { currentCartId: cartId })
      ),
      dispatch(setSeatConfigDataForEventDateId(eventDateId)),
      dispatch(fetchStockContingentList(eventDateId)),
    ];

    return Promise.all(promiseList);
  };
}

export function initSeatingPlanViewer(seatConfigId: number): SeatThunkAction {
  return (dispatch) => {
    dispatch(resetState());

    return dispatch(getSeatConfigData(seatConfigId.toString(), false, true));
  };
}

export function initSeatingPlanPurchase(
  eventDateId: number,
  currentDomain: DOMAIN_CONTEXT
): SeatThunkAction {
  return (dispatch) => {
    dispatch(resetState());
    dispatch(setSeatConfigDataForEventDateId(eventDateId));

    if (currentDomain === DOMAIN_CONTEXT.DESK) {
      dispatch(fetchStockContingentList(eventDateId));
    }

    return dispatch(setAvailableSeatListForEventDate(eventDateId));
  };
}

function getOrderReplacementInitialData(
  orderId: Id,
  eventDateId: number
): SeatThunkAction<Promise<unknown>> {
  return (dispatch) => {
    const promiseList = [
      dispatch(
        setAvailableSeatListForEventDate(eventDateId, {
          currentOrderId: orderId,
        })
      ),
      dispatch(setSeatConfigDataForEventDateId(eventDateId)),
      dispatch(fetchStockContingentList(eventDateId)),
    ];

    return Promise.all(promiseList);
  };
}

export function initOrderReplacement(
  orderId: Id,
  eventDateId: number
): SeatThunkAction<Promise<unknown>> {
  return (dispatch) => {
    dispatch(resetState());

    return dispatch(getOrderReplacementInitialData(orderId, eventDateId));
  };
}

export function initOrderViewer(
  cartId: Id,
  eventDateId: Id
): SeatThunkAction<Promise<unknown>> {
  return (dispatch) => {
    dispatch(resetState());

    const promiseList = [
      dispatch(
        setAvailableSeatListForEventDate(eventDateId, { currentCartId: cartId })
      ),
      dispatch(setSeatConfigDataForEventDateId(eventDateId)),
    ];

    return Promise.all(promiseList);
  };
}

export function initStockContingent({
  eventDateId,
  hasError,
}: {
  eventDateId: null | Id;
  hasError: boolean;
}): SeatThunkAction {
  return (dispatch) => {
    if (hasError) {
      dispatch(resetState());
    }

    if (!eventDateId) {
      throw new Error('EventDateId should be defined');
    }

    return dispatch(getEventDateData(eventDateId)).then((eventDate) => {
      if (eventDate.logicalSeatConfig) {
        assertRelationIsString(
          eventDate.logicalSeatConfig,
          'eventDate.logicalSeatConfig'
        );
        dispatch(fetchStockContingentList(eventDateId));

        return dispatch(
          getLogicalSeatConfigData(
            eventDate.logicalSeatConfig.replace(
              /\/v\d+\/logical_seat_configs\//,
              ''
            )
          )
        );
      }

      throw new Error(
        'EventDate should have one of logicalSeatConfig or seatConfig'
      );
    });
  };
}

export function initStockGroup({
  eventDateId,
  hasError,
}: {
  eventDateId: null | Id;
  hasError: boolean;
}): SeatThunkAction {
  return (dispatch) => {
    // TODO
    if (hasError) {
      dispatch(resetState());
    }

    return initStockGauge({ eventDateId, hasError });
  };
}

export function initStockGauge({
  eventDateId,
  hasError,
}: {
  eventDateId: null | Id;
  hasError: boolean;
}): SeatThunkAction {
  return (dispatch) => {
    if (hasError) {
      dispatch(resetState());
    }

    if (!eventDateId) {
      throw new Error('eventDateId should be defined. This should not happen.');
    }

    Promise.all([
      dispatch(setSeatConfigDataForEventDateId(eventDateId)),
      dispatch(setAvailableSeatListForEventDate(eventDateId)),
    ]);
  };
}

export function initLogicalSeatConfigAdmin(
  logicalSeatConfigId: null | Id = null
): SeatThunkAction {
  return (dispatch) => {
    dispatch(resetState());

    if (!logicalSeatConfigId) {
      throw new Error(
        'logicalSeatConfigId should be defined. This should not happen.'
      );
    }

    return dispatch(
      getLogicalSeatConfigData(logicalSeatConfigId.toString(), true)
    );
  };
}

export function initOrderManagement(
  eventDateId: number
): SeatThunkAction<Promise<unknown[]>> {
  return (dispatch) => {
    dispatch(resetState());

    const promises = [
      dispatch(setSeatConfigDataForEventDateId(eventDateId)),
      dispatch(setAvailableSeatListForEventDate(eventDateId)),
    ];

    // this return is used for tests :/
    return Promise.all(promises);
  };
}

export function setContract(contract: Contract): SeatActionTypes {
  return {
    type: SET_CONTRACT,
    contract,
  };
}
