import {
  assertRelationIsDefined,
  assertRelationIsString,
  Iri,
  Seat,
  SeatConfigBlock,
  SeatGroup,
} from 'mapado-ticketing-js-sdk';
import { List } from 'immutable';
import { MpdToastFunction, TOAST_TYPE } from '@mapado/makeup';
import TicketingSdkInstance from '../../../src/TicketingSdkInstance';
import { getSeatConfigData } from '../../../src/actions/SeatConfigAction';
import {
  actualYToApiY,
  actualXToApiX,
} from '../../../src/utils/seatDimensions';
import {
  ADD_SEAT_CONFIG_BLOCK,
  REMOVE_SEAT_CONFIG_BLOCK,
  UPDATE_SEAT_CONFIG_BLOCK,
  UPDATE_SEAT_LIST,
  UPDATE_SEAT_POSITION,
} from '../../../src/reducers/types';
import {
  SeatActionTypes,
  SeatThunkAction,
} from '../../../src/reducers/SeatReducer';
import { seatConfigSelector } from '../../../src/utils/selectors';
import { SeatConfigBlockType, SeatType } from '../../../src/propTypes';

const SEAT_CONFIG_BLOCK_FIELDS = [
  'info',
  'x',
  'y',
  'z',
  'width',
  'height',
  'fontSize',
  'seatConfig',
];

export function addSeatConfigBlock(toast: MpdToastFunction): SeatThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const seatConfig = seatConfigSelector(state);

    assertRelationIsDefined(seatConfig, 'seatConfig');

    const newSeatConfigBlock = new SeatConfigBlock({
      '@id': null,
      '@type': 'SeatConfigBlock',
      x: 0,
      y: 0,
      info: '',
      seatConfig: seatConfig['@id'],
    });

    const ticketingSdk = TicketingSdkInstance.getDefaultSerializerSdk();

    return ticketingSdk
      .getRepository('seatConfigBlock')
      .create(newSeatConfigBlock, { fields: SEAT_CONFIG_BLOCK_FIELDS })
      .then((createdSeatConfigBlock) => {
        toast({
          title: 'Le SeatConfigBlock a été créé avec succès.',
          type: TOAST_TYPE.SUCCESS,
        });

        return dispatch({
          type: ADD_SEAT_CONFIG_BLOCK,
          seatConfigBlock:
            createdSeatConfigBlock.toJSON() as SeatConfigBlockType,
        });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        toast({
          title:
            'Une erreur est survenue pendant la création du SeatConfigBlock.',
          type: TOAST_TYPE.ERROR,
        });
      });
  };
}

export function removeSeatConfigBlock(index: number): SeatActionTypes {
  return {
    type: REMOVE_SEAT_CONFIG_BLOCK,
    index,
  };
}

export function updateSeatConfigBlockInfo(
  id: Iri<'SeatConfigBlock'>,
  info: string,
  toast: MpdToastFunction,
  updateApiSide = true
): SeatThunkAction {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SEAT_CONFIG_BLOCK,
      id,
      info,
    });

    if (!updateApiSide) {
      return;
    }

    const ticketingSdk = TicketingSdkInstance.getDefaultSerializerSdk();

    ticketingSdk
      .getRepository('seatConfigBlock')
      .find(id, { fields: SEAT_CONFIG_BLOCK_FIELDS })
      .then((seatConfigBlock) =>
        ticketingSdk
          .getRepository('seatConfigBlock')
          .update(seatConfigBlock.set('info', info), {
            fields: SEAT_CONFIG_BLOCK_FIELDS,
          })
          .then(() => {
            toast({
              title: 'Le SeatConfigBlock a été modifié avec succès.',
              type: TOAST_TYPE.SUCCESS,
            });
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error(err);
            toast({
              title:
                'Une erreur est survenue pendant la modification du SeatConfigBlock.',
              type: TOAST_TYPE.ERROR,
            });
          })
      );
  };
}

export function createOrUpdateSeatGroup(seatGroup: SeatGroup): SeatThunkAction {
  return (dispatch) => {
    const method = seatGroup ? 'update' : 'create';

    return TicketingSdkInstance.getDefaultSerializerSdk()
      .getRepository('seatGroup')
      [method](seatGroup, {
        fields: ['seatConfig'],
      })
      .then((updatedSeatGroup) => {
        const seatConfig = updatedSeatGroup.get('seatConfig');

        assertRelationIsString(seatConfig, 'seatConfig');

        const seatConfigShortId = seatConfig.replace('/v1/seat_configs/', '');

        return dispatch(getSeatConfigData(seatConfigShortId, true, true));
      });
  };
}

export function updateSeatList(
  seatList: List<SeatType>,
  onError: () => void
): SeatThunkAction {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SEAT_LIST,
      seatList,
    });

    const promiseList = seatList.map((s) => {
      // @ts-expect-error clonedSeat is a valid Seat constructor
      const seatEntity = new Seat({ ...s });

      return TicketingSdkInstance.getDefaultSerializerSdk()
        .getRepository('seat')
        .update(seatEntity, {
          fields: ['@id'],
        });
    });

    return Promise.all(promiseList).catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      onError();
    });
  };
}

export function updateSeatPosition(
  seatId: string,
  x: string | number,
  y: string | number,
  toast: MpdToastFunction,
  onError: () => void
): SeatThunkAction {
  const apiX = actualXToApiX(x);
  const apiY = actualYToApiY(y);

  return (dispatch) => {
    dispatch({
      type: UPDATE_SEAT_POSITION,
      seatId: `/v1/seats/${seatId}`,
      x: apiX,
      y: apiY,
    });

    return TicketingSdkInstance.getDefaultSerializerSdk()
      .getRepository('seat')
      .find(seatId, {
        fields: [
          '@id',
          'coordinates',
          'label',
          'seatNumber',
          'zone',
          'row',
          'seatGroup',
          'position',
          'visible',
          'stockContingent',
        ],
      })
      .then((seatEntity) => {
        const updatedSeat = seatEntity
          .setIn(['coordinates', 'Y'], apiY)
          .setIn(['coordinates', 'X'], apiX);

        return TicketingSdkInstance.getDefaultSerializerSdk()
          .getRepository('seat')
          .update(updatedSeat, {
            fields: ['@id'],
          })
          .then(() => {
            toast({
              title: 'La position du siège a été mise à jour avec succès.',
              type: TOAST_TYPE.SUCCESS,
            });
          });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        onError();
        toast({
          title: 'Erreur lors du déplacement du siège.',
          type: TOAST_TYPE.ERROR,
        });
      });
  };
}
