const DEFAULT_DELAY = 100;

function debounce<Params extends unknown[]>(
  func: (...args: Params) => void,
  ms = DEFAULT_DELAY
): (...args: Params) => void {
  let timeoutId: ReturnType<typeof setTimeout>;

  return (...args: Params) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), ms);
  };
}

export default debounce;
