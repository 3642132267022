import React, { FunctionComponent } from 'react';
import { components, GroupBase, SingleValueProps } from 'react-select';
import cn from 'classnames';
import { SingleSelectOption } from '../common/utils';

const SingleValue: FunctionComponent<
  SingleValueProps<SingleSelectOption, false, GroupBase<SingleSelectOption>>
> = (props: SingleValueProps<SingleSelectOption>) => {
  const {
    children,
    data: { subLabel },
  } = props;

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <components.SingleValue {...props}>
        <div className="flex-column">
          <span className={cn({ bold: !!subLabel })}>{children}</span>
          {subLabel && <small className="small">{subLabel}</small>}
        </div>
      </components.SingleValue>
    </>
  );
};

export default SingleValue;
