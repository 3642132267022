import React, { Component, ReactNode } from 'react';
import { Iri, SeatGroup } from 'mapado-ticketing-js-sdk';
import { List } from 'immutable';
import { MpdToastFunction } from '@mapado/makeup';
import SideBarElement from './SidebarElement';
import { AttributeKey, attributeMap, SetAttributeFunction } from './NodeDetail';
import { SeatConfigType, SeatGroupType } from '../../../../src/propTypes';

function setAttributeToElt(
  elt: Element,
  attribute: string,
  value: string | number | undefined
): void {
  const addPxElementList = [
    'width',
    'height',
    'stroke-width',
    'data-originalX',
    'font-size',
    'data-originalY',
  ];

  if (addPxElementList.indexOf(attribute) >= 0) {
    elt.setAttribute(attribute, `${value}px`);
  } else if (typeof value === 'undefined') {
    elt.setAttribute(attribute, '');
  } else {
    elt.setAttribute(
      attribute,
      typeof value === 'string' ? value : value.toString()
    );
  }
}

type Props = {
  addSeatConfigBlock: (toast: MpdToastFunction) => void;
  toast: MpdToastFunction;
  createOrUpdateSeatGroup: (seatGroup: SeatGroup) => void;
  registeredElements: Array<SVGGElement>;
  removeSeatConfigBlock: (index: number) => void;
  setSelectedElement: (element: HTMLElement, isSelected: boolean) => void;
  updateSeatConfigBlockInfo: (
    id: Iri<'SeatConfigBlock'>,
    html: string,
    toast: MpdToastFunction,
    updateApiSide?: boolean
  ) => void;
  children: ReactNode;
  seatConfig: SeatConfigType;
  seatGroupList: List<SeatGroupType>;
};

// do not extend PureComponent here otherwise forced rerenders won't have any effect
class Sidebar extends Component<Props> {
  constructor(props: Props) {
    super(props);

    this.addNewSeatConfigBlock = this.addNewSeatConfigBlock.bind(this);
    this.addTagToElement = this.addTagToElement.bind(this);
    this.changeNodeAttribute = this.changeNodeAttribute.bind(this);
    this.createNewSeatGroup = this.createNewSeatGroup.bind(this);
    this.deleteNode = this.deleteNode.bind(this);
    this.duplicateNode = this.duplicateNode.bind(this);
    this.forceRerender = this.forceRerender.bind(this);
  }

  forceRerender(): void {
    this.forceUpdate();
  }

  addTagToElement(element: Element, tagName: AttributeKey): void {
    const newChild = document.createElement(tagName);

    const attributeList = attributeMap[tagName];

    attributeList.forEach(
      ({
        attributeListToUpdate,
        defaultValue,
        setAttributeFunction = null,
      }) => {
        if (setAttributeFunction !== null) {
          setAttributeFunction(newChild, defaultValue);
        } else if (attributeListToUpdate) {
          attributeListToUpdate.forEach((attribute) => {
            setAttributeToElt(newChild, attribute, defaultValue);
          });
        }
      }
    );

    element.appendChild(newChild);

    const id = element.getAttribute('id');

    if (!id) {
      throw new Error('id is undefined');
    }

    this.props.updateSeatConfigBlockInfo(
      // @ts-expect-error this is an iri here
      id,
      element.innerHTML,
      this.props.toast,
      false
    );
  }

  changeNodeAttribute(
    node: Element,
    value: string | number,
    attributeList: Array<string>,
    setAttributeFunction: SetAttributeFunction | null = null
  ): void {
    if (setAttributeFunction !== null) {
      setAttributeFunction(node, value);
    } else {
      attributeList.forEach((attribute) => {
        setAttributeToElt(node, attribute, value);
      });
    }

    this.forceRerender();
  }

  addNewSeatConfigBlock(): void {
    this.props.addSeatConfigBlock(this.props.toast);
  }

  deleteNode(node: Element): void {
    node.remove();
    this.forceRerender();
  }

  duplicateNode(node: Element, index: number, parentElement: Element): void {
    const newChild = node.cloneNode(true);

    parentElement.appendChild(newChild);

    const parentId = parentElement.getAttribute('id');

    if (!parentId) {
      throw new Error('id is undefined');
    }

    this.props.updateSeatConfigBlockInfo(
      // @ts-expect-error this is an iri here
      parentId,
      parentElement.innerHTML,
      this.props.toast,
      false
    );

    this.forceRerender();
  }

  createNewSeatGroup() {
    const { seatConfig, createOrUpdateSeatGroup, seatGroupList } = this.props;

    const nbSeatGroup = seatGroupList.size;
    const seatGroup = new SeatGroup({
      '@id': null,
      '@type': 'SeatGroup',
      seatConfig: seatConfig['@id'],
      label: `SeatGroup ${nbSeatGroup + 1}`,
    });

    createOrUpdateSeatGroup(seatGroup);
  }

  render() {
    const {
      toast,
      children,
      registeredElements,
      removeSeatConfigBlock,
      setSelectedElement,
      updateSeatConfigBlockInfo,
    } = this.props;

    return (
      <div className="mpd-seating__editor__sidebar">
        {children ? (
          <>
            {children}
            <hr />
          </>
        ) : (
          ''
        )}

        <div className="mpd-seating__editor__sidebar__seat-config-blocks">
          <h3>Elements</h3>

          {registeredElements.map((element, index) => (
            <SideBarElement
              addTagToElement={this.addTagToElement}
              toast={toast}
              changeNodeAttribute={this.changeNodeAttribute}
              element={element}
              index={index}
              key={element.getAttribute('id')}
              setSelectedElement={setSelectedElement}
              deleteNode={this.deleteNode}
              duplicateNode={this.duplicateNode}
              removeSeatConfigBlock={removeSeatConfigBlock}
              isLast={index === registeredElements.length - 1}
              forceRerender={this.forceRerender}
              updateSeatConfigBlockInfo={updateSeatConfigBlockInfo}
            />
          ))}

          <button
            type="button"
            className="block txtcenter mpd-btn mpd-btn--primary mpd-btn--small"
            onClick={this.addNewSeatConfigBlock}
          >
            + Ajouter un seat_config_block
          </button>
        </div>
      </div>
    );
  }
}

export default Sidebar;
