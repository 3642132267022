import { List } from 'immutable';
import {
  AVAILABLE_SEAT_MODEL_FIELDS_LITE,
  SEAT_FIELDS,
  SEAT_GROUP_FIELDS,
  CONTINGENT_FIELDS,
} from './fields';
import TicketingSdkInstance from '../TicketingSdkInstance';
import {
  AvailableSeatModelType,
  Id,
  SeatGroupType,
  SeatType,
} from '../propTypes';

export default async function getAvailableSeatModelListForLogicalSeatConfig(
  logicalSeatConfigId: Id,
  contractId: Id
) {
  const seatGroupPromise = TicketingSdkInstance.getSdk()
    .getRepository('seatGroup')
    .findBy(
      {
        contract: contractId,
        availableSeatModelListLogicalSeatConfig: logicalSeatConfigId,
        showHidden: true,
        itemsPerPage: 9999,
      },
      SEAT_GROUP_FIELDS
    );

  const contingentPromise = TicketingSdkInstance.getSdk()
    .getRepository('contingent')
    .findBy(
      {
        contract: contractId,
        availableSeatModelListLogicalSeatConfig: logicalSeatConfigId,
        showHidden: true,
        'order[name]': 'ASC',
        itemsPerPage: 9999,
      },
      CONTINGENT_FIELDS
    );

  const availableSeatModelPromise = TicketingSdkInstance.getSdk()
    .getRepository('availableSeatModel')
    .findBy(
      {
        contract: contractId,
        logicalSeatConfig: logicalSeatConfigId,
        itemsPerPage: 9999,
      },
      AVAILABLE_SEAT_MODEL_FIELDS_LITE
    );

  const seatPromise = TicketingSdkInstance.getSdk()
    .getRepository('seat')
    .findBy(
      {
        availableSeatModelListLogicalSeatConfig: logicalSeatConfigId,
        itemsPerPage: 9999,
      },
      SEAT_FIELDS
    );

  const [seatGroupColl, contingentColl, seatColl, availableSeatModelColl] =
    await Promise.all([
      seatGroupPromise,
      contingentPromise,
      seatPromise,
      availableSeatModelPromise,
    ]);

  const updatedAvailableSeatModelList = availableSeatModelColl
    .get('hydra:member')
    // @ts-expect-error -- custom serializer typing error
    .map((availableSeatModel: AvailableSeatModelType) => {
      const seatGroup = seatGroupColl.get('hydra:member').find(
        // @ts-expect-error -- custom serializer typing error
        (sg: SeatGroupType) => sg['@id'] === availableSeatModel.seatGroup
      );
      const contingent =
        contingentColl
          .getMembers()
          .find((sc) => sc.get('@id') === availableSeatModel.contingent) ||
        null;
      const seat = seatColl
        .get('hydra:member')
        // @ts-expect-error -- custom serializer typing error
        .find((s: SeatType) => s['@id'] === availableSeatModel.seat);

      if (typeof seatGroup === 'undefined') {
        throw new Error(
          `SeatGroup ${availableSeatModel.seatGroup} not found in seatGroupColl for logicalSeatConfig ${logicalSeatConfigId}. This should not happen.`
        );
      }

      if (typeof seat === 'undefined') {
        throw new Error(
          `Seat ${availableSeatModel.seat} not found in seatColl for logicalSeatConfig ${logicalSeatConfigId}. This should not happen.`
        );
      }

      return {
        ...availableSeatModel,
        seatGroup,
        contingent,
        seat,
      };
    });

  return availableSeatModelColl
    .set('hydra:member', updatedAvailableSeatModelList)
    .get('hydra:member') as List<AvailableSeatModelType>;
}
