import { AvailableSeat, Cart, Order } from 'mapado-ticketing-js-sdk';

import { AvailableSeatType } from '../propTypes';
import {
  getAvailableSeatListFromCart,
  getAvailableSeatListFromOrder,
} from './memoized';

export const isMovableSeat = (
  availableSeat: AvailableSeat | AvailableSeatType | undefined
) => {
  if (!availableSeat) {
    return false;
  }

  return !availableSeat.isLocked;
};

export const getMovableAvailableSeatListFromCart = (cart: Cart) => {
  return getAvailableSeatListFromCart(cart).filter(isMovableSeat);
};

export const getMovableAvailableSeatListFromOrder = (order: Order) => {
  return getAvailableSeatListFromOrder(order).filter(isMovableSeat);
};
