/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import { MpdIcon, MpdToastFunction, TOAST_TYPE } from '@mapado/makeup';
import { Iri } from 'mapado-ticketing-js-sdk';
import NodeDetail, {
  AttributeKey,
  attributeMap,
  ChangeNodeAttributeFunction,
  getTagnameLabel,
} from './NodeDetail';

function getChildNodeList({
  element,
}: {
  element: SVGGElement;
}): Array<HTMLElement> {
  return (
    // eslint-disable-next-line prefer-spread
    [].concat
      // @ts-expect-error don't really care and do not understand this weird syntax
      .apply([], element.childNodes)
      .filter(
        (node: HTMLElement) =>
          typeof node.tagName !== 'undefined' &&
          Object.keys(attributeMap).indexOf(node.tagName.toLowerCase()) >= 0
      )
  );
}

type Props = {
  addTagToElement: (element: Element, value: AttributeKey) => void;
  toast: MpdToastFunction;
  changeNodeAttribute: ChangeNodeAttributeFunction;
  deleteNode: (node: HTMLElement) => void;
  duplicateNode: (node: HTMLElement, index: number, element: Element) => void;
  element: SVGGElement;
  forceRerender: () => void;
  index: number;
  isLast: boolean;
  removeSeatConfigBlock: (index: number) => void;
  setSelectedElement: (
    destinationNode: HTMLElement,
    isEntering: boolean
  ) => void;
  updateSeatConfigBlockInfo: (
    id: Iri<'SeatConfigBlock'>,
    html: string,
    toast: MpdToastFunction,
    updateApiSide?: boolean
  ) => void;
};

// do not extend PureComponent here otherwise forced rerenders won't have any effect
class SideBarElement extends Component<Props, { isAddingElement: boolean }> {
  constructor(props: Props) {
    super(props);

    const childNodeList = getChildNodeList(props);

    this.state = {
      isAddingElement: childNodeList.length === 0,
    };

    this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
    this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);
    this.addElement = this.addElement.bind(this);
    this.deleteCurrentElement = this.deleteCurrentElement.bind(this);
    this.onSelectElementTypeToAdd = this.onSelectElementTypeToAdd.bind(this);
    this.updateCurrentElement = this.updateCurrentElement.bind(this);
    this.duplicateNode = this.duplicateNode.bind(this);
  }

  onSelectElementTypeToAdd(evt: React.ChangeEvent<HTMLSelectElement>) {
    evt.preventDefault();

    const { addTagToElement, element } = this.props;
    const { value } = evt.target;

    // @ts-expect-error Object.keys return AttributeKey[]
    const attributeMapKeys: Array<AttributeKey> = Object.keys(attributeMap);

    // @ts-expect-error to make runtime work
    if (attributeMapKeys.indexOf(value) >= 0) {
      // @ts-expect-error value is AttributeKey here
      addTagToElement(element, value);
    }

    this.setState({
      isAddingElement: false,
    });
  }

  onMouseEnterHandler(destinationNode: HTMLElement): void {
    this.props.setSelectedElement(destinationNode, true);
  }

  onMouseLeaveHandler(destinationNode: HTMLElement): void {
    this.props.setSelectedElement(destinationNode, false);
  }

  deleteCurrentElement(evt: React.MouseEvent): void {
    evt.preventDefault();

    const { removeSeatConfigBlock, index } = this.props;

    removeSeatConfigBlock(index);
  }

  addElement(e: React.MouseEvent): void {
    e.preventDefault();
    this.setState({
      isAddingElement: true,
    });
  }

  updateCurrentElement(): void {
    const { toast, element, updateSeatConfigBlockInfo } = this.props;
    const id = element.getAttribute('id');

    if (!id) {
      toast({ title: 'No id found on this element', type: TOAST_TYPE.ERROR });

      return;
    }

    // @ts-expect-error id is an iri
    updateSeatConfigBlockInfo(id, element.innerHTML, toast);
  }

  duplicateNode(node: HTMLElement, index: number): void {
    this.props.duplicateNode(node, index, this.props.element);
  }

  render() {
    const { changeNodeAttribute, deleteNode, element, forceRerender, isLast } =
      this.props;

    const elementId = element.getAttribute('id');

    if (!elementId) {
      throw new Error('element should have an id');
    }

    const { isAddingElement } = this.state;
    const childNodeList = getChildNodeList(this.props);
    const hasSeatConfigId = elementId.startsWith('/v1/seat_config_blocks/');

    return (
      <div>
        <div className="mpd-block mpd-block-top">
          <div className="mpd-block-content mpd-dropDown mpd-dropDown--keepOpen">
            <button
              type="button"
              className="mpd-btn mpd-btn--link mpd-btn--no-padding mpd-dropDown-link block"
            >
              <h3>
                {hasSeatConfigId && (
                  <span>
                    seat_config_block #
                    {elementId.replace('/v1/seat_config_blocks/', '')}
                  </span>
                )}
                {!hasSeatConfigId && <span>Nouveau seat_config_blick</span>}
              </h3>
              <MpdIcon icon="arrow-down" height="20" width="20" />
            </button>

            <div className="mpd-dropDown-contentWrapper">
              <div className="mpd-dropDown-content">
                <hr />
                <div>
                  {childNodeList.map((node, i) => (
                    <NodeDetail
                      onMouseEnter={this.onMouseEnterHandler}
                      onMouseLeave={this.onMouseLeaveHandler}
                      node={node}
                      changeNodeAttribute={changeNodeAttribute}
                      deleteNode={deleteNode}
                      duplicateNode={this.duplicateNode}
                      index={i}
                      forceRerender={forceRerender}
                      // eslint-disable-next-line react/no-array-index-key
                      key={i}
                      isLast={i === childNodeList.length - 1}
                    />
                  ))}
                </div>

                <hr />
                {!isAddingElement && (
                  <div>
                    <button
                      type="button"
                      onClick={this.addElement}
                      className="mpd-btn mpd-btn--primary block txtcenter"
                    >
                      + Créer un élément
                    </button>
                  </div>
                )}

                {isAddingElement && (
                  <div className="form-group">
                    <select
                      id="plan-editor-new-element-type"
                      defaultValue=""
                      onChange={this.onSelectElementTypeToAdd}
                      className="form-control"
                    >
                      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                      <option value="" />
                      {Object.keys(attributeMap).map((tagName) => (
                        <option value={tagName} key={tagName}>
                          {getTagnameLabel(tagName)}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="plan-editor-new-element-type">
                      Type du nouvel élément
                    </label>
                  </div>
                )}

                <hr />

                <div className="mt2 mb2">
                  <button
                    type="button"
                    className="block txtcenter w100 mpd-btn mpd-btn--primary"
                    onClick={this.updateCurrentElement}
                  >
                    Sauvegarder
                  </button>
                </div>

                <div className="mt2 mb2">
                  <button
                    type="button"
                    className="block txtcenter mpd-btn mpd-btn--secondary w100"
                    onClick={this.deleteCurrentElement}
                  >
                    Supprimer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {!isLast && <hr />}
      </div>
    );
  }
}

export default SideBarElement;
