import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSeat = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 10 9" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="M1 1.5A1.5 1.5 0 0 1 2.5 0h5A1.5 1.5 0 0 1 9 1.5v2c-.384 0-.735.144-1 .382V1.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0-.5.5v2.382A1.5 1.5 0 0 0 1 3.5zM1 4a1 1 0 0 1 1 1v1H0V5a1 1 0 0 1 1-1M.91 6.5H0v.8c0 .663.61 1.2 1.364 1.2h7.272C9.39 8.5 10 7.963 10 7.3v-.8h-.91v.6c0 .22-.203.4-.454.4H1.364c-.251 0-.455-.18-.455-.4zM7.5 6V5h-5v1zM9 4a1 1 0 0 0-1 1v1h2V5a1 1 0 0 0-1-1"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSeat;
