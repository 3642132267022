import clone from 'clone';
import { Moment } from 'moment';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import { BaseEntity, EntityRelation, PartialEntity } from '../..';
import ProviderMappingNotificationClient from '../../../client/ProviderMappingNotificationClient';
import mapEntityRelationShips from '../../../entityFactory/mapEntityRelationShips';
import { parseDate } from '../../../utils/date';
import NetworkEntity from '../../NetworkEntity';
import ProviderTicketImportedFile from '../Import/ProviderTicketImportedFile';

export enum PROVIDER_MAPPING_NOTIFICATION_TYPE {
  ERROR = 'error',
  MISSING = 'missing',
}

export enum PROVIDER_MAPPING_NOTIFICATION_MAPPING_TYPE {
  TICKET_PRICE = 'ticket_price',
  TICKETING = 'ticketing',
  CONTINGENT = 'contingent',
  STOCK_CONTINGENT = 'stock_contingent',
  SEAT_CONFIG = 'seat_config',
  SEAT = 'seat',
  EVENT_DATE = 'event_date',
  AVAILABLE_SEAT = 'available_seat',
}

export type ProviderMappingNotificationType = BaseEntity<'ProviderMappingNotification'> & {
  type: null | undefined | PROVIDER_MAPPING_NOTIFICATION_TYPE;
  mappingType: null | undefined | PROVIDER_MAPPING_NOTIFICATION_MAPPING_TYPE;
  isCancelled: undefined | null | boolean;
  message: undefined | null | string;
  barCode: undefined | null | string;
  provider: undefined | null | string;
  createdAt: undefined | null | Moment;
  providerTicketImportedFile:
    | undefined
    | null
    | EntityRelation<ProviderTicketImportedFile>;
};

class ProviderMappingNotification extends NetworkEntity<ProviderMappingNotificationType>(
  {
    '@id': null,
    '@type': 'ProviderMappingNotification',
    type: undefined,
    mappingType: undefined,
    isCancelled: undefined,
    message: undefined,
    barCode: undefined,
    provider: undefined,
    createdAt: undefined,
    providerTicketImportedFile: undefined,
  }
) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<ProviderMappingNotificationType> = {
      '@id': null,
      '@type': 'ProviderMappingNotification',
    }
  ) {
    const data = clone(val);

    data.createdAt = parseDate(data.createdAt, null);

    super(data);

    return mapEntityRelationShips(this, data);
  }

  getShortId(): string {
    return (
      this.get('@id')?.replace('/v1/provider_mapping_notifications/', '') || ''
    );
  }
}

ProviderMappingNotification.classMetadata = new ClassMetadata(
  'providerMappingNotification',
  'provider_mapping_notifications',
  /** @ts-expect-error -- method signature are incompatible */
  ProviderMappingNotificationClient
);
ProviderMappingNotification.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('type'),
  new Attribute('mappingType'),
  new Attribute('isCancelled', 'isCancelled', 'boolean'),
  new Attribute('message'),
  new Attribute('barCode'),
  new Attribute('provider'),
  new Attribute('createdAt', 'createdAt', 'datetime'),
]);
ProviderMappingNotification.classMetadata.setRelationList([
  new Relation(
    Relation.MANY_TO_ONE,
    'providerTicketImportedFile',
    'providerTicketImportedFile'
  ),
]);

export default ProviderMappingNotification;
