import clone from 'clone';
import { List, Map } from 'immutable';
import { Moment } from 'moment';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import AbstractClient from '../client/AbstractClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import { parseDate } from '../utils/date';
import BookingItem from './BookingItem';
import type Cart from './Cart';
import Customer from './Customer';
import NetworkEntity from './NetworkEntity';
import Order from './Order';
import Ticketing from './Ticketing';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

/** @deprecated use `BOOKING_ENTITY_STATUS.AWAITING_PAYMENT` instead */
export const STATUS_AWAITING_PAYMENT = 'awaiting_payment';
/** @deprecated use `BOOKING_ENTITY_STATUS.INCOMPLETE_PAYMENT` instead */
export const STATUS_INCOMPLETE_PAYMENT = 'incomplete_payment';
/** @deprecated use `BOOKING_ENTITY_STATUS.OVERPAID` instead */
export const STATUS_OVERPAID = 'overpaid';
/** @deprecated use `BOOKING_ENTITY_STATUS.BOOKED` instead */
export const STATUS_BOOKED = 'booked';

export const TRANSACTION_NAME_MULTIPLE = 'multiple';

export enum BOOKING_ENTITY_STATUS {
  AWAITING_PAYMENT = 'awaiting_payment',
  INCOMPLETE_PAYMENT = 'incomplete_payment',
  OVERPAID = 'overpaid',
  BOOKED = 'booked',
  OPTION = 'option',
}

export enum BOOKING_ENTITY_TYPE {
  OPTION = 'option',
  RESERVATION = 'reservation',
  ORDER = 'order',
  TICKET_IMPORT = 'ticket_import',
}

type Tag = string | Map<'name' | 'searchValue', string>;
type PossibleSearchValue = string | number | Map<string, unknown>;
type SearchContent = null | Map<
  string,
  null | PossibleSearchValue | List<PossibleSearchValue>
>;

// eslint-disable-next-line no-shadow
export enum BOOKING_ENTITY_NAME {
  CART = 'cart',
  ORDER = 'order',
}

export type BookingType = BaseEntity<'Booking', `/v1/bookings/${string}`> & {
  cart: null | EntityRelation<Cart>;
  order: null | EntityRelation<Order>;
  customer: null | EntityRelation<Customer>;
  scanned: null | boolean;
  lastScannedDate: null | Moment;
  nbTickets: null | number;
  nbCoupons: null | number;
  nbScannedTickets: null | number;
  nbValidItems: null | number;
  createdAt: null | Moment;
  createdAtTimezone: null | string;
  paymentStatus: null | string;
  status: null | string;
  paymentTransactionBalance: null | number;
  refundTransactionName: null | string;
  paymentTransactionName: null | string;
  facialValue: null | number;
  retailValue: null | number;
  currency: null | string;
  searchContent: SearchContent;
  entityId: null | number;
  entityName: null | BOOKING_ENTITY_NAME;
  bookingItemList: List<BookingItem>;
  paymentLink: null | string;
  isReservation: null | boolean;
  ticketingList: null | List<EntityRelation<Ticketing>>;
  type: null | BOOKING_ENTITY_TYPE;
};

class Booking extends NetworkEntity<BookingType>({
  '@id': null,
  '@type': 'Booking',
  cart: null,
  order: null,
  customer: null,
  scanned: null,
  lastScannedDate: null,
  nbTickets: 0,
  nbCoupons: 0,
  nbScannedTickets: 0,
  nbValidItems: 0,
  createdAt: null,
  createdAtTimezone: null,
  paymentStatus: null,
  status: null,
  paymentTransactionBalance: 0,
  refundTransactionName: null,
  paymentTransactionName: null,
  facialValue: null,
  retailValue: null,
  currency: null,
  searchContent: null,
  entityId: null,
  entityName: null,
  bookingItemList: List<BookingItem>(),
  paymentLink: null,
  isReservation: null,
  ticketingList: null,
  type: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<BookingType> = { '@id': null, '@type': 'Booking' }
  ) {
    const data = clone(val);

    data.createdAt = parseDate(data.createdAt, data.createdAtTimezone);
    data.lastScannedDate = parseDate(
      data.lastScannedDate,
      data.createdAtTimezone
    );

    super(data);

    return mapEntityRelationShips(this, data);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/bookings/', '') || '';
  }

  /** @deprecated createdAt should already handle timezone */
  getLocalCreatedAt(): null | Moment {
    const tz = this.createdAtTimezone;

    if (tz) {
      /** @ts-expect-error -- issue with moment & moment-timezone */
      return this.createdAt?.clone().tz(tz);
    }
    return this.createdAt;
  }

  /** @deprecated lastScannedDate should already handle timezone */
  getLocalLastScannedDate(): null | Moment {
    const tz = this.createdAtTimezone;

    if (tz) {
      /** @ts-expect-error -- issue with moment & moment-timezone */
      return this.lastScannedDate?.clone().tz(tz);
    }

    return this.lastScannedDate;
  }

  getTagList(): List<string> {
    const tags = this.searchContent?.get('tags');
    if (!tags) {
      return List<string>();
    }

    if (typeof tags === 'string') {
      return List(tags);
    }

    if (typeof tags === 'number') {
      throw new Error('searchContent.tags should not be a number');
    }

    if (Map.isMap(tags)) {
      throw new Error('searchContent.tags should not be an object');
    }

    return tags.map<string>((possibleTag: PossibleSearchValue): string => {
      const tag = possibleTag as Tag;

      if (typeof tag === 'string') {
        // @deprecated
        return tag;
      }

      return tag.get('name') ?? '';
    });
  }
}

Booking.classMetadata = new ClassMetadata(
  'booking',
  'bookings',
  /** @ts-expect-error -- method signature are incompatible */
  AbstractClient
);

Booking.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('status'),
  new Attribute('paymentStatus'),
  new Attribute(
    'paymentTransactionBalance',
    'paymentTransactionBalance',
    'integer'
  ),
  new Attribute('refundTransactionName', 'refundTransactionName', 'integer'),
  new Attribute('paymentTransactionName', 'paymentTransactionName', 'integer'),
  new Attribute('facialValue', 'facialValue', 'integer'),
  new Attribute('retailValue', 'retailValue', 'integer'),
  new Attribute('currency'),
  new Attribute('searchContent'),
  new Attribute('scanned', 'scanned', 'boolean'),
  new Attribute('nbTickets', 'nbTickets', 'integer'),
  new Attribute('nbValidItems', 'nbValidItems', 'integer'),
  new Attribute('nbScannedTickets', 'nbScannedTickets', 'integer'),
  new Attribute('nbCoupons', 'nbCoupons', 'integer'),
  new Attribute('createdAt', 'createdAt', 'datetime'),
  new Attribute('createdAtTimezone', 'createdAtTimezone', 'string'),
  new Attribute('entityId'),
  new Attribute('entityName'),
  new Attribute('paymentLink'),
  new Attribute('isReservation', 'isReservation', 'boolean'),
  new Attribute('type'),
]);

Booking.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'cart', 'cart'),
  new Relation(Relation.MANY_TO_ONE, 'order', 'order'),
  new Relation(Relation.MANY_TO_ONE, 'customer', 'customer'),
  new Relation(Relation.ONE_TO_MANY, 'ticketing', 'ticketingList'),
]);

export default Booking;
