import React from 'react';
import { components, ClearIndicatorProps } from 'react-select';
import MpdIcon from '../../../MpdIcon';
import { MultiSelectOption, SingleSelectOption } from './utils';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ClearIndicator = (
  props: ClearIndicatorProps<SingleSelectOption | MultiSelectOption>
) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <components.ClearIndicator {...props}>
    <MpdIcon icon="cross" className="mpd-color-light-gray ml1" />
  </components.ClearIndicator>
);

export default ClearIndicator;
