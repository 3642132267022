import React from 'react';
import { components, GroupHeadingProps } from 'react-select';
import { MultiSelectOption, SelectGroupedOption, SingleSelectOption } from './utils';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const GroupHeading = (props: GroupHeadingProps<SingleSelectOption | MultiSelectOption, boolean, SelectGroupedOption<SingleSelectOption | MultiSelectOption>>) => {
  const { data } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.GroupHeading {...props} className="mpd-select-group-heading">
      <span className="mpd-select-group-heading-label">{data.label}</span>
      <span className="mpd-select-group-heading-count">
        {data.groupCountLabel}
      </span>
    </components.GroupHeading>
  );
};

export default GroupHeading;
