import React, { useCallback, useEffect, useState } from 'react';
import { Set } from 'immutable';
import { StockContingent } from 'mapado-ticketing-js-sdk';
import type { RootState } from '../../reducers';
import {
  initStockGauge,
  initStockContingent,
  initStockGroup,
} from '../../actions/AppActions';
import { resetSelectedSeats, resetState } from '../../actions/SeatActions';
import {
  StockManagementProvider,
  STOCK_MANAGEMENT_TABS,
} from '../../contexts/StockManagementContext';
import { useViewContext, View } from '../../contexts/ViewContext';
import {
  seatConfigSelector,
  isLoadedAvailableSeatList,
  isLoadedEventDateWithData,
} from '../../utils/selectors';
import {
  getSelectableSeatIdSetForStockManagementContingent,
  getSelectableSeatIdSetForStockManagementGauge,
  getSelectableSeatIdSetForStockManagementSeatGroup,
} from '../../utils/seatSelectable';
import SeatingLoader from '../SeatingLoader';
import StockManagementSeating from '../StockManagementSeating';
import StockManagementSidebar from '../Sidebar/StockManagementSidebar';
import '../../styles/components/App.scss';
import { SelectedSeatGroupContextProvider } from '../../contexts/SelectedSeatGroupContext';
import {
  useSeatingDispatch,
  useSeatingSelector,
  useSeatingStore,
} from '../../reducers/typedFunctions';
import { OnStockChangedType } from '../StockManagementSeating/hooks';

export type StockManagementProps = {
  eventDateId: number;
  onStockChanged?: OnStockChangedType;
};

type TabData = {
  getSelectableSeats: (
    state: RootState,
    seatIdSet: Set<string>,
    selectedStockContingent?: string | StockContingent | null
  ) => Set<string>;
  initData: ({
    seatConfigId,
    eventDateId,
    hasError,
  }: {
    seatConfigId?: null | string | number;
    eventDateId: null | number;
    hasError: boolean;
  }) => void;
};

const tabsData: { [key: string]: TabData } = {
  [STOCK_MANAGEMENT_TABS.CONTINGENT]: {
    getSelectableSeats: getSelectableSeatIdSetForStockManagementContingent,
    initData: initStockContingent,
  },
  [STOCK_MANAGEMENT_TABS.SEAT_GROUP]: {
    getSelectableSeats: getSelectableSeatIdSetForStockManagementSeatGroup,
    initData: initStockGroup,
  },
  [STOCK_MANAGEMENT_TABS.GAUGE]: {
    getSelectableSeats: getSelectableSeatIdSetForStockManagementGauge,
    initData: initStockGauge,
  },
};

function StockManagement({
  eventDateId,
  onStockChanged,
}: StockManagementProps) {
  const store = useSeatingStore();
  const dispatch = useSeatingDispatch();

  // reset state when unmounting the component to clear redux state.
  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, [dispatch]);

  const seatConfig = useSeatingSelector(seatConfigSelector);
  const isReady =
    isLoadedAvailableSeatList(store.getState()) &&
    isLoadedEventDateWithData(store.getState());

  const { onSelectView } = useViewContext();

  const [activeTab, setActiveTab] = useState(STOCK_MANAGEMENT_TABS.CONTINGENT);

  const getData = useCallback(
    (hasError = false) => {
      // @ts-expect-error don't know. initData is weird
      dispatch(tabsData[activeTab].initData({ eventDateId, hasError }));
    },
    [activeTab, dispatch, eventDateId]
  );

  useEffect(() => {
    getData();
  }, [getData]);

  const handleTabChange = (tabName: STOCK_MANAGEMENT_TABS) => {
    setActiveTab(tabName);

    if (tabName === STOCK_MANAGEMENT_TABS.SEAT_GROUP) {
      onSelectView(View.SeatGroup);
    }

    if (tabName === STOCK_MANAGEMENT_TABS.CONTINGENT) {
      onSelectView(View.Contingent);
    }

    // reset selection between tabs
    dispatch(resetSelectedSeats());
  };

  if (!isReady) {
    return <SeatingLoader />;
  }

  if (!seatConfig) {
    throw new Error(
      'SeatConfig should be set in store. Maybe you forgot to check that `app.loadStatus.seatConfigData` is set'
    );
  }

  return (
    <SelectedSeatGroupContextProvider>
      <StockManagementProvider>
        <div
          className="mpd-seating__app context-stock-management"
          id="mpd-seating__app"
        >
          <div className="mpd-seating__app__container">
            <StockManagementSeating
              onStockChanged={onStockChanged}
              seatConfigBlockList={seatConfig.seatConfigBlockList}
              onGetSelectableSeats={tabsData[activeTab].getSelectableSeats}
              onError={() => getData(true)}
            />

            <StockManagementSidebar
              onTabChange={handleTabChange}
              onError={() => getData(true)}
            />
          </div>
        </div>
      </StockManagementProvider>
    </SelectedSeatGroupContextProvider>
  );
}

export default StockManagement;
