import clone from 'clone';
import { List, Map } from 'immutable';
import { Moment } from 'moment';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
import TicketPriceClient from '../client/TicketPriceClient';
import EntityRegistry from '../entityFactory/EntityRegistry';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import { parseDate } from '../utils/date';
import { Media } from '../utils/mediaType';
import BookingTrack from './BookingTrack';
import Contingent from './Contingent';
import EventDate from './EventDate';
import EventDatePattern from './EventDatePattern';
import { HidableType } from './HidableType';
import NetworkEntity from './NetworkEntity';
import OfferRule from './OfferRule';
import ScanTrack from './ScanTrack';
import SellingDevice from './SellingDevice';
import StockContingent from './StockContingent';
import Tax from './Tax';
import TicketPriceGroup from './TicketPriceGroup';
import TicketPriceSeatGroup from './TicketPriceSeatGroup';
import Wallet from './Wallet';
import { extractTimezone } from './utils';
import { BaseEntity, EntityRelation, PartialEntity } from '.';

export const TYPE_FULL_PRICE = 'full_price';
export const TYPE_REDUCED = 'reduced';
export const TYPE_EXEMPTED = 'exempted';
export const TYPE_SUBSCRIPTION = 'subscription';
export const TYPE_SUBSCRIPTION_MEMBER_LOYALTY = 'subscriptionMemberLoyalty';
export const TYPE_SCHOLAR = 'scholar';
export const TYPE_GROUP = 'group';
export const TYPE_RESELLER = 'reseller';
export const TYPE_OTHER = 'other';

type TYPE_FULL_PRICE = 'full_price';
type TYPE_REDUCED = 'reduced';
type TYPE_EXEMPTED = 'exempted';
type TYPE_SUBSCRIPTION = 'subscription';
type TYPE_SUBSCRIPTION_MEMBER_LOYALTY = 'subscriptionMemberLoyalty';
type TYPE_SCHOLAR = 'scholar';
type TYPE_GROUP = 'group';
type TYPE_RESELLER = 'reseller';
type TYPE_OTHER = 'other';

export type TICKET_PRICE_TYPE_LIST =
  | TYPE_FULL_PRICE
  | TYPE_REDUCED
  | TYPE_EXEMPTED
  | TYPE_SUBSCRIPTION
  | TYPE_SUBSCRIPTION_MEMBER_LOYALTY
  | TYPE_SCHOLAR
  | TYPE_GROUP
  | TYPE_RESELLER
  | TYPE_OTHER;

export type TicketPriceType = HidableType &
  BaseEntity<'TicketPrice'> & {
    type: null | TICKET_PRICE_TYPE_LIST;
    hideFees: null | boolean;
    name: null | string;
    description: null | string;
    hash: null | string;
    slug: null | string;
    enabled: null | boolean;
    favorite: null | boolean;
    saleStartDate: null | Moment;
    saleEndDate: null | Moment;
    onSale: null | boolean;
    facialValue: null | number;
    currency: null | string;
    customerPaidAmountBySellingDevice: null | Map<string, number>;
    amountForProBySellingDevice: null | Map<string, number>;
    bookableStock: null | number;
    remainingStock: null | number;
    totalStock: null | number;
    manuallySetStock: null | number;
    unlimitedStock: null | boolean;
    soldTickets: null | number;
    bookedTickets: null | number;
    issuedTickets: null | number;
    reservedTickets: null | number;
    soldItems?: number;
    bookedItems?: number;
    issuedItems?: number;
    reservedItems?: number;
    manualSort: null | number;
    amountForProExvatBySellingDevice: null | Map<string, number>;
    eventDate: null | EntityRelation<EventDate>;
    eventDatePattern: null | EntityRelation<EventDatePattern>;
    tax: null | EntityRelation<Tax>;
    ticketPriceGroup: null | EntityRelation<TicketPriceGroup>;
    ticketPriceSeatGroupList: null | List<EntityRelation<TicketPriceSeatGroup>>;
    sellingDeviceList: null | List<EntityRelation<SellingDevice>>;
    notOnSaleReasons: List<string>;
    wallet: null | EntityRelation<Wallet>;
    dynamic: null | boolean;
    buyable: null | boolean;
    visible: null | boolean;
    offerRuleList: null | List<EntityRelation<OfferRule>>;
    behaviour: null | string;
    willGenerateTicket: null | boolean;
    ticketExpirationInterval: null | string;
    excludedFromParentStock: null | boolean;
    isPriceDisplayed: null | boolean;
    bookingTrack: null | EntityRelation<BookingTrack>;
    scanTrack: null | EntityRelation<ScanTrack>;
    /** @deprecated use `defaultStockContingent` instead */
    onlineStockContingent: null | EntityRelation<StockContingent>;
    /** @deprecated use `defaultContingent` instead */
    onlineContingent: null | EntityRelation<Contingent>;
    defaultStockContingent: null | EntityRelation<StockContingent>;
    defaultContingent: null | EntityRelation<Contingent>;
    onlineMaxQuantityPerCart: undefined | number;
    isHidden: undefined | boolean;
    imageList: null | List<string>;
    mediaList: null | List<Media>;
  };

export type TicketPriceNonNullProps =
  | '@id'
  | 'excludedFromParentStock'
  | 'facealValue'
  | 'type'
  | 'createdAt'
  | 'updatedAt'
  | 'tax'
  | 'buyable'
  | 'visible'
  | 'willGenerateTicket'
  | 'isPriceDisplayed'
  | 'currency'
  | 'unlimitedStock';

class TicketPrice extends NetworkEntity<TicketPriceType>({
  '@id': null,
  '@type': 'TicketPrice',
  type: null,
  hideFees: null,
  name: '',
  description: '',
  hash: '',
  slug: null,
  enabled: true,
  favorite: null,
  saleStartDate: null,
  saleEndDate: null,
  onSale: false,
  facialValue: null,
  currency: null,
  customerPaidAmountBySellingDevice: Map<string, number>(),
  amountForProBySellingDevice: Map<string, number>(),
  bookableStock: null,
  remainingStock: null,
  totalStock: null,
  manuallySetStock: null,
  unlimitedStock: true,
  soldTickets: 0,
  bookedTickets: 0,
  issuedTickets: 0,
  reservedTickets: 0,
  soldItems: undefined,
  bookedItems: undefined,
  issuedItems: undefined,
  reservedItems: undefined,
  manualSort: null,
  amountForProExvatBySellingDevice: Map<string, number>(),
  eventDate: new EventDate(),
  eventDatePattern: new EventDatePattern(),
  tax: new Tax({}),
  ticketPriceGroup: null,
  ticketPriceSeatGroupList: List<EntityRelation<TicketPriceSeatGroup>>(),
  sellingDeviceList: List<EntityRelation<SellingDevice>>(),
  notOnSaleReasons: List<string>(),
  wallet: new Wallet(),
  dynamic: false,
  buyable: null,
  visible: null,
  offerRuleList: List<EntityRelation<OfferRule>>(),
  behaviour: null,
  willGenerateTicket: true,
  ticketExpirationInterval: null,
  excludedFromParentStock: null,
  isPriceDisplayed: null,
  bookingTrack: null,
  scanTrack: null,
  onlineStockContingent: null,
  onlineContingent: null,
  defaultStockContingent: null,
  defaultContingent: null,
  onlineMaxQuantityPerCart: undefined,
  isHidden: false,
  imageList: null,
  mediaList: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<TicketPriceType> = {
      '@id': null,
      '@type': 'TicketPrice',
    },
    registry?: EntityRegistry
  ) {
    const data = clone(val);

    const tz =
      extractTimezone(data, ['eventDate', 'ticketing', 'timezone']) ||
      extractTimezone(data, ['wallet', 'contract', 'timezone']);

    data.saleStartDate = parseDate(data.saleStartDate, tz);
    data.saleEndDate = parseDate(data.saleEndDate, tz);

    super(data);

    return mapEntityRelationShips(this, data, registry);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/ticket_prices/', '') || '';
  }

  getRemainingStock(): null | number {
    if (this.get('unlimitedStock')) {
      return null;
    }

    return this.get('remainingStock');
  }

  getBookableStock(): null | number {
    if (this.get('unlimitedStock')) {
      return null;
    }

    return this.get('bookableStock');
  }

  // types helper
  getIn(
    path: ['eventDate' | 'eventDatePattern' | 'wallet', '@id']
  ): null | string;

  getIn(keyPath: Iterable<unknown>): unknown {
    return super.getIn(keyPath);
  }
}

TicketPrice.classMetadata = new ClassMetadata(
  'ticketPrice',
  'ticket_prices',
  /** @ts-expect-error -- method signature are incompatible */
  TicketPriceClient
);
TicketPrice.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('type'),
  new Attribute('hideFees', 'hideFees', 'boolean'),
  new Attribute('name'),
  new Attribute('description'),
  new Attribute('hash'),
  new Attribute('slug'),
  new Attribute('behaviour'),
  new Attribute('willGenerateTicket'),
  new Attribute('ticketExpirationInterval'),
  new Attribute('enabled', 'enabled', 'boolean'),
  new Attribute('favorite', 'favorite', 'boolean'),
  new Attribute('saleStartDate', 'saleStartDate', 'datetime'),
  new Attribute('saleEndDate', 'saleEndDate', 'datetime'),
  new Attribute('onSale', 'onSale', 'boolean'),
  new Attribute('facialValue', 'facialValue', 'integer'),
  new Attribute('currency'),
  new Attribute(
    'customerPaidAmountBySellingDevice',
    'customerPaidAmountBySellingDevice',
    'object'
  ),
  new Attribute(
    'amountForProBySellingDevice',
    'amountForProBySellingDevice',
    'object'
  ),
  new Attribute('bookableStock', 'bookableStock', 'integer'),
  new Attribute('remainingStock', 'remainingStock', 'integer'),
  new Attribute('totalStock', 'totalStock', 'integer'),
  new Attribute('manuallySetStock', 'manuallySetStock', 'boolean'),
  new Attribute('unlimitedStock', 'unlimitedStock', 'boolean'),
  new Attribute('soldTickets', 'soldTickets', 'integer'),
  new Attribute('bookedTickets', 'bookedTickets', 'integer'),
  new Attribute('issuedTickets', 'issuedTickets', 'integer'),
  new Attribute('reservedTickets', 'reservedTickets', 'integer'),
  new Attribute('manualSort', 'manualSort', 'integer'),
  new Attribute(
    'amountForProExvatBySellingDevice',
    'amountForProExvatBySellingDevice',
    'object'
  ),
  new Attribute('notOnSaleReasons', 'notOnSaleReasons', 'object'),
  new Attribute('dynamic', 'dynamic', 'boolean'),
  new Attribute('buyable', 'buyable', 'boolean'),
  new Attribute('visible', 'visible', 'boolean'),
  new Attribute(
    'excludedFromParentStock',
    'excludedFromParentStock',
    'boolean'
  ),
  new Attribute('isPriceDisplayed', 'isPriceDisplayed', 'boolean'),
  new Attribute(
    'onlineMaxQuantityPerCart',
    'onlineMaxQuantityPerCart',
    'integer'
  ),
  new Attribute('isHidden', 'isHidden', 'boolean'),
  new Attribute('imageList', 'imageList', 'array'),
  new Attribute('mediaList', 'mediaList', 'array'),
]);

TicketPrice.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'eventDate', 'eventDate'),
  new Relation(Relation.MANY_TO_ONE, 'eventDatePattern', 'eventDatePattern'),
  new Relation(Relation.MANY_TO_ONE, 'tax', 'tax'),
  new Relation(Relation.MANY_TO_ONE, 'ticketPriceGroup', 'ticketPriceGroup'),
  new Relation(
    Relation.ONE_TO_MANY,
    'ticketPriceSeatGroup',
    'ticketPriceSeatGroupList'
  ),
  new Relation(Relation.ONE_TO_MANY, 'sellingDevice', 'sellingDeviceList'),
  new Relation(Relation.MANY_TO_ONE, 'wallet', 'wallet'),
  new Relation(Relation.MANY_TO_MANY, 'offerRule', 'offerRuleList'),
  new Relation(Relation.MANY_TO_ONE, 'bookingTrack', 'bookingTrack'),
  new Relation(Relation.MANY_TO_ONE, 'scanTrack', 'scanTrack'),
  new Relation(
    Relation.MANY_TO_ONE,
    'stockContingent',
    'onlineStockContingent'
  ),
  new Relation(
    Relation.MANY_TO_ONE,
    'stockContingent',
    'defaultStockContingent'
  ),
  new Relation(Relation.MANY_TO_ONE, 'contingent', 'onlineContingent'),
  new Relation(Relation.MANY_TO_ONE, 'contingent', 'defaultContingent'),
]);

export default TicketPrice;
