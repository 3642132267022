import React, {
  Component,
  ReactNode,
  ReactElement,
  RefObject,
  ChangeEvent,
} from 'react';
import cn from 'classnames';
import mpdTabInit from '../js-libs/mpdTab';

const SETUP_ANIMATION_DURATION = 200;

type Tab = {
  id: string;
  defaultSelected?: boolean;
  label: string;
  labelClassName: string;
  TabContent?: Array<ReactNode> | ReactNode;
};

type DefaultProps = {
  containerClassName: string;
  flex: boolean;
};

type Props = DefaultProps & {
  name: string;
  // eslint-disable-next-line react/require-default-props
  onChangeTab?: (tab: string) => void;
  tabs: Array<Tab>;
};

type State = {
  selectedTab: string | null;
};

class MpdTabs extends Component<Props, State> {
  static defaultProps: DefaultProps = {
    // eslint-disable-next-line react/default-props-match-prop-types
    containerClassName: '',
    // eslint-disable-next-line react/default-props-match-prop-types
    flex: false,
  };

  #selector: RefObject<HTMLDivElement>;

  #container: RefObject<HTMLDivElement>;

  #initTimeout: number;

  constructor(props: Props) {
    super(props);

    this.#selector = React.createRef();
    this.#container = React.createRef();
    this.#initTimeout = 0;

    const tabSelected = props.tabs.find((tab) => tab.defaultSelected === true);

    this.state = {
      selectedTab:
        (tabSelected && tabSelected.id) || (props.tabs[0] && props.tabs[0].id),
    };

    this.animateSelector = this.animateSelector.bind(this);
    this.getTargetId = this.getTargetId.bind(this);
    this.handleChangeEvent = this.handleChangeEvent.bind(this);
  }

  componentDidMount(): void {
    mpdTabInit();

    // Setup selector animation
    this.#initTimeout = window.setTimeout(
      this.animateSelector,
      SETUP_ANIMATION_DURATION
    );
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    const { selectedTab } = this.state;
    const { tabs, onChangeTab } = this.props;

    if (prevProps.tabs.length !== tabs.length) {
      const tabSelected = tabs.find((tab) => tab.defaultSelected === true);

      if (!tabSelected) {
        return;
      }

      // eslint-disable-next-line
      this.setState({ selectedTab: tabSelected.id }, this.animateSelector);
    }

    if (selectedTab !== prevState.selectedTab) {
      if (onChangeTab && selectedTab) {
        onChangeTab(selectedTab);
      }
    }
  }

  componentWillUnmount(): void {
    window.clearTimeout(this.#initTimeout);
  }

  getSelectorPosition(selectedItem: HTMLElement): number {
    const selectedPosition = selectedItem.getBoundingClientRect().left;
    const containerPosition =
      this.#container.current?.getBoundingClientRect().left || 0;

    return selectedPosition - containerPosition;
  }

  getTargetId(id: string): string {
    return `mpd-tab-content-target-${id}`;
  }

  animateSelector(selected?: HTMLElement): void {
    const container = this.#container.current;
    const selectedItem =
      selected ||
      container?.querySelector('.selected') ||
      container?.querySelector('.mpd-tabs__tab');

    if (selectedItem) {
      const position = this.getSelectorPosition(selectedItem);
      const selector = this.#selector.current;

      if (!selector) {
        return;
      }

      selector.style.width = `${selectedItem.offsetWidth}px`;
      selector.style.left = `${position < 0 ? 0 : position}px`;
    }
  }

  handleChangeEvent(event: ChangeEvent<HTMLInputElement>): void {
    const { target } = event;

    this.setState(
      {
        selectedTab: target.getAttribute('id'),
      },
      () => {
        this.animateSelector(target.parentNode as HTMLElement);
      }
    );
  }

  render(): ReactElement {
    const { containerClassName, name, tabs, flex } = this.props;
    const { selectedTab } = this.state;
    const classNames = cn('mpd-tabs', {
      'mpd-tabs--flex': flex,
    });

    return (
      <section className={classNames}>
        <div
          ref={this.#container}
          className={`mpd-tabs__tab-container ${containerClassName}`}
        >
          <div ref={this.#selector} className="selector" />
          {tabs.map(({ id, defaultSelected, label, labelClassName }) => (
            <div
              className={`mpd-tabs__tab ${
                defaultSelected || id === selectedTab ? 'selected' : ''
              }`}
              key={id}
            >
              <input
                id={id}
                data-target={this.getTargetId(id)}
                type="radio"
                name={name}
                className="mpd-tabs__tab-selector"
                defaultChecked={defaultSelected}
                onChange={this.handleChangeEvent}
              />
              <label
                htmlFor={id}
                className={`mpd-tabs__tab-label ${labelClassName}`}
              >
                {label}
              </label>
            </div>
          ))}
        </div>

        <div className="mpd-tabs__content-container">
          {tabs.map(({ defaultSelected, id, TabContent }) => (
            <div
              className={`mpd-tabs__content ${
                defaultSelected || id === selectedTab
                  ? 'mpd-tabs__content opened'
                  : ''
              }`}
              id={this.getTargetId(id)}
              key={id}
            >
              {TabContent}
            </div>
          ))}
        </div>
      </section>
    );
  }
}

export default MpdTabs;
