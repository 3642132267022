import React from 'react';
// @ts-expect-error -- See https://github.com/microsoft/TypeScript/issues/33079#issuecomment-911893337
import { $secondaryGray } from '@mapado/makeup/variables';
import BaseSeat, { SeatProps } from './BaseSeat';
import { getIcon } from '../../utils/seatStyle';
import { useCartContext } from '../CartReplacement/CartContext';
import { getAvailableSeatListFromCart } from '../../utils/memoized';

const OrderViewerSeat = React.memo(
  ({
    color,
    seatEntity,
    isSelectable,
    isSelected,
    bookingStatus,
    isDesk,
    ...rest
  }: Props) => {
    const cart = useCartContext();

    const inBooking = !!(
      cart &&
      getAvailableSeatListFromCart(cart).find((availableSeat) => {
        return availableSeat['@id'] === seatEntity['@id'];
      })
    );

    return (
      <BaseSeat<'AvailableSeat'>
        {...rest}
        isDesk={isDesk}
        bookingStatus={bookingStatus}
        seatEntity={seatEntity}
        icon={getIcon(bookingStatus, inBooking)}
        fill={!isSelectable && !isDesk ? $secondaryGray : color}
        isSelectable={isSelectable}
        isSelected={isSelected || inBooking}
        isZoomed={isSelected}
      />
    );
  }
);

OrderViewerSeat.displayName = 'OrderViewerSeat';

type Props = SeatProps<'AvailableSeat'> & {
  color: string;
};

export default OrderViewerSeat;
