import React from 'react';
import { TicketPrice, assertRelationIsObject } from 'mapado-ticketing-js-sdk';
import { List } from 'immutable';
// @ts-expect-error -- See https://github.com/microsoft/TypeScript/issues/33079#issuecomment-911893337
import { $secondaryGray } from '@mapado/makeup/variables';
import { getIcon } from '../../utils/seatStyle';
import BaseSeat, { SeatProps } from './BaseSeat';
import { useCurrentContingentContext } from '../CurrentContingentContext';
import { StockContingentType } from '../../propTypes';

function getStockContingentId(
  contingentEntity: string | StockContingentType | null | undefined
): string | null {
  if (typeof contingentEntity === 'string') {
    return contingentEntity;
  }

  if (contingentEntity) {
    return contingentEntity['@id'];
  }

  return null;
}

const SeatingPlanPurchaseSeat = React.memo(
  ({
    color,
    isSelectable,
    isSelected,
    bookingStatus,
    seatEntity,
    getTicketPriceListBySeatGroup,
    isDesk,
    ...rest
  }: Props) => {
    const seatGroup = seatEntity?.seatGroup;

    assertRelationIsObject(seatGroup, 'seatGroup');

    const ticketPriceList = getTicketPriceListBySeatGroup(
      seatGroup['@id'],
      null
    );

    const hasTicketPrice = ticketPriceList && ticketPriceList.size > 0;

    const currentContingentId = useCurrentContingentContext();

    const contingentEntity = seatEntity.stockContingent;

    const isSelectedContingent =
      getStockContingentId(contingentEntity) === currentContingentId;

    const isSelectableSeat =
      isSelectable && hasTicketPrice && isSelectedContingent;

    return (
      <BaseSeat<'AvailableSeat'>
        {...rest}
        isDesk={isDesk}
        seatEntity={seatEntity}
        isSelectable={isSelectableSeat}
        bookingStatus={bookingStatus}
        icon={isSelected ? 'seat-filled' : getIcon(bookingStatus, false)}
        fill={!isSelectableSeat && !isDesk ? $secondaryGray : color}
        isSelected={isSelected}
      />
    );
  }
);

type Props = SeatProps<'AvailableSeat'> & {
  color: string;
  getTicketPriceListBySeatGroup: (
    seatGroup: string,
    stockContingent: string | null
  ) => List<TicketPrice>;
};

SeatingPlanPurchaseSeat.displayName = 'SeatingPlanPurchaseSeat';

export default SeatingPlanPurchaseSeat;
