import { Map, List, Set, MapOf } from 'immutable';
import { ThunkAction } from 'redux-thunk';
import { getEntityId, getShortId } from '@mapado/js-component';
import {
  assertRelationIsDefined,
  assertRelationIsObject,
  Booking,
  Contingent,
  StockContingent,
  AvailableSeatModelBulkRequestMember,
  AVAILABLE_SEAT_BOOKING_STATUS,
  AVAILABLE_SEAT_STATUS_BOOKED,
  AVAILABLE_SEAT_STATUS_PAID,
  AvailableSeat,
  AvailableSeatStatus,
  Contract,
  Iri,
} from 'mapado-ticketing-js-sdk';
import { colorList } from '../config/config';
import {
  SELECT_SEAT,
  SET_AVAILABLE_SEAT_STATUS,
  REQUEST_MOVE_SEAT_LIST,
  MOVE_SEAT_LIST_FAILURE,
  MOVE_SEAT_LIST_SUCCESS,
  FOCUS_BOOKING,
  UNFOCUS_BOOKING,
  SET_STOCK_CONTINGENT_LIST,
  SET_CONTINGENT_TO_AVAILABLE_SEAT_MODEL_LIST,
  SET_EVENTDATE_DATA,
  INIT_CLEANUP_STATE,
  ADD_SEAT_CONFIG_BLOCK,
  REMOVE_SEAT_CONFIG_BLOCK,
  UPDATE_SEAT_CONFIG_BLOCK,
  UPDATE_SEAT_POSITION,
  UPDATE_SEAT_LIST,
  SELECT_SEAT_LIST,
  SET_SEATCONFIG_DATA,
  SET_AVAILABLE_SEAT_LIST,
  SET_AVAILABLE_SEAT_MODEL_LIST,
  SET_LOGICALSEATCONFIG_DATA,
  REQUEST_UPDATE_AVAILABLE_SEAT_MODEL_LIST,
  UPDATE_AVAILABLE_SEAT_MODEL_LIST_SUCCESS,
  UPDATE_AVAILABLE_SEAT_MODEL_LIST_FAILURE,
  ADD_SEAT_GROUP,
  ADD_CONTINGENT,
  REMOVE_CONTINGENT,
  UPDATE_AVAILABLE_SEAT_LIST_SEAT_GROUP,
  SET_AVAILABLE_SEAT_LIST_AVAILABLE,
  SET_CONTRACT,
} from './types';
import type { RootState } from '.';
import type {
  SeatConfigBlockType,
  SeatType,
  SeatConfigType,
  NoGroupSeatType,
  AvailableSeatType,
  EventDateType,
  SeatGroupType,
  AvailableSeatModelType,
  LogicalSeatConfigType,
  UpdateAvailableSeatListSeatGroupType,
} from '../propTypes';
import { required } from '../utils';

export type SeatThunkAction<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  SeatActionTypes
>;

export type SeatStateObject = {
  contract: null | Contract;
  eventDate: null | EventDateType;
  focusedBooking: null | Booking;
  availableSeatList: null | List<AvailableSeatType>;
  stockContingentList: null | List<StockContingent>;
  contingentList: null | List<Contingent>;
  seatList: null | List<SeatType>;
  logicalSeatConfig: null | LogicalSeatConfigType;
  availableSeatModelList: null | List<AvailableSeatModelType>;
  seatGroupList: null | List<SeatGroupType>;
  seatConfig: null | SeatConfigType;
  'movingSeat-fetch-status': Map<'status', string>;
  'updatingAvailableSeatModelList-fetch-status': Map<'status', string>;
  'seat-status': Map<'status', '' | 'IN_PROGRESS' | 'SUCCEEDED'>;
  selectedSeatIdSet: null | Set<number>;
};

export interface SeatState extends MapOf<SeatStateObject> {
  // getIn definitions
  getIn(path: ['seatList', number]): SeatType;
  getIn(path: ['availableSeatList', number]): AvailableSeatType | undefined;
  getIn(path: ['movingSeat-fetch-status', 'status']): string;
  getIn(
    path: ['updatingAvailableSeatModelList-fetch-status', 'status']
  ): string;
  getIn(path: ['seat-status', 'status']): '' | 'IN_PROGRESS' | 'SUCCEEDED';

  // setIn definitions
  setIn(path: ['seatList', number], value: SeatType): this;
  setIn(path: ['availableSeatList', number], value: AvailableSeatType): this;
}

const initialState: SeatState = Map({
  contract: null,
  seatGroupList: null,
  eventDate: null,
  // The focused state is used in EventDateAdmin context only
  // to know which booking / seats is currently focused
  focusedBooking: null,

  // If we're on a context with an eventDate, we shall use availableSeatList, otherwise, we shall use seatList
  availableSeatList: null,
  stockContingentList: null,
  logicalSeatConfig: null,
  availableSeatModelList: null,
  contingentList: null,
  seatList: null,
  seatConfig: null,
  'movingSeat-fetch-status': Map<'status', string>({
    status: '',
  }),
  'seat-status': Map<'status', string>({
    status: '',
  }),
  'updatingAvailableSeatModelList-fetch-status': Map<'status', string>({
    status: '',
  }),
  /**
  * - Used in Editor context to make batch actions on a seat of list
  - Used in CartReplacement, OrderReplacement and OrderViewer contexts to select seats to move
  */
  selectedSeatIdSet: null,
}) as SeatState; // force seat state as we create a Map, not a SeatState but they are in fact compatible

function getColorForSeatGroup(index: number): string {
  const nbColors = colorList.length;
  const realIndex = index >= nbColors ? index % nbColors : index;

  return colorList[realIndex];
}

function setAvailableSeatList(
  state: SeatState,
  availableSeatList: List<AvailableSeatType>
): SeatState {
  let seatGroupMap: Map<string, SeatGroupType> = Map({});

  availableSeatList
    .map((avs) => avs.seatGroup)
    .filter((sg): sg is SeatGroupType => sg !== null)
    .forEach((sg) => {
      if (!seatGroupMap.has(sg['@id'])) {
        seatGroupMap = seatGroupMap.set(sg['@id'], sg);
      }
    });

  return state
    .set(
      'seatGroupList',
      seatGroupMap.toList().sortBy((sg) => sg.label)
    )
    .set('focusedBooking', null)
    .set('availableSeatList', List(availableSeatList))
    .set(
      'seatList',
      List(
        availableSeatList
          .map((avs) => avs.seat)
          .filter((s): s is SeatType => s !== null)
      )
    );
}

function setAvailableSeatModelList(
  state: SeatState,
  availableSeatModelList: List<AvailableSeatModelType>
): SeatState {
  let seatGroupMap: Map<string, SeatGroupType> = Map({});

  availableSeatModelList
    .map((avsm) => avsm.seatGroup)
    .filter((sg): sg is SeatGroupType => sg !== null)
    .forEach((sg) => {
      if (!seatGroupMap.has(sg['@id'])) {
        seatGroupMap = seatGroupMap.set(sg['@id'], sg);
      }
    });

  let contingentMap: Map<string, Contingent> = Map({});

  availableSeatModelList
    .map((avsm) => avsm.contingent)
    .filter((c): c is Contingent => c !== null)
    .forEach((c) => {
      if (!contingentMap.has(getEntityId(c) as string)) {
        contingentMap = contingentMap.set(getEntityId(c) as string, c);
      }
    });

  return state
    .set('seatGroupList', seatGroupMap.toList())
    .set('focusedBooking', null)
    .set('availableSeatModelList', List(availableSeatModelList))
    .set(
      'seatList',
      List(
        availableSeatModelList
          .map((avsm) => avsm.seat)
          .filter((s): s is SeatType => s !== null)
      )
    )
    .set(
      'contingentList',
      contingentMap.toList().sort((a, b) => {
        if (!a.name || !b.name) {
          return 0;
        }

        return a.name.localeCompare(b.name);
      })
    );
}

function updateSeatConfig(
  state: SeatState,
  seatConfig: SeatConfigType,
  extractSeatGroupsAndSeats = false
): SeatState {
  let nextState = state;

  if (extractSeatGroupsAndSeats) {
    nextState = extractAndUpdateSeatGroupList(
      state,
      List<SeatGroupType>(seatConfig.seatGroupList || [])
    );
    nextState = extractAndUpdateSeatList(
      nextState,
      List<SeatGroupType>(seatConfig.seatGroupList || [])
    );
  }

  return nextState.set('seatConfig', seatConfig);
}

function extractAndUpdateSeatList(
  state: SeatState,
  seatGroupList: List<SeatGroupType>
): SeatState {
  const listOfSeatList = seatGroupList
    .map((seatGroup) => required(seatGroup.seatList))
    .toArray();
  // eslint-disable-next-line prefer-spread
  const flatSeatList: SeatType[] = ([] as SeatType[]).concat.apply(
    [],
    listOfSeatList
  );

  return state.set('seatList', List(flatSeatList));
}

function extractAndUpdateSeatGroupList(
  state: SeatState,
  seatGroupList: List<SeatGroupType>
): SeatState {
  let defaultSeatColorIndex = 0;

  seatGroupList.forEach((seatGroup) => {
    if (seatGroup.seatColor === null) {
      // eslint-disable-next-line no-param-reassign
      seatGroup.seatColor = getColorForSeatGroup(defaultSeatColorIndex);
      defaultSeatColorIndex += 1;
    }
  });

  return state.set('seatGroupList', seatGroupList);
}

function updateSeatList(state: SeatState, seatList: List<SeatType>): SeatState {
  const updatedSeatList = (state.get('seatList') || List<SeatType>()).map(
    (seatInSeatList: SeatType): SeatType => {
      const index = seatList.findIndex(
        (s) => s['@id'] === seatInSeatList['@id']
      );

      if (index >= 0) {
        const seatFromSeatList = seatList.get(index);

        if (!seatFromSeatList) {
          throw new Error('seat should be defined. This should not happen');
        }

        return seatFromSeatList;
      }

      return seatInSeatList;
    }
  );

  const oldSeatConfig = state.get('seatConfig');

  if (!oldSeatConfig) {
    throw new Error('old seat config should exist. This should not happen.');
  }

  return state.set('seatList', updatedSeatList);
}

// @DEPRECATED
// @TODO: Use updateSeatList instead
function changeSeatPosition(
  state: SeatState,
  seatId: string,
  x: string | number,
  y: string | number
): SeatState {
  const seatList = state.get('seatList');
  const index = (seatList || List<SeatType>()).findIndex(
    (s: SeatType) => s['@id'] === seatId
  );

  if (index >= 0) {
    const updatedSeat = { ...state.getIn(['seatList', index]) };

    updatedSeat.coordinates.X = x;
    updatedSeat.coordinates.Y = y;

    return state.setIn(['seatList', index], updatedSeat);
  }

  return state;
}

function updateAvailableSeatModelList(
  state: SeatState,
  availableSeatModelList: List<AvailableSeatModelBulkRequestMember>,
  updatedFieldName: 'status' | 'contingent' | 'seatGroup'
) {
  let updatedAvailableSeatModelList =
    state.get('availableSeatModelList') || List<AvailableSeatModelType>();

  availableSeatModelList.forEach((avsm) => {
    const index = updatedAvailableSeatModelList.findIndex(
      (avsmInState) => avsmInState['@id'] === avsm['@id']
    );

    if (index !== -1) {
      const stateAvailableSeatModel = updatedAvailableSeatModelList.get(index);

      if (stateAvailableSeatModel) {
        let updatedFieldValue: string | null | undefined | Contingent;

        if (updatedFieldName === 'contingent') {
          const contingent = state
            .get('contingentList')
            ?.find((c) => c.get('@id') === avsm.contingent);

          if (contingent) {
            updatedFieldValue = contingent;
          }
        } else {
          updatedFieldValue = avsm[updatedFieldName];
        }

        updatedAvailableSeatModelList = updatedAvailableSeatModelList.set(
          index,
          {
            ...stateAvailableSeatModel,
            [updatedFieldName]: updatedFieldValue,
          }
        );
      }
    }
  });

  return state.set('availableSeatModelList', updatedAvailableSeatModelList);
}

function setAvailableSeatStatus(
  state: SeatState,
  seatId: number,
  bookingStatus: AVAILABLE_SEAT_BOOKING_STATUS
): SeatState {
  const nextState = state;
  const seatLongId = `/v1/seats/${seatId}`;
  const availableSeatIndex = (
    state.get('availableSeatList') || List<AvailableSeatType>()
  ).findIndex((as: AvailableSeatType) => {
    assertRelationIsDefined(as, 'as');
    assertRelationIsObject(as.seat, 'as.seat');

    return as.seat['@id'] === seatLongId;
  });

  let status: null | AvailableSeatStatus = null;

  switch (bookingStatus) {
    case AVAILABLE_SEAT_BOOKING_STATUS.AVAILABLE:
    case AVAILABLE_SEAT_BOOKING_STATUS.DISMISSED:
      status = bookingStatus;
      break;
    case AVAILABLE_SEAT_BOOKING_STATUS.SCANNED:
    case AVAILABLE_SEAT_BOOKING_STATUS.IN_ORDER:
      status = AVAILABLE_SEAT_STATUS_PAID;
      break;
    case AVAILABLE_SEAT_BOOKING_STATUS.IN_CART:
    case AVAILABLE_SEAT_BOOKING_STATUS.IN_RESERVATION:
      status = AVAILABLE_SEAT_STATUS_BOOKED;
      break;
    default:
      throw new Error(
        `Unable to set status according to booking status ${bookingStatus}`
      );
      break;
  }

  if (availableSeatIndex >= 0) {
    const availableSeat = nextState.getIn([
      'availableSeatList',
      availableSeatIndex,
    ]);

    if (typeof availableSeat === 'undefined') {
      throw new Error(
        `Unable to find available seat with id ${seatLongId}. This should not happen.`
      );
    }

    const newAvailableSeat = {
      ...availableSeat,
      status,
      bookingStatus,
    };

    return nextState.setIn(
      ['availableSeatList', availableSeatIndex],
      newAvailableSeat
    );
  }

  if (nextState.get('selectedSeatIdSet')?.has(seatId)) {
    return nextState.set('selectedSeatIdSet', Set<number>());
  }

  return nextState;
}

function changeAvailableSeatListSeatGroup(
  state: SeatState,
  availableSeatList: Array<UpdateAvailableSeatListSeatGroupType>
): SeatState {
  const newStateAvailableSeatList = availableSeatList.reduce(
    (
      carry: List<AvailableSeatType>,
      newAvailableSeat: UpdateAvailableSeatListSeatGroupType
    ) => {
      const entry = carry.findEntry(
        (stateAvailableSeat) =>
          stateAvailableSeat['@id'] === newAvailableSeat['@id']
      );

      if (!entry) {
        return carry;
      }

      const [index, stateAvailableSeat] = entry;

      const updatedAvailableSeat = {
        ...stateAvailableSeat,
        seatGroup: newAvailableSeat.seatGroup,
      };

      return carry.set(index, updatedAvailableSeat);
    },
    state.get('availableSeatList') || List()
  );

  return state.set('availableSeatList', newStateAvailableSeatList);
}

type InitCleanupStateAction = {
  type: typeof INIT_CLEANUP_STATE;
};

type SetEventDateDataAction = {
  type: typeof SET_EVENTDATE_DATA;
  eventDate: EventDateType;
};

type SetSeatConfigDataAction = {
  type: typeof SET_SEATCONFIG_DATA;
  seatConfig: SeatConfigType;
  extractSeatGroupsAndSeats: boolean;
};

export type SetLogicalSeatConfigDataAction = {
  type: typeof SET_LOGICALSEATCONFIG_DATA;
  logicalSeatConfig: LogicalSeatConfigType;
};

type SetAvailableSeatListAction = {
  type: typeof SET_AVAILABLE_SEAT_LIST;
  availableSeatList: List<AvailableSeatType>;
};

type SetAvailableSeatModelListAction = {
  type: typeof SET_AVAILABLE_SEAT_MODEL_LIST;
  availableSeatModelList: List<AvailableSeatModelType>;
};

type SetAvailableSeatStatusAction = {
  type: typeof SET_AVAILABLE_SEAT_STATUS;
  seatId: number;
  bookingStatus: AVAILABLE_SEAT_BOOKING_STATUS;
};

type SelectSeatAction = {
  type: typeof SELECT_SEAT;
  isSelected: boolean;
  seatId: number;
};

type SelectSeatListAction = {
  type: typeof SELECT_SEAT_LIST;
  seatList: List<NoGroupSeatType>;
};

type RequestMoveSeatListAction = {
  type: typeof REQUEST_MOVE_SEAT_LIST;
};

type MoveSeatListFailureAction = {
  type: typeof MOVE_SEAT_LIST_FAILURE;
};

type MoveSeatListSuccessAction = {
  type: typeof MOVE_SEAT_LIST_SUCCESS;
  availableSeat: AvailableSeatType;
};

type AddSeatConfigBlockAction = {
  type: typeof ADD_SEAT_CONFIG_BLOCK;
  seatConfigBlock: SeatConfigBlockType;
};

type RemoveSeatConfigBlockAction = {
  type: typeof REMOVE_SEAT_CONFIG_BLOCK;
  index: number;
};

type UpdateSeatConfigBlockAction = {
  type: typeof UPDATE_SEAT_CONFIG_BLOCK;
  id: Iri<'SeatConfigBlock'>;
  info: string;
};

type FocusBookingAction = {
  type: typeof FOCUS_BOOKING;
  booking: Booking;
};

type UnfocusBookingAction = {
  type: typeof UNFOCUS_BOOKING;
};

type UpdateSeatListAction = {
  type: typeof UPDATE_SEAT_LIST;
  seatList: List<SeatType>;
};

type UpdateSeatPositionAction = {
  type: typeof UPDATE_SEAT_POSITION;
  seatId: string;
  x: string | number;
  y: string | number;
};

type SetContingentToSeatListAction = {
  type: typeof SET_CONTINGENT_TO_AVAILABLE_SEAT_MODEL_LIST;
  seatContingentMap: Record<string, Iri<'Contingent'> | null>;
};

type SetStockContingentListAction = {
  type: typeof SET_STOCK_CONTINGENT_LIST;
  stockContingentList: List<StockContingent>;
};

type RequestUpdateAvailableSeatModelList = {
  type: typeof REQUEST_UPDATE_AVAILABLE_SEAT_MODEL_LIST;
};

type UpdateAvailableSeatModelListSuccess = {
  type: typeof UPDATE_AVAILABLE_SEAT_MODEL_LIST_SUCCESS;
  availableSeatModelList: List<AvailableSeatModelBulkRequestMember>;
  updatedFieldName: 'contingent' | 'seatGroup' | 'status';
};

type UpdateAvailableSeatModelListFailure = {
  type: typeof UPDATE_AVAILABLE_SEAT_MODEL_LIST_FAILURE;
  error: unknown;
};

type AddSeatGroupAction = {
  type: typeof ADD_SEAT_GROUP;
  seatGroup: SeatGroupType;
};

type AddContingentAction = {
  type: typeof ADD_CONTINGENT;
  contingent: Contingent;
};

type RemoveContingentAction = {
  type: typeof REMOVE_CONTINGENT;
  contingentId: string;
};

type UpdateAvailableSeatListSeatGroupAction = {
  type: typeof UPDATE_AVAILABLE_SEAT_LIST_SEAT_GROUP;
  availableSeatList: Array<UpdateAvailableSeatListSeatGroupType>;
};

type SetAvailabelSeatListAvailableAction = {
  type: typeof SET_AVAILABLE_SEAT_LIST_AVAILABLE;
  availableSeatList: List<AvailableSeat>;
};

type SetContractAction = {
  type: typeof SET_CONTRACT;
  contract: Contract;
};

export type SeatActionTypes =
  | InitCleanupStateAction
  | SetEventDateDataAction
  | SetSeatConfigDataAction
  | SetAvailableSeatListAction
  | SetAvailableSeatModelListAction
  | SetAvailableSeatStatusAction
  | SelectSeatAction
  | SelectSeatListAction
  | RequestMoveSeatListAction
  | MoveSeatListFailureAction
  | MoveSeatListSuccessAction
  | AddSeatConfigBlockAction
  | RemoveSeatConfigBlockAction
  | UpdateSeatConfigBlockAction
  | FocusBookingAction
  | UnfocusBookingAction
  | UpdateSeatListAction
  | UpdateSeatPositionAction
  | SetStockContingentListAction
  | SetLogicalSeatConfigDataAction
  | SetContingentToSeatListAction
  | RequestUpdateAvailableSeatModelList
  | UpdateAvailableSeatModelListSuccess
  | UpdateAvailableSeatModelListFailure
  | AddSeatGroupAction
  | AddContingentAction
  | RemoveContingentAction
  | UpdateAvailableSeatListSeatGroupAction
  | SetAvailabelSeatListAvailableAction
  | SetContractAction;

function seat(
  // eslint-disable-next-line default-param-last
  state: SeatState = initialState,
  action: SeatActionTypes
): SeatState {
  let index: number;
  let seatConfig: SeatConfigType | null;
  let seatConfigBlockList: SeatConfigBlockType[];

  switch (action.type) {
    case INIT_CLEANUP_STATE:
      return initialState.set('contract', state.get('contract'));
    case SET_CONTRACT:
      return state.set('contract', action.contract);
    case SET_EVENTDATE_DATA:
      return state.set('eventDate', action.eventDate);

    case SET_SEATCONFIG_DATA: {
      return updateSeatConfig(
        state,
        action.seatConfig,
        action.extractSeatGroupsAndSeats
      );
    }

    case SET_LOGICALSEATCONFIG_DATA:
      return state
        .set('logicalSeatConfig', action.logicalSeatConfig)
        .set('seatConfig', action.logicalSeatConfig.seatConfig);

    case SET_AVAILABLE_SEAT_LIST:
      return setAvailableSeatList(state, action.availableSeatList);

    case SET_AVAILABLE_SEAT_MODEL_LIST:
      return setAvailableSeatModelList(state, action.availableSeatModelList);

    case SET_AVAILABLE_SEAT_STATUS: {
      return setAvailableSeatStatus(state, action.seatId, action.bookingStatus);
    }

    case SET_AVAILABLE_SEAT_LIST_AVAILABLE: {
      const stateAvailableSeatList = state.get('availableSeatList');

      if (!stateAvailableSeatList) {
        return state;
      }

      const availableSeatIdList = action.availableSeatList
        .map((avs) => avs['@id'])
        .toSet();

      const entryList = stateAvailableSeatList
        .entrySeq()
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([_key, availableSeat]) =>
          availableSeatIdList.has(availableSeat['@id'])
        );

      const newAvailableSeatList = stateAvailableSeatList.withMutations(
        (innerAvailableSeatList) => {
          entryList.forEach(([key]) => {
            innerAvailableSeatList.setIn([key, 'bookingStatus'], 'available');
          });
        }
      );

      return state.set('availableSeatList', newAvailableSeatList);
    }

    case SELECT_SEAT: {
      if (action.isSelected) {
        return state.set('selectedSeatIdSet', Set([action.seatId]));
      }

      return state.set('selectedSeatIdSet', Set<number>());
    }

    case SELECT_SEAT_LIST: {
      const seatIdSeat: Set<number> = action.seatList
        .map((s) => parseInt(s['@id'].replace('/v1/seats/', ''), 10))
        .toSet();

      return state
        .set('selectedSeatIdSet', seatIdSeat)
        .set('focusedBooking', null);
    }

    case REQUEST_UPDATE_AVAILABLE_SEAT_MODEL_LIST:
      return state.set(
        'updatingAvailableSeatModelList-fetch-status',
        Map<'status', string>({
          status: 'IN_PROGRESS',
        })
      );

    case UPDATE_AVAILABLE_SEAT_MODEL_LIST_FAILURE:
      return state.set(
        'updatingAvailableSeatModelList-fetch-status',
        Map<'status', string>({
          status: 'ERROR',
        })
      );

    case UPDATE_AVAILABLE_SEAT_MODEL_LIST_SUCCESS: {
      const newState = updateAvailableSeatModelList(
        state,
        action.availableSeatModelList,
        action.updatedFieldName
      );

      return newState.set(
        'updatingAvailableSeatModelList-fetch-status',
        Map<'status', string>({
          status: 'SUCCEEDED',
        })
      );
    }

    case REQUEST_MOVE_SEAT_LIST:
      return state.set(
        'movingSeat-fetch-status',
        Map<'status', string>({
          status: 'IN_PROGRESS',
        })
      );

    case MOVE_SEAT_LIST_FAILURE: {
      return state.set(
        'movingSeat-fetch-status',
        Map<'status', string>({
          status: 'ERROR',
        })
      );
    }

    case MOVE_SEAT_LIST_SUCCESS: {
      const { availableSeat } = action;

      let nextSelectedSeatIdList = state.get('selectedSeatIdSet');

      const nextAvailableSeatList =
        state.get('availableSeatList')?.map((avs) => {
          assertRelationIsObject(avs.seat);
          assertRelationIsObject(availableSeat.seat);

          if (avs.seat['@id'] === availableSeat.seat['@id']) {
            return {
              ...avs,
              bookingStatus: availableSeat.bookingStatus,
              ticket: availableSeat.ticket,
              cartItem: availableSeat.cartItem,
            };
          }

          if (
            (availableSeat.ticket !== null &&
              avs.ticket === availableSeat.ticket) ||
            (availableSeat.cartItem !== null &&
              avs.cartItem === availableSeat.cartItem)
          ) {
            nextSelectedSeatIdList =
              nextSelectedSeatIdList?.remove(
                Number(getShortId(avs.seat['@id']))
              ) ?? null;

            return {
              ...avs,
              bookingStatus: AVAILABLE_SEAT_BOOKING_STATUS.AVAILABLE,
              ticket: null,
              cartItem: null,
            };
          }

          return avs;
        }) ?? List();

      return state
        .set(
          'movingSeat-fetch-status',
          Map<'status', string>({
            status: 'SUCCEEDED',
          })
        )
        .set('availableSeatList', nextAvailableSeatList)
        .set('selectedSeatIdSet', nextSelectedSeatIdList)
        .set('focusedBooking', null);
    }

    case ADD_SEAT_CONFIG_BLOCK: {
      const oldSeatConfig = state.get('seatConfig');

      if (!oldSeatConfig) {
        throw new Error(
          'old seat config should exist. This should not happen.'
        );
      }

      const innerSeatConfig: SeatConfigType = { ...oldSeatConfig };

      seatConfigBlockList = innerSeatConfig.seatConfigBlockList.slice(0);
      seatConfigBlockList.push(action.seatConfigBlock);

      return updateSeatConfig(state, {
        ...innerSeatConfig,
        seatConfigBlockList,
      });
    }

    case REMOVE_SEAT_CONFIG_BLOCK: {
      const oldSeatConfig = state.get('seatConfig');

      if (!oldSeatConfig) {
        throw new Error(
          'old seat config should exist. This should not happen.'
        );
      }

      seatConfig = { ...oldSeatConfig };
      seatConfigBlockList = seatConfig.seatConfigBlockList.slice(0);

      if (action.index >= 0) {
        seatConfigBlockList.splice(action.index, 1);

        return updateSeatConfig(state, { ...seatConfig, seatConfigBlockList });
      }

      return state;
    }

    case UPDATE_SEAT_CONFIG_BLOCK: {
      const oldSeatConfig = state.get('seatConfig');

      if (!oldSeatConfig) {
        throw new Error(
          'old seat config should exist. This should not happen.'
        );
      }

      seatConfig = { ...oldSeatConfig };
      seatConfigBlockList = seatConfig.seatConfigBlockList.slice(0);
      index = seatConfigBlockList.findIndex(
        (seatConfigBlock) => seatConfigBlock['@id'] === action.id
      );

      if (index >= 0) {
        seatConfigBlockList[index].info = action.info;

        return updateSeatConfig(state, { ...seatConfig, seatConfigBlockList });
      }

      return state;
    }

    case FOCUS_BOOKING: {
      return state.set('focusedBooking', action.booking);
    }

    case UNFOCUS_BOOKING:
      return state.set('focusedBooking', null).set('selectedSeatIdSet', Set());

    case UPDATE_SEAT_LIST: {
      const { seatList } = action;

      return updateSeatList(state, seatList);
    }

    case UPDATE_SEAT_POSITION: {
      const { seatId, x, y } = action;

      return changeSeatPosition(state, seatId, x, y);
    }

    case SET_CONTINGENT_TO_AVAILABLE_SEAT_MODEL_LIST: {
      const { seatContingentMap } = action;

      const updatedAvailableSeatModelList = (
        state.get('availableSeatModelList') || List<AvailableSeatModelType>()
      ).map(
        (
          availableSeatModel: AvailableSeatModelType
        ): AvailableSeatModelType => {
          const contingent =
            seatContingentMap[availableSeatModel['@id']] || null;

          return {
            ...availableSeatModel,
            contingent,
          };
        }
      );

      return state.set('availableSeatModelList', updatedAvailableSeatModelList);
    }

    case SET_STOCK_CONTINGENT_LIST: {
      return state.set('stockContingentList', action.stockContingentList);
    }

    case ADD_SEAT_GROUP: {
      const seatGroupList = state.get('seatGroupList') || List<SeatGroupType>();

      return state.set('seatGroupList', seatGroupList.push(action.seatGroup));
    }

    case ADD_CONTINGENT: {
      const contingentList = state.get('contingentList') || List<Contingent>();

      return state.set(
        'contingentList',
        contingentList.push(action.contingent)
      );
    }

    case REMOVE_CONTINGENT: {
      const contingentList = state.get('contingentList') || List<Contingent>();
      const contingentIdx = contingentList.findIndex(
        (c) => getEntityId(c) === action.contingentId
      );

      if (contingentIdx !== -1) {
        return state.set(
          'contingentList',
          contingentList.delete(contingentIdx)
        );
      }

      return state;
    }

    case UPDATE_AVAILABLE_SEAT_LIST_SEAT_GROUP: {
      return changeAvailableSeatListSeatGroup(state, action.availableSeatList);
    }

    default:
      return state;
  }
}

export default seat;

export const testables = {
  initialState,
};
