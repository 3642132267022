import React, { ReactNode, InputHTMLAttributes } from 'react';
import cn from 'classnames';
import { generateRandomID } from '../../js-libs/utils';

export type MpdCheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  id?: string;
  className?: string;
  children?: ReactNode;
  indeterminate?: boolean;
} & (
    | {
        card?: never;
        cardWithoutShadow?: never;
        active?: never;
        leftBorderColor?: never;
      }
    | {
        card: boolean;
        cardWithoutShadow?: never;
        active?: boolean;
        leftBorderColor?: string;
      }
    | {
        card?: never;
        cardWithoutShadow: boolean;
        active?: boolean;
        leftBorderColor?: string;
      }
  );

function MpdCheckbox({
  id,
  className,
  card,
  cardWithoutShadow,
  active = true,
  children,
  indeterminate = false,
  leftBorderColor,
  ...input
}: MpdCheckboxProps): JSX.Element {
  const inputId = id ?? generateRandomID();
  const { checked } = { ...input };

  return (
    <div
      className={cn('mpd-checkbox', className, {
        'mpd-card': card || cardWithoutShadow,
        active: active && card,
        'mpd-checkbox--shadow-less': cardWithoutShadow,
        'mpd-checkbox--no-children': !children,
        'mpd-checkbox--indeterminate': indeterminate,
      })}
    >
      {/* hide border if checked checkbox is of type card */}
      {leftBorderColor && !((card || cardWithoutShadow) && checked) && (
        <div
          className="left-border"
          style={{ backgroundColor: leftBorderColor }}
        />
      )}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input type="checkbox" id={inputId} {...input} />
      <label htmlFor={inputId}>
        <span className="mpd-checkbox__check-render">
          <svg viewBox="0 0 14 14">
            <polyline points="0 5 4 8 11 1" />
          </svg>
        </span>
        <div className="mpd-checkbox__children">{children}</div>
      </label>
    </div>
  );
}

export default MpdCheckbox;
