import React from 'react';
import {
  assertRelationIsObject,
  assertRelationIsDefined,
  Order,
} from 'mapado-ticketing-js-sdk';
import {
  formatPrice,
  getShortId,
  useDomainContext,
} from '@mapado/js-component';
import CustomerLine from './CustomerLine';
import { useTranslation } from '../../../i18n';

function OrderTile({ order, eventDateId, canMoveSeats }: OrderTileType) {
  const { t } = useTranslation();
  const { deskDomain } = useDomainContext();

  assertRelationIsObject(order, 'order');

  assertRelationIsDefined(order.retailValue, 'order.retailValue');
  assertRelationIsDefined(order.currency, 'order.currency');

  const formattedPrice = formatPrice(order.retailValue, order.currency);

  const orderId = order.get('@id');

  if (!orderId) {
    throw new Error('Unable to get id for order. This should not happen.');
  }

  const { sellingDevice, hasMovableSeats } = order;

  assertRelationIsObject(sellingDevice, 'sellingDevice');

  return (
    <>
      <section className="mpd-seating-booking-tile__container">
        <div>
          <span>
            {t('booking.number.order', { order: order.getShortId() })}
          </span>
          <CustomerLine entity={order} />
        </div>

        {formattedPrice !== null && (
          <h2 className="mpd-seating-booking-tile__price">{formattedPrice}</h2>
        )}

        <div>
          {order.nbTickets !== 0 &&
            t('order.nb_tickets', {
              count: order.nbTickets ?? 0,
            })}
        </div>

        {order.comment !== null && (
          <div className="mpd-seating-booking-tile__comment">
            {t('order.comment')} {order.comment}
          </div>
        )}
      </section>

      {typeof order !== 'string' && (
        <div className="mpd-seating-booking-tile__action-cell">
          <div>
            {canMoveSeats && !!hasMovableSeats && (
              <a
                href={`${deskDomain}/orders/${getShortId(
                  orderId
                )}/move-seats/${getShortId(eventDateId)}`}
                className="mpd-btn mpd-btn--secondary block"
              >
                {t('booking.move_seats')}
              </a>
            )}

            <a
              href={`${deskDomain}/orders/${getShortId(orderId)}`}
              className="mpd-btn mpd-btn--primary block"
            >
              {t('booking.see_detail_action')}
            </a>
          </div>
        </div>
      )}
    </>
  );
}

type OrderTileType = {
  order: Order;
  eventDateId: string;
  canMoveSeats: boolean;
};

export default OrderTile;
