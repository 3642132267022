import React, { ReactNode } from 'react';

type Props = {
  help?: ReactNode;
  errorMessage?: string | boolean | null | undefined;
  successMessage?: string | boolean | null | undefined;
};

function InputMessagesWrapper({
  help,
  errorMessage,
  successMessage,
}: Props): JSX.Element | null {
  if (!errorMessage && !successMessage && !help) {
    return null;
  }

  return (
    <>
      {!errorMessage && !successMessage && help && (
        <span className="help">{help}</span>
      )}
      {!!errorMessage && (
        <span className="help help--error">{errorMessage}</span>
      )}
      {!!successMessage && !errorMessage && (
        <span className="help help--success">{successMessage}</span>
      )}
    </>
  );
}

export default InputMessagesWrapper;
