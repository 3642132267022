import React, { PureComponent, ReactElement } from 'react';
import { List, Set } from 'immutable';
import {
  assertRelationIsDefined,
  assertRelationIsObject,
  AvailableSeat,
  AVAILABLE_SEAT_BOOKING_STATUS,
  Order,
} from 'mapado-ticketing-js-sdk';
import { MpdToastFunction, useMpdToast } from '@mapado/makeup';
import SidebarTemplate from '../Sidebar/SidebarTemplate';
import OrderSidebar from '../OrderSidebar';
import Seating from '../Seating';
import EventDateCaptionWithFetch from '../Caption/EventDateCaption';
import SeatingLoader from '../SeatingLoader';
import SvgDrawer from '../../utils/SvgDrawer';
import { AvailableSeatType, SeatConfigType } from '../../propTypes';
import '../../styles/components/App.scss';
import '../../styles/contexts/order.css';
import { getSelectableSeatIdSetForReplacement } from '../../utils/seatSelectable';
import { SelectBatchOfSeats } from '../../utils/drawers/FreeHandDrawSeatSelector';
import OrderReplacementSeat from '../Seat/OrderReplacementSeat';
import { getSeatIdForAvailableSeat } from '../../utils/entity';
import { OrderContextProvider } from '../OrderReplacement/OrderContext';
import useOrder from '../useOrder';
import {
  selectBatchOfSeats,
  SelectOrMoveSeatAction,
} from '../../actions/SeatActions';
import { ReplaceAvailableSeatFunction } from '../useCart';
import { TFunction, useTranslation } from '../../i18n';
import {
  SeatingDispatch,
  SeatingThunkDispatch,
  useSeatingDispatch,
} from '../../reducers/typedFunctions';
import { getMovableAvailableSeatListFromOrder } from '../../utils/seat';

class OrderReplacement extends PureComponent<Props> {
  static defaultProps = {
    onDismissSeating: null,
    onConfirmAction: null,
  };

  // eslint-disable-next-line react/sort-comp
  #svgDrawer: SvgDrawer;

  constructor(props: Props) {
    super(props);

    this.init = this.init.bind(this);
    this.setRef = this.setRef.bind(this);
    this.selectOrMoveSeat = this.selectOrMoveSeat.bind(this);
    this.toggleSelectAllSeat = this.toggleSelectAllSeat.bind(this);

    this.#svgDrawer = new SvgDrawer({
      // eslint-disable-next-line react/destructuring-assignment
      selectBatchOfSeats: this.props.selectBatchOfSeats,
    });
  }

  componentDidMount(): void {
    const { orderId } = this.props;

    if (!orderId) {
      throw new Error('OrderId is required context');
    }

    this.init();
  }

  componentDidUpdate(prevProps: Props): void {
    const { selectedSeatIdSet } = this.props;

    if (!prevProps.selectedSeatIdSet?.equals(selectedSeatIdSet)) {
      this.#svgDrawer.handleSelectedSeatIdListChange(
        selectedSeatIdSet ?? Set()
      );
    }
  }

  componentWillUnmount(): void {
    const { resetState } = this.props;

    resetState();
  }

  setRef(ref: HTMLDivElement): void {
    if (ref) {
      this.#svgDrawer.init();
    }
  }

  init(): void {
    const { eventDateId, orderId, initOrderReplacement } = this.props;

    initOrderReplacement(orderId, eventDateId);
  }

  selectOrMoveSeat(
    seatEntity: AvailableSeatType,
    isSelected: boolean,
    isSelectable: boolean
  ): void {
    const { selectOrMoveSeat, order, replaceAvailableSeat, toast, t } =
      this.props;

    if (!order) {
      return;
    }

    const seatId = getSeatIdForAvailableSeat(seatEntity);

    const isCurrentBooking = [
      AVAILABLE_SEAT_BOOKING_STATUS.IN_ORDER,
      AVAILABLE_SEAT_BOOKING_STATUS.IN_EXTERNAL_PROVIDER_ORDER,
    ].includes(seatEntity.bookingStatus);

    selectOrMoveSeat(
      order,
      seatId,
      isSelectable,
      isCurrentBooking,
      null,
      replaceAvailableSeat,
      this.init,
      toast,
      t
    );
  }

  toggleSelectAllSeat(): void {
    const { selectedSeatIdSet, order, dispatch } = this.props;

    assertRelationIsDefined(order);

    const movableSeatList = getMovableAvailableSeatListFromOrder(order);
    const isSelectedSeatEqualTotalSeat =
      selectedSeatIdSet && selectedSeatIdSet.size === movableSeatList.size;

    if (isSelectedSeatEqualTotalSeat) {
      dispatch(selectBatchOfSeats(Set()));
    } else {
      const availableSeatIdList = movableSeatList
        .map((availableSeat) => {
          const seat = availableSeat.get('seat');

          assertRelationIsObject(seat);

          return Number(seat.getShortId());
        })
        .toSet();

      dispatch(selectBatchOfSeats(availableSeatIdList));
    }
  }

  render(): ReactElement {
    const {
      selectedSeatIdSet,
      isReady,
      getSelectableSeatIdSet,
      eventDateId,
      seatConfig,
      onDismissSeating,
      order,
      t,
      onConfirmAction,
    } = this.props;

    if (!isReady) {
      return <SeatingLoader />;
    }

    if (!seatConfig) {
      throw new Error('Unable to find seatConfig. This should not happen.');
    }

    return (
      <div className="mpd-seating__app context-order" id="mpd-seating__app">
        <div className="mpd-seating__app__container" ref={this.setRef}>
          <div className="mpd-seating__app__event-svg-wrapper">
            <EventDateCaptionWithFetch eventDateId={eventDateId} />
            <Seating
              seatEntityType="AvailableSeat"
              SeatElement={OrderReplacementSeat}
              getSelectableSeatIdSet={(state, seatIdSet) => {
                if (order) {
                  return getSelectableSeatIdSet(state, seatIdSet, order);
                }

                return Set();
              }}
              onClickSeat={this.selectOrMoveSeat}
              seatConfigBlockList={
                seatConfig ? seatConfig.seatConfigBlockList : []
              }
              isMovingSeat={!!(selectedSeatIdSet && selectedSeatIdSet.size > 0)}
            />
          </div>
          <SidebarTemplate
            onClose={onDismissSeating}
            action={
              <>
                <button
                  type="button"
                  className="mpd-btn"
                  onClick={this.toggleSelectAllSeat}
                >
                  {order &&
                  selectedSeatIdSet &&
                  selectedSeatIdSet.size ===
                    getMovableAvailableSeatListFromOrder(order).size
                    ? t('cart_replacement.deselect_all')
                    : t('cart_replacement.select_all')}
                </button>
                {onConfirmAction && (
                  <button
                    type="button"
                    className="mpd-btn mpd-btn--primary"
                    onClick={onConfirmAction}
                  >
                    {t('cart_replacement.confirm')}
                  </button>
                )}
              </>
            }
          >
            {order && (
              <OrderSidebar
                seatConfig={seatConfig}
                selectedSeatIdSet={selectedSeatIdSet}
                order={order}
              />
            )}
          </SidebarTemplate>
        </div>
      </div>
    );
  }
}

export type OrderReplacementProps = {
  eventDateId: number;
  orderId: number;
  onDismissSeating?: null | (() => void);
  onConfirmAction?: () => void;
  // eslint-disable-next-line react/no-unused-prop-types, react/require-default-props
  defaultSelectedSeatIdList?: List<AvailableSeat>;
};

type PropsFromContainer = {
  toast: MpdToastFunction;
  t: TFunction;
  order: Order | null;
  replaceAvailableSeat: ReplaceAvailableSeatFunction;
  dispatch: SeatingDispatch & SeatingThunkDispatch;
};

export type StateProps = {
  seatConfig: null | SeatConfigType;
  isReady: boolean;
  getSelectableSeatIdSet: typeof getSelectableSeatIdSetForReplacement;
  selectedSeatIdSet: Set<number> | null;
};

export type DispatchProps = {
  resetState: () => void;
  initOrderReplacement: (orderId: number, eventDateId: number) => void;
  selectBatchOfSeats: SelectBatchOfSeats;
  selectOrMoveSeat: SelectOrMoveSeatAction;
};

type Props = OrderReplacementProps &
  PropsFromContainer &
  StateProps &
  DispatchProps;

export default function OrderReplacementContainer(
  props: Omit<Props, keyof PropsFromContainer>
): ReactElement {
  const { orderId, eventDateId, defaultSelectedSeatIdList } = props;
  const toast = useMpdToast();
  const { t } = useTranslation();
  const [order, replaceAvailableSeat] = useOrder(
    orderId,
    eventDateId,
    defaultSelectedSeatIdList
  );
  const dispatch = useSeatingDispatch();

  return (
    <OrderContextProvider order={order}>
      <OrderReplacement
        {...props}
        order={order}
        replaceAvailableSeat={replaceAvailableSeat}
        toast={toast}
        t={t}
        dispatch={dispatch}
      />
    </OrderContextProvider>
  );
}
