import React, { CSSProperties, ReactElement } from 'react';
import CountUp from '../CountUp';

type DefaultProps = {
  size: number;
  stroke: number;
  style: CSSProperties;
  className: string;
};

type Props = DefaultProps & {
  current: number;
  // eslint-disable-next-line react/require-default-props
  max?: number;
  // eslint-disable-next-line react/require-default-props
  maxLabel?: string;
  // eslint-disable-next-line react/require-default-props
  currentLabel?: string;
  // eslint-disable-next-line react/require-default-props
  strokeColor?: string;
};

type State = {
  progress: number;
};

const SIZE_THRESHOLD = 80;

class MpdCircleProgress extends React.PureComponent<Props, State> {
  static defaultProps: DefaultProps = {
    // eslint-disable-next-line react/default-props-match-prop-types
    size: 90,
    // eslint-disable-next-line react/default-props-match-prop-types
    stroke: 4,
    // eslint-disable-next-line react/default-props-match-prop-types
    style: {},
    // eslint-disable-next-line react/default-props-match-prop-types
    className: '',
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      progress: 0,
    };
  }

  componentDidMount(): void {
    // Set a timeout to display the animation
    setTimeout(() => {
      this.setState({ progress: this.props.current });
    }, 10);
  }

  componentDidUpdate(prevProps: Props): void {
    const { current } = this.props;

    if (prevProps.current !== current) {
      // Set a timeout to display the animation
      setTimeout(() => {
        this.setState({ progress: current });
      }, 10);
    }
  }

  get percentage(): number {
    const { max } = this.props;

    if (max === 0) {
      return 0;
    }

    if (!max) {
      throw new Error('"max" props can not be null or undefined.');
    }

    return (this.state.progress / max) * 100;
  }

  renderLabel(): ReactElement {
    const { current, max, maxLabel, currentLabel, stroke } = this.props;

    if (maxLabel && currentLabel) {
      const labelStyle = { padding: `0 ${stroke * 2}px` };

      return (
        <>
          <p style={labelStyle}>
            <CountUp className="value" final={current} />
            <span className="content">{currentLabel}</span>
          </p>
          {max !== null && (
            <p className="max" style={labelStyle}>
              <span className="value">{max}</span>
              <span className="content">{maxLabel}</span>
            </p>
          )}
        </>
      );
    }

    return (
      <CountUp
        className="percent"
        final={Math.round(this.percentage)}
        suffix=" %"
      />
    );
  }

  render(): ReactElement {
    const { size, stroke, style, className, strokeColor } = this.props;

    const radius = (size - stroke) / 2;
    const viewBox = `0 0 ${size} ${size}`;
    const dashArray = radius * Math.PI * 2;
    const dashOffset =
      dashArray -
      dashArray * (Math.max(Math.min(this.percentage, 100), 0) / 100);

    return (
      <div
        className={`progress progress--circle ${className}`}
        style={{ width: size, height: size, ...style }}
      >
        <svg width={size} height={size} viewBox={viewBox}>
          <circle
            className="progress__background"
            cx={size / 2}
            cy={size / 2}
            r={radius}
            strokeWidth={`${stroke}px`}
          />
          <circle
            className="progress__active"
            cx={size / 2}
            cy={size / 2}
            r={radius}
            strokeWidth={`${stroke}px`}
            transform={`rotate(-90 ${size / 2} ${size / 2})`}
            style={{
              strokeDasharray: dashArray,
              strokeDashoffset: dashOffset,
              stroke: strokeColor,
            }}
          />
        </svg>
        {size >= SIZE_THRESHOLD && (
          <div
            className="progress__label"
            style={{ width: size, height: size }}
          >
            {this.renderLabel()}
          </div>
        )}
      </div>
    );
  }
}

export default MpdCircleProgress;
