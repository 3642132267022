import React, { FunctionComponent } from 'react';
import { components, OptionProps } from 'react-select';
import cn from 'classnames';
import { SelectGroupedOption, SingleSelectOption } from '../common/utils';
import MpdIcon from '../../../MpdIcon';

const Option: FunctionComponent<
  OptionProps<
    SingleSelectOption,
    false,
    SelectGroupedOption<SingleSelectOption>
  >
> = (props: OptionProps<SingleSelectOption>) => {
  const {
    children,
    data: { iconName, iconColor, asideLabel, subLabel, imageUrl },
    selectProps: { isGrouped },
    isSelected,
    isDisabled,
  } = props;

  return (
    <>
      <components.Option
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        className={cn({
          'mpd-single-select-option--grouped': isGrouped,
          'mpd-single-select-option--selected': isSelected,
        })}
      >
        <div
          className={cn('mpd-single-select-label', {
            'mpd-color-black': !isDisabled,
          })}
        >
          {iconName && (
            <MpdIcon
              icon={iconName}
              color={iconColor}
              width="12"
              className="mr2"
            />
          )}
          {imageUrl && (
            <img
              className="mr2"
              src={imageUrl}
              loading="lazy"
              title={imageUrl}
              alt={imageUrl}
              width={20}
            />
          )}
          <div className="flex-column">
            <span className={cn({ bold: !!subLabel })}>{children}</span>
            {subLabel && <small className="small">{subLabel}</small>}
          </div>
        </div>

        {asideLabel && (
          <span className="mpd-single-select-value-aside-label">
            {asideLabel}
          </span>
        )}
      </components.Option>
    </>
  );
};

export default Option;
