import React, { ReactElement } from 'react';
import MpdIcon from '../MpdIcon';
import MpdCard from '../MpdCard';

type Props = {
  onClick: () => void;
  children: string;
};

function MpdFiltersActiveItem({ children, onClick }: Props): ReactElement {
  return (
    <MpdCard active className="mpd-filters-active-item" onClick={onClick}>
      {children}
      <MpdIcon icon="cross" width="12" className="mpd-color-blue ml1" />
    </MpdCard>
  );
}

export default MpdFiltersActiveItem;
