import { Map } from 'immutable';
import { Attribute, ClassMetadata } from 'rest-client-sdk';
import PaymentAttemptClient from '../client/PaymentAttemptClient';
import mapEntityRelationShips from '../entityFactory/mapEntityRelationShips';
import NetworkEntity from './NetworkEntity';
import PAYMENT_ATTEMPT_STATUS from './PaymentAttemptStatus';
import { BaseEntity, PartialEntity } from '.';

export type PaymentAttemptType = BaseEntity<'PaymentAttempt'> & {
  providerMetadata: undefined | Map<string, unknown>;
  status: undefined | PAYMENT_ATTEMPT_STATUS;
};

class PaymentAttempt extends NetworkEntity<PaymentAttemptType>({
  '@id': null,
  '@type': 'PaymentAttempt',
  providerMetadata: undefined,
  status: undefined,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<PaymentAttemptType> = {
      '@id': null,
      '@type': 'PaymentAttempt',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }
}

PaymentAttempt.classMetadata = new ClassMetadata(
  'paymentAttempt',
  'payment_attempts',
  /** @ts-expect-error -- method signature are incompatible */
  PaymentAttemptClient
);
PaymentAttempt.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('providerMetadata'),
  new Attribute('status'),
]);

export default PaymentAttempt;
