import { List, MapOf } from 'immutable';
import { Attribute, ClassMetadata } from 'rest-client-sdk';
import ProviderExportedCartClient from '../client/ProviderExportedCartClient';
import { mapEntityRelationShips } from '../entityFactory';
import NetworkEntity from './NetworkEntity';
import { BaseEntity, PartialEntity } from '.';

export enum PROVIDER_EXPORTED_CART {
  PASS_CULTURE = 'pass_culture',
}

type ProviderPassCultureData = {
  name: null | string;
  description: null | string;
  beginningDatetime: null | string;
  endDatetime: null | string;
  durationMinutes: null | number;
  formats: null | List<string>;
  totalPrice: null | number;
  numberOfTickets: null | number;
  educationalPriceDetail: null | string;
  contactEmail: null | string;
  contactPhone: null | string;
  bookingEmails: null | List<string>;
  bookingLimitDatetime: null | string;
  educationalInstitution: null | string;
  isActive: boolean;
  visualDisabilityCompliant: boolean;
  motorDisabilityCompliant: boolean;
  audioDisabilityCompliant: boolean;
  mentalDisabilityCompliant: boolean;
};

export type ProviderPassCultureDefaultData = ProviderPassCultureData & {
  offerVenue: null | List<MapOf<{ id: number; legalName: string }>>;
  domains: null | List<
    MapOf<{
      id: number;
      name: string;
      nationalProgramList: List<MapOf<{ id: number; name: string }>>;
    }>
  >;
  students: null | List<MapOf<{ id: number; name: string }>>;
};

export type ProviderPassCultureCartData = ProviderPassCultureData & {
  offerVenueId: number;
  domains: List<number>;
  nationalProgramId?: null | number;
  students: List<string>;
};

export type ProviderExportedCartType = BaseEntity<'ProviderExportedCart'> & {
  providerName: PROVIDER_EXPORTED_CART | null;
  providerExportUrl: string | null;
  data: MapOf<ProviderPassCultureCartData> | null;
};

class ProviderExportedCart extends NetworkEntity<ProviderExportedCartType>({
  '@id': null,
  '@type': 'ProviderExportedCart',
  providerName: null,
  providerExportUrl: null,
  data: null,
}) {
  public static classMetadata: ClassMetadata;

  [key: string]: unknown;

  constructor(
    val: PartialEntity<ProviderExportedCartType> = {
      '@id': null,
      '@type': 'ProviderExportedCart',
    }
  ) {
    super(val);

    return mapEntityRelationShips(this, val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/v1/provider_exported_carts/', '') || '';
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
ProviderExportedCart.classMetadata = new ClassMetadata(
  'providerExportedCart',
  'provider_exported_carts',
  /** @ts-expect-error -- method signature are incompatible */
  ProviderExportedCartClient
);
ProviderExportedCart.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('providerName'),
  new Attribute('providerExportUrl'),
  new Attribute('data', 'data', 'object'),
]);

export default ProviderExportedCart;
