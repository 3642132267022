import { MpdIconType } from '@mapado/makeup';
import { AVAILABLE_SEAT_BOOKING_STATUS } from 'mapado-ticketing-js-sdk';

// eslint-disable-next-line import/prefer-default-export
export function getIcon(
  bookingStatus: string,
  inBooking: boolean
): MpdIconType {
  const inCart = bookingStatus === AVAILABLE_SEAT_BOOKING_STATUS.IN_CART;
  const inOrder = bookingStatus === AVAILABLE_SEAT_BOOKING_STATUS.IN_ORDER;
  const inReservation =
    bookingStatus === AVAILABLE_SEAT_BOOKING_STATUS.IN_RESERVATION;
  const isDismissed = bookingStatus === AVAILABLE_SEAT_BOOKING_STATUS.DISMISSED;
  const isAvailable = bookingStatus === AVAILABLE_SEAT_BOOKING_STATUS.AVAILABLE;
  const isScanned = bookingStatus === AVAILABLE_SEAT_BOOKING_STATUS.SCANNED;
  const isExternalProviderOrder =
    bookingStatus === AVAILABLE_SEAT_BOOKING_STATUS.IN_EXTERNAL_PROVIDER_ORDER;
  const isExternalProviderReservation =
    bookingStatus ===
    AVAILABLE_SEAT_BOOKING_STATUS.IN_EXTERNAL_PROVIDER_RESERVATION;

  let icon: MpdIconType = 'seat-filled';

  // seat not purchasable
  if (isDismissed) {
    icon = 'disallow';
  }

  // seat available for purchase (line not really necessary, "seat-filled" is the default)
  if (isAvailable) {
    icon = 'seat-filled';
  }

  if (isExternalProviderOrder) {
    icon = 'external-provider-filled';
  }

  if (isExternalProviderReservation) {
    icon = 'external-provider';
  }

  // seat reserved (order with reservation)
  if (!inBooking && inReservation) {
    icon = 'participant';
  }

  // seat being purchased (order in cart)
  if (!inBooking && inCart) {
    icon = 'cart';
  }

  // purchased seat (order)
  if (!inBooking && inOrder) {
    icon = 'participant-filled';
  }

  // purchased seat (order) and scanned ticket
  if (!inBooking && isScanned) {
    icon = 'valid';
  }

  return icon;
}
