import URI from 'urijs';
import { AvailableSeatStatus } from '../entity/AvailableSeat';
import { TSMetadata } from '../mapping';
import getFieldsString from '../utils';
import AbstractClient, { FieldsParameter } from './AbstractClient';

export type BulkRequestBody = {
  'hydra:member': Array<BulkRequestMember>;
};

export type BulkRequestMember = {
  '@id': null | string;
  '@type'?: 'AvailableSeat';
  seat?: null | string;
  status?: AvailableSeatStatus;
  ticket?: null | string;
  cartItem?: null | string;
  manuallyAssigned?: null | boolean;
  seatGroup?: null | string;
};

function filterBulkRequestBody(body: BulkRequestBody) {
  if (!body['hydra:member']) {
    return {};
  }

  return {
    'hydra:member': body['hydra:member'].map(
      (member: BulkRequestMember): BulkRequestMember => {
        const out: BulkRequestMember = {
          '@id': member['@id'],
          '@type': member['@type'] ?? 'AvailableSeat',
          seat: member.seat,
          status: member.status,
          ticket: member.ticket,
          cartItem: member.cartItem,
          seatGroup: member.seatGroup,
        };

        if (
          member.manuallyAssigned === true ||
          member.manuallyAssigned === false
        ) {
          out.manuallyAssigned = member.manuallyAssigned;
        }

        return out;
      }
    ),
  };
}

class AvailableSeatClient extends AbstractClient<TSMetadata['availableSeat']> {
  getPathBase(): string {
    return '/v1/available_seats';
  }

  getDefaultGroups(): Array<string> {
    return ['available_seat_read', 'available_seat_write'];
  }

  bulkRequest(
    method: string,
    body: BulkRequestBody,
    fields: FieldsParameter
  ): Promise<Response> {
    const uri = new URI(`${this.getPathBase()}/bulk`);
    uri.addSearch({ fields: getFieldsString(fields) });

    return this.authorizedFetch(uri, {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(filterBulkRequestBody(body)),
    });
  }

  putBulk(
    body: BulkRequestBody,
    fields: FieldsParameter = []
  ): Promise<Response> {
    return this.bulkRequest('PUT', body, fields);
  }
}

export default AvailableSeatClient;
