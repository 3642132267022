import React from 'react';
import {
  assertRelationIsObject,
  assertRelationIsDefined,
  Cart,
} from 'mapado-ticketing-js-sdk';
import {
  formatPrice,
  getShortId,
  useDomainContext,
} from '@mapado/js-component';
import CustomerLine from './CustomerLine';
import { useTranslation } from '../../../i18n';

// cart = reservation
function CartTile({ cart, eventDateId, canMoveSeats }: CartTileType) {
  const { t } = useTranslation();
  const { deskDomain } = useDomainContext();

  assertRelationIsDefined(cart.retailValue, 'cart.retailValue');
  assertRelationIsDefined(cart.currency, 'cart.currency');

  const formattedPrice = formatPrice(cart.retailValue, cart.currency);

  assertRelationIsObject(cart, 'cart');

  const cartId = cart.get('@id');

  if (!cartId) {
    throw new Error('Unable to get id for cart. This should not happen.');
  }

  const { sellingDevice, hasMovableSeats } = cart;

  assertRelationIsObject(sellingDevice, 'sellingDevice');

  return (
    <>
      <div className="mpd-seating-booking-tile__container">
        {t('booking.number.booking', { cart: cart.getShortId() })}
        <CustomerLine entity={cart} />
        <h2 className="mpd-seating-booking-tile__price">{formattedPrice}</h2>
        <div>
          {cart.nbTickets !== 0 &&
            t('order.nb_tickets', {
              count: cart.nbTickets ?? 0,
            })}
        </div>

        {cart.comment !== null && (
          <div className="mpd-seating-booking-tile__comment">
            {t('order.comment')} {cart.comment}
          </div>
        )}
      </div>
      <div className="mpd-seating-booking-tile__action-cell">
        <div>
          {canMoveSeats && hasMovableSeats && (
            <a
              href={`${deskDomain}/carts/${getShortId(
                cartId
              )}/move-seats/${getShortId(eventDateId)}`}
              className="mpd-btn mpd-btn--secondary block"
            >
              {t('booking.move_seats')}
            </a>
          )}

          <a
            href={`${deskDomain}/carts/${getShortId(cartId)}/reservation`}
            className="mpd-btn mpd-btn--primary block"
          >
            {t('booking.see_detail_action')}
          </a>
        </div>
      </div>
    </>
  );
}

type CartTileType = {
  cart: Cart;
  eventDateId: string;
  canMoveSeats: boolean;
};

export default CartTile;
