import {
  Booking,
  Order,
  Cart,
  AVAILABLE_SEAT_BOOKING_STATUS,
} from 'mapado-ticketing-js-sdk';
import type { AvailableSeatType } from '../propTypes';

export function isCartEntity(seatElement: AvailableSeatType): boolean {
  return [
    AVAILABLE_SEAT_BOOKING_STATUS.IN_RESERVATION,
    AVAILABLE_SEAT_BOOKING_STATUS.IN_CART,
    AVAILABLE_SEAT_BOOKING_STATUS.IN_EXTERNAL_PROVIDER_RESERVATION,
  ].includes(seatElement.bookingStatus);
}

export function isBooking(
  booking: undefined | null | Cart | Order | Booking
): booking is Booking {
  if (!booking) {
    return false;
  }

  return booking.get('@type', null) === 'Booking';
}

export function isCart(
  booking: undefined | null | Cart | Order | Booking
): booking is Cart {
  if (!booking) {
    return false;
  }

  return booking.get('@type', null) === 'Cart';
}

export function isOrder(
  booking: undefined | null | Cart | Order | Booking
): booking is Order {
  if (!booking) {
    return false;
  }

  return booking.get('@type', null) === 'Order';
}
