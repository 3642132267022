import React, {
  PureComponent,
  RefObject,
  ChangeEvent,
  ReactElement,
} from 'react';
import InputMessagesWrapper from './form/InputMessagesWrapper';
import InputLabel from './form/InputLabel';

type DefaultProps = {
  required: boolean;
  defaultValue: string;
};

type Props = DefaultProps & {
  id: string;
  // eslint-disable-next-line react/require-default-props
  label?: string;
  maxLength: number;
  // eslint-disable-next-line react/require-default-props
  onChange?: (s: string) => void;
  // eslint-disable-next-line react/require-default-props
  errorMessage?: string | boolean | null | undefined;
};

class MpdInputByCharacter extends PureComponent<Props> {
  static defaultProps: DefaultProps = {
    // eslint-disable-next-line react/default-props-match-prop-types
    required: false,
    // eslint-disable-next-line react/default-props-match-prop-types
    defaultValue: '',
  };

  #input: RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);

    this.#input = React.createRef();

    this.handleChange = this.handleChange.bind(this);
  }

  get value(): string | undefined {
    return this.#input.current?.value;
  }

  handleChange(event: ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;
    const { onChange } = this.props;

    if (!onChange) {
      return;
    }

    onChange(value);
  }

  render(): ReactElement {
    const { id, label, maxLength, required, errorMessage, defaultValue } =
      this.props;
    const inputWidth = `${maxLength * 2 + maxLength - 0.1}ch`;

    return (
      <div className="form-group form-group--letter">
        <InputLabel id={id} label={label} required={required} />
        <div className="input-container" style={{ width: inputWidth }}>
          <div>
            <input
              type="text"
              id={id}
              required={required}
              maxLength={maxLength}
              style={{ width: inputWidth }}
              onChange={this.handleChange}
              ref={this.#input}
              defaultValue={defaultValue?.substring(0, maxLength)}
            />
            <InputMessagesWrapper errorMessage={errorMessage} />
          </div>
        </div>
      </div>
    );
  }
}

export default MpdInputByCharacter;
