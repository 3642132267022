import * as React from 'react';
import type { SVGProps } from 'react';
const SvgOption = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 15 15" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="M12 1H3a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2M3 0a3 3 0 0 0-3 3v9a3 3 0 0 0 3 3h9a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3zm-.692 2.647h10.384v.882H2.308zm10.384 1.765H2.308v.882h10.384zM2.308 6.176h10.384v.883H2.308zm10.384 1.765H2.308v.883h10.384zM2.308 9.706h10.384v.882H2.308zm10.384 1.765H2.308v.882h10.384z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgOption;
