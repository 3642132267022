import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAgendaTime = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 18 16" className="mpd-icon" {...props}>
    <path
      fillRule="evenodd"
      d="M4 0H2.667v1.333H0v12h8.577A5 5 0 0 1 8.1 12H1.333V6.667h9.17a4.98 4.98 0 0 1 2.83-.656V1.333h-2.666V0H9.333v1.333H4zm5.333 4V2.667H4V4H2.667V2.667H1.333v2.666H12V2.667h-1.333V4zm0 7.667a4.333 4.333 0 1 0 8.667 0 4.333 4.333 0 0 0-8.667 0m7.334 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0M12.944 9.5v2.889h2.89v-.722h-2.167V9.5z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgAgendaTime;
