import URI from 'urijs';
import EventDate from '../entity/EventDate';
import { TSMetadata } from '../mapping';
import getFieldsString, { ShortId } from '../utils';
import AbstractClient, { FieldsParameter } from './AbstractClient';

export enum EVENT_DATE_ACCOUNTABILITY_FILTER {
  TICKETS = 'tickets',
  ITEMS = 'items',
}

class EventDateClient extends AbstractClient<TSMetadata['eventDate']> {
  getPathBase(): string {
    return '/v1/event_dates';
  }

  getAccountability(
    id: number,
    originType: string,
    orderItemFilter = EVENT_DATE_ACCOUNTABILITY_FILTER.TICKETS
  ): Promise<Response> {
    const filename =
      orderItemFilter === EVENT_DATE_ACCOUNTABILITY_FILTER.ITEMS
        ? 'event_date_items'
        : 'event_date';

    const url = URI(
      `/v1/accountability/${id}/${filename}.pdf?user=me&summary=1&originType=${originType}`
    );
    return this.authorizedFetch(url, {
      method: 'GET',
    });
  }

  getDownloadListOfParticipantsByEventDate(
    id: number,
    provider: string,
    format: string
  ): Promise<Response> {
    const url = URI(`/v1/event_dates/${id}/tickets.${provider}.${format}`);
    return this.authorizedFetch(url, {
      method: 'GET',
    });
  }

  putBulk(id: number): Promise<Response> {
    const url = URI(`${this.getPathBase()}/${id}/bulk`);

    return this.authorizedFetch(url, { method: 'PUT' });
  }

  clone(
    id: ShortId,
    eventDate: EventDate,
    fields: FieldsParameter
  ): Promise<EventDate> {
    const url = URI(`${this.getPathBase()}/${id}/clone`);
    url.addSearch({ fields: getFieldsString(fields) });

    return this.deserializeResponse(
      this.authorizedFetch(url, {
        method: 'POST',
        body: JSON.stringify(eventDate),
      }),
      'item'
    );
  }

  cancel(eventDate: EventDate, fields: FieldsParameter): Promise<EventDate> {
    const url = URI(`${this.getPathBase()}/${eventDate.getShortId()}/cancel`);
    url.addSearch({ fields: getFieldsString(fields) });

    return this.deserializeResponse(
      this.authorizedFetch(url, { method: 'PUT', body: '{}' }),
      'item'
    );
  }
}

export default EventDateClient;
