import React, { PureComponent, ReactElement } from 'react';

type DefaultProps = {
  duration: number;
  suffix: string;
};

type Props = DefaultProps & {
  final: number;
  // eslint-disable-next-line react/require-default-props
  className?: string;
};

type State = {
  count: number;
};

class CountUp extends PureComponent<Props, State> {
  static defaultProps: DefaultProps = {
    // eslint-disable-next-line react/default-props-match-prop-types
    duration: 2000,
    // eslint-disable-next-line react/default-props-match-prop-types
    suffix: '',
  };

  #requestID: number | null;

  constructor(props: Props) {
    super(props);

    this.state = {
      count: 0,
    };

    this.#requestID = null;
  }

  componentDidMount(): void {
    this.progress();
  }

  componentDidUpdate(prevProps: Props): void {
    const { final } = this.props;

    if (prevProps.final !== final) {
      this.progress();
    }
  }

  componentWillUnmount(): void {
    if (this.#requestID) {
      window.cancelAnimationFrame(this.#requestID);
    }
  }

  progress(): void {
    const { duration, final } = this.props;
    const { count: startCount } = this.state;
    let startTimestamp: number;

    const step = (timestamp: number) => {
      if (!startTimestamp) {
        startTimestamp = timestamp;
      }

      const progress = Math.min((timestamp - startTimestamp) / duration, 1);

      const newCount = startCount + (final - startCount) * progress;

      this.setState({ count: Math.round(newCount) });

      if (progress < 1) {
        this.#requestID = window.requestAnimationFrame(step);
      }
    };

    this.#requestID = window.requestAnimationFrame(step);
  }

  render(): ReactElement {
    const { suffix, ...props } = this.props;
    const { count } = this.state;

    // prettier-ignore
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <span {...props}>
        {count}{suffix}
      </span>
    );
    // prettier-ignore-end
  }
}

export default CountUp;
