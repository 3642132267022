import delegate from './delegate';

let isInitialized = false;

function mpdTabInit(): void {
  if (isInitialized) {
    return;
  }

  isInitialized = true;
  delegate(
    document,
    'change',
    '.mpd-tabs__tab-selector',
    function onSelectTab(event: Event): void {
      const evtTarget = event.target as Element;
      const parentTabNode = evtTarget.closest('.mpd-tabs');

      if (!parentTabNode) {
        throw new Error('.mpd-tabs should be defined. This should not happen.');
      }

      const targetId = evtTarget.getAttribute('data-target');

      if (!targetId) {
        throw new Error('Unable to get "data-target" for change target.');
      }

      const targetOpenedTab = document.getElementById(targetId);

      if (!targetOpenedTab) {
        throw new Error(
          'Unable to get target for "data-target" attribute. Check that you defined an "id" matching your "data-target" attribute.'
        );
      }

      const openedTabContentToHide = parentTabNode.querySelectorAll(
        '.mpd-tabs__content.opened'
      );

      const openedTabToUnselect = parentTabNode.querySelectorAll(
        '.mpd-tabs__tab.selected'
      );

      for (let i = 0; i < openedTabContentToHide.length; i++) {
        openedTabContentToHide[i].classList.remove('opened');
      }

      for (let i = 0; i < openedTabToUnselect.length; i++) {
        openedTabToUnselect[i].classList.remove('selected');
      }

      targetOpenedTab.classList.add('opened');
      evtTarget.closest('.mpd-tabs__tab')?.classList.add('selected');
    }
  );
}

export default mpdTabInit;
