import { useMemo } from 'react';
import { SellingDevice } from 'mapado-ticketing-js-sdk';
import { useCartDispatch } from '../../../selector/typedFunctions';
import type { BookingActionTypes, BookingThunkAction } from '../reducer';
import { SET_SELLING_DEVICE } from './BookingChoiceActionTypes';
import { setBookingOriginTypeEnv } from './bookingChoiceAction';
import {
  discardCart,
  discardOrDeleteCart,
  dismissCartBookingError,
  freeCartId,
  lockSeatsForEventDate,
  removeCartItemFromCurrentCart,
  setTicketPriceQuantity,
  updateComment,
  updateCustomers,
} from './cartAction';
import { loadCart, setCartAsShoppingCart } from './loadCart';

export function useSetTicketPriceQuantity() {
  const cartDispatch = useCartDispatch();

  return (...args: Parameters<typeof setTicketPriceQuantity>) =>
    cartDispatch(setTicketPriceQuantity(...args));
}

export function useLockSeatsForEventDate() {
  const cartDispatch = useCartDispatch();

  return (...args: Parameters<typeof lockSeatsForEventDate>) =>
    cartDispatch(lockSeatsForEventDate(...args));
}

export function useRemoveCartItemFromCurrentCart() {
  const cartDispatch = useCartDispatch();

  return (...args: Parameters<typeof removeCartItemFromCurrentCart>) =>
    cartDispatch(removeCartItemFromCurrentCart(...args));
}

function useActionHook<
  F extends (...args: Args) => BookingActionTypes | BookingThunkAction,
  Args extends unknown[] = Parameters<F>,
>(fn: F) {
  const cartDispatch = useCartDispatch();

  return useMemo(
    () =>
      (...args: Args) =>
        // @ts-expect-error probable issue with function as callback, but do work in practice
        cartDispatch(fn(...args)),
    [cartDispatch, fn]
  );
}

export const useLoadCart = () => useActionHook(loadCart);

export const useSetCartAsShoppingCart = () =>
  useActionHook(setCartAsShoppingCart);

export const useDiscardCart = () => useActionHook(discardCart);

export const useDiscardOrDeleteCart = () => useActionHook(discardOrDeleteCart);

export const useFreeCartId = () => useActionHook(freeCartId);

export const useSetBookingOriginTypeEnv = () =>
  useActionHook(setBookingOriginTypeEnv);

export const useUpdateComment = () => useActionHook(updateComment);
export const useUpdateCustomers = () => useActionHook(updateCustomers);

export const useSetSellingDevice = () =>
  useActionHook((sellingDevice: null | SellingDevice) => ({
    type: SET_SELLING_DEVICE,
    sellingDevice,
  }));

export const useDismissCartBookingError = () =>
  useActionHook(dismissCartBookingError);
