import { List, Map } from 'immutable';
import {
  assertRelationIsListOfObject,
  assertRelationIsNullOrListOfObject,
  Cart,
  CartItemWithOffer,
} from 'mapado-ticketing-js-sdk';
import { Offer } from '../../types';

export const getCartItemWithOfferFacialValue = (
  ciwoList: List<CartItemWithOffer>,
  unit = false
) => {
  return ciwoList.reduce((carry, ciwo) => {
    const facialValue = ciwo.get('facialValue') || 0;
    const quantity = ciwo.get('quantity') || 1;

    return carry + facialValue * (unit ? 1 : quantity);
  }, 0);
};

export interface OfferClusterMap<
  T = {
    offerClusterList: List<OfferCluster>;
    noOfferCartItemWithOfferList: List<CartItemWithOffer>;
  },
> extends Map<keyof T, T[keyof T]> {
  get<K extends keyof T>(key: K): T[K];
}

export interface OfferApplication<
  T = {
    cartItemWithOfferList: List<CartItemWithOffer>;
    applicationId: string;
    offer: Offer;
  },
> extends Map<keyof T, T[keyof T]> {
  get<K extends keyof T>(key: K): T[K];
  set<K extends keyof T>(key: K, value: T[K]): this;
}

type TmpOfferClusterKey =
  | 'offerApplicationMap'
  | 'offerApplicationList'
  | 'offer'
  | 'clusterId';
export interface OfferCluster<
  T = {
    offerApplicationMap: Map<string, OfferApplication>;
    offerApplicationList: List<OfferApplication>;
    offer: Offer;
    clusterId: string;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
> extends Map<keyof T, any> {
  // get(key: 'offerApplicationMap', notSetValue: never): string; // Map<unknown, unknown>;

  get<K extends keyof T>(key: K): T[K];
  // get(key: unknown, notSetValue: never): never;
  set<K extends keyof T>(key: K, value: T[K]): this;
}

interface TmpOfferClusterMap extends Map<string, OfferCluster> {
  // get(key: string): TmpOfferCluster;
  // set<K extends keyof T>(key: K, value: T[K]): this;

  getIn(
    keyPath: [string, 'offerApplicationMap']
  ): Map<string, OfferApplication>;
  getIn(keyPath: [string, 'cartItemWithOfferList']): List<CartItemWithOffer>;
  getIn(keyPath: Iterable<unknown>): unknown;
}

export function getOfferApplicationList(
  cart: Cart
): List<OfferApplication> | null {
  assertRelationIsNullOrListOfObject(
    cart.cartItemWithOfferList,
    'cart.cartItemWithOfferList'
  );

  if (!cart.cartItemWithOfferList) {
    return null;
  }

  const ciwoListGroupedByApplicationId = cart.cartItemWithOfferList
    .filter((ciwo) => ciwo.applicationId) // keep only cartItemWithOffer that have applicationId
    .groupBy((ciwo) => ciwo.get('applicationId'));

  // shape ciwoListGroupedByApplicationId as OfferApplication
  const offerApplicationList = ciwoListGroupedByApplicationId
    .keySeq()
    .map((id: string | null) => {
      const ciwoList = ciwoListGroupedByApplicationId.get(id);
      const offer = ciwoList?.first()?.get('offer');

      // @ts-expect-error -- This should be an offer application
      return Map({
        applicationId: id,
        cartItemWithOfferList: ciwoList,
        offer,
      }) as OfferApplication;
    });

  return offerApplicationList.toList();
}

export function getNoOfferCartItemWithOfferList(
  cart: Cart
): List<CartItemWithOffer> | null {
  assertRelationIsNullOrListOfObject(
    cart.cartItemWithOfferList,
    'cart.cartItemWithOfferList'
  );

  if (!cart.cartItemWithOfferList) {
    return null;
  }

  return cart.cartItemWithOfferList.filter((ciwo) => !ciwo.applicationId);
}

/** @deprecated -- we don't want cluster anymore
 * use `getOfferApplicationList` to get equivalent of `offerClusterList.offerApplicationList` but without cluster grouping
 * and `getNoOfferCartItemWithOfferList` get equivalent of `noOfferCartItemWithOfferList`
 * */
export function generateOfferClusterMap(cart: Cart): OfferClusterMap {
  // @ts-expect-error -- This is the right object
  let tmpOfferClusterMap: TmpOfferClusterMap = Map();

  if (cart.cartItemWithOfferList) {
    assertRelationIsListOfObject(
      cart.cartItemWithOfferList,
      'cart.cartItemWithOfferList'
    );

    cart.cartItemWithOfferList.forEach((ciwo: CartItemWithOffer) => {
      const clusterId = ciwo.clusterId || 'no-offer';

      if (!tmpOfferClusterMap.get(clusterId)) {
        const tmp: OfferCluster = Map<TmpOfferClusterKey, unknown>({
          offerApplicationMap: Map<string, OfferApplication>(),
          offerApplicationList: List(),
          offer: ciwo.offer,
          clusterId,
        });

        tmpOfferClusterMap = tmpOfferClusterMap.set(clusterId, tmp);
      }

      let offerApplicationMap: Map<string, OfferApplication> =
        tmpOfferClusterMap.getIn([clusterId, 'offerApplicationMap']);
      const applicationId = ciwo.get('applicationId') || 'no-application';

      if (!offerApplicationMap.get(applicationId)) {
        // @ts-expect-error -- This is an offer application
        const offerApplication: OfferApplication = Map({
          cartItemWithOfferList: List<CartItemWithOffer>(),
          applicationId,
          offer: ciwo.offer,
        });

        offerApplicationMap = offerApplicationMap.set(
          applicationId,
          offerApplication
        );
      }

      const cartItemWithOfferList = (
        offerApplicationMap.getIn([
          applicationId,
          'cartItemWithOfferList',
        ]) as List<CartItemWithOffer>
      ).push(ciwo);

      offerApplicationMap = offerApplicationMap.setIn(
        [applicationId, 'cartItemWithOfferList'],
        cartItemWithOfferList
      );

      tmpOfferClusterMap = tmpOfferClusterMap
        .setIn([clusterId, 'offerApplicationMap'], offerApplicationMap)
        .setIn(
          [clusterId, 'offerApplicationList'],
          offerApplicationMap.toList()
        );
    });
  }

  let noApplicationMapOfferClusterMap = Map<string, OfferCluster>();

  tmpOfferClusterMap.forEach((cluster, key) => {
    let newCluster = cluster.delete('offerApplicationMap');

    newCluster.get('offerApplicationList').forEach((application, idx) => {
      newCluster = newCluster.setIn(
        ['offerApplicationList', idx, 'cartItemWithOfferList'],
        application.get('cartItemWithOfferList')
      );
    });
    noApplicationMapOfferClusterMap = noApplicationMapOfferClusterMap.set(
      key,
      newCluster
    );
  });

  const offerClusterList = noApplicationMapOfferClusterMap
    .toList()
    .filter((offerCluster) => offerCluster.get('clusterId') !== 'no-offer');

  const noOfferCartItemWithOfferList =
    (noApplicationMapOfferClusterMap
      .toList()
      .filter((offerCluster) => offerCluster.get('clusterId') === 'no-offer')
      .getIn([
        0,
        'offerApplicationList',
        0,
        'cartItemWithOfferList',
      ]) as List<CartItemWithOffer>) || List<CartItemWithOffer>();

  const offerClusterMap: OfferClusterMap = Map({
    offerClusterList,
    noOfferCartItemWithOfferList,
  });

  return offerClusterMap;
}
