import URI from 'urijs';
import ProviderTicketImportedFile from '../../entity/Module/Import/ProviderTicketImportedFile';
import { TSMetadata } from '../../mapping';
import getFieldsString from '../../utils';
import AbstractClient, { FieldsParameter, QueryParam } from '../AbstractClient';

type methodType = 'handle' | 'retry' | 'delete';

// eslint-disable-next-line no-shadow
export enum DOWNLOAD_TYPE {
  ERROR_REPORT = 'error-report',
  ORIGINAL_FILE = 'original-file',
}

class ProviderTicketImportedFileClient extends AbstractClient<
  TSMetadata['providerTicketImportedFile']
> {
  getPathBase(): string {
    return '/v1/provider_ticket_imported_files';
  }

  uploadFile(
    file: File,
    eventDateId: null | string,
    contractId: string,
    fields: FieldsParameter
  ): Promise<TSMetadata['providerTicketImportedFile']['entity']> {
    const uri = new URI(this.getPathBase());
    uri.addSearch({ fields: getFieldsString(fields) });

    const formData = new FormData();
    formData.append('file', file);
    formData.append('contract', contractId);

    if (eventDateId) {
      formData.append('eventDate', eventDateId);
    }

    return this.deserializeResponse(
      this.authorizedFetch(uri, {
        method: 'POST',
        body: formData,
        headers: {
          'Content-Type': undefined, // force Content-Type to undefined to avoid setting it to `application/json`. With the FormData, it will be set to `multipart/form-data`
        },
      }),
      'item'
    );
  }

  triggerMethod(
    entity: ProviderTicketImportedFile,
    method: methodType,
    fields: FieldsParameter
  ): Promise<ProviderTicketImportedFile> {
    const uri = new URI(`${entity.get('@id')}/${method}`);
    uri.addSearch({ fields: getFieldsString(fields) });

    const newSerializedModel = this.sdk.serializer.normalizeItem(
      entity,
      this.metadata
    );

    const {
      mapping,
      externalProvider,
      scanTrack,
      strategy,
      strategyMode,
    } = newSerializedModel;

    const bodyToEncode =
      method === 'handle'
        ? {
            mapping,
            externalProvider,
            scanTrack,
            strategy,
            strategyMode,
          }
        : {};

    return this.deserializeResponse(
      this.authorizedFetch(uri, {
        method: 'PUT',
        body: this.serializer.encodeItem(bodyToEncode, this.metadata),
      }),
      'item'
    );
  }

  handle(
    entity: ProviderTicketImportedFile,
    fields: FieldsParameter
  ): Promise<ProviderTicketImportedFile> {
    return this.triggerMethod(entity, 'handle', fields);
  }

  retry(
    entity: ProviderTicketImportedFile,
    fields: FieldsParameter
  ): Promise<ProviderTicketImportedFile> {
    return this.triggerMethod(entity, 'retry', fields);
  }

  download(entityId: string, type: DOWNLOAD_TYPE): Promise<Response> {
    const uri = new URI(`${this.getPathBase()}/${entityId}/download/${type}`);
    uri.addSearch({ fields: getFieldsString(['@id']) });

    return this.authorizedFetch(uri, {
      method: 'GET',
    });
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  create(
    entity: ProviderTicketImportedFile,
    queryParam?: QueryParam,
    pathParameters?: Record<string, unknown>
  ): Promise<never> {
    throw new Error(
      'Create method is forbidden on ImportedFileClient. Please use uploadFile method instead.'
    );
  }
}

export default ProviderTicketImportedFileClient;
