import React, { PureComponent, ReactElement, RefObject } from 'react';
import { MpdIconType } from '@mapado/makeup';
import '../../styles/components/BaseSeat.scss';
import {
  AVAILABLE_SEAT_BOOKING_STATUS,
  CustomerType,
  SEAT_TYPE,
} from 'mapado-ticketing-js-sdk';
import {
  DOMAIN_CONTEXT,
  getShortId,
  useDomainContext,
} from '@mapado/js-component';
import { invertColor } from '../../utils';
import {
  apiXToActualX,
  apiYToActualY,
  seatDimensions,
} from '../../utils/seatDimensions';
import {
  SeatEntityFromType,
  SeatEntityType,
  isAvailableSeatType,
} from '../../propTypes';
import SeatIcon from './SeatIcon';
import TicketingSdkInstance from '../../TicketingSdkInstance';

export type OnClickSeatFunction<SE extends SeatEntityType> =
  | null
  | ((
      seatId: SeatEntityFromType<SE>,
      isSelected: boolean,
      isSelectable: boolean
    ) => void);

interface SeatState {
  isHoverActive: boolean;
}

class Seat<SE extends SeatEntityType> extends PureComponent<
  SeatProps<SE>,
  SeatState
> {
  static defaultProps = {
    displaySeatLabel: false,
  };

  // eslint-disable-next-line react/sort-comp
  #gRef: RefObject<SVGGElement>;

  #hammertime: null | HammerManager;

  hoverTimeout: null | NodeJS.Timeout;

  constructor(props: SeatProps<SE>) {
    super(props);

    this.state = {
      isHoverActive: false,
    };

    this.onHover = this.onHover.bind(this);
    this.handleSeatFocus = this.handleSeatFocus.bind(this);
    this.onHoverOut = this.onHoverOut.bind(this);
    this.handleClick = this.handleClick.bind(this);

    this.#gRef = React.createRef<SVGGElement>();
    this.#hammertime = null;
    this.hoverTimeout = null;
  }

  componentDidMount(): void {
    // dynamic import is used to avoid loading hammerjs on the server side
    import('hammerjs').then(({ default: Hammer }) => {
      if (this.#gRef.current) {
        this.#hammertime = new Hammer(this.#gRef.current);
        this.#hammertime.on('tap', this.handleClick);
      }
    });
  }

  componentWillUnmount(): void {
    if (this.#hammertime) {
      this.#hammertime.destroy();
    }

    if (this.hoverTimeout) {
      clearTimeout(this.hoverTimeout);
    }
  }

  handleClick(): void {
    const { isSelectable, isSelected, onClickSeat, seatEntity } = this.props;

    if (onClickSeat) {
      onClickSeat(seatEntity, isSelected, isSelectable);
    }
  }

  handleSeatFocus(seatId: number, participant: CustomerType | null): void {
    const { isHoverActive } = this.state;
    const { onFocusSeat, isDesk } = this.props;

    if (isHoverActive || !isDesk) {
      onFocusSeat(seatId, participant);
    }
  }

  onHover(): void {
    const { seatId, seatEntity, isDesk } = this.props;

    if (this.hoverTimeout) {
      clearTimeout(this.hoverTimeout);
    }

    this.setState({ isHoverActive: true });

    if (isAvailableSeatType(seatEntity) && isDesk) {
      this.hoverTimeout = setTimeout(async () => {
        try {
          const availableSeat = await TicketingSdkInstance.getSdk()
            .getRepository('availableSeat')
            .find(getShortId(seatEntity['@id']), [
              { ticket: [{ participant: ['firstname', 'lastname'] }] },
              { cartItem: [{ participant: ['firstname', 'lastname'] }] },
            ]);

          const ticketParticipant = availableSeat.getIn([
            'ticket',
            'participant',
            // get availableSeat uses rawSerializer, returns Map<any, any>
          ]) as CustomerType;

          const cartItemParticipant = availableSeat.getIn([
            'cartItem',
            'participant',
          ]) as CustomerType;

          const seatParticipant =
            ticketParticipant || cartItemParticipant || null;

          this.handleSeatFocus(seatId, seatParticipant);
        } catch (error) {
          this.handleSeatFocus(seatId, null);
        }
      }, 300);
    } else {
      this.hoverTimeout = setTimeout(() => {
        this.handleSeatFocus(seatId, null);
      }, 0);
    }
  }

  onHoverOut(): void {
    const { onUnfocusSeat, seatId } = this.props;

    this.setState({ isHoverActive: false });

    onUnfocusSeat(seatId);

    if (this.hoverTimeout) {
      clearTimeout(this.hoverTimeout);
    }
  }

  getPosX(): number {
    const { coordinates } = this.props;

    return apiXToActualX(coordinates.X);
  }

  getPosY(): number {
    const { coordinates } = this.props;

    return apiYToActualY(coordinates.Y);
  }

  render(): ReactElement | null {
    const {
      fill,
      label,
      isSelected,
      isZoomed,
      isMovingSeat,
      seatId,
      isSelectable,
      icon,
      displaySeatLabel,
      bookingStatus,
      displayDismissedSeat,
      angle,
      type,
      hasObstructedView,
    } = this.props;

    if (
      !displayDismissedSeat &&
      bookingStatus === AVAILABLE_SEAT_BOOKING_STATUS.DISMISSED
    ) {
      return null;
    }

    const posX = !isZoomed ? this.getPosX() : this.getPosX() - 2;
    const posY = !isZoomed ? this.getPosY() : this.getPosY() - 2;

    const width = !isZoomed
      ? seatDimensions.iconSize
      : seatDimensions.iconZoomedSize;

    const isSelectableClass = isSelectable
      ? 'mpd-seating__seat--is-selectable'
      : '';

    const isSelectedClass = isSelected ? 'mpd-seating__seat--is-selected' : '';

    const isMovingSeatClass = isMovingSeat
      ? 'mpd-seating__seat--is-moving-seat'
      : '';

    return (
      <g
        ref={this.#gRef}
        className={`mpd-seating__seat ${isSelectableClass} ${isSelectedClass} ${isMovingSeatClass}`}
        transform={`translate(${posX}, ${posY})  rotate(${angle ?? 0} 12 12)`}
        id={String(seatId)}
        onMouseEnter={this.onHover}
        onMouseLeave={this.onHoverOut}
      >
        {/* rect with dimensions necessary: sometimes when page is initialized, MpdIcon svg
        has no width/height yet and the page will zoom only on the stage (see DraggableSVG.tsx) */}

        {type !== SEAT_TYPE.STANDING && (
          <rect x="0" y="0" width={width} height={width} fill="transparent" />
        )}

        <SeatIcon
          icon={icon}
          fill={fill}
          width={width}
          type={type}
          hasObstructedView={hasObstructedView}
        />

        {displaySeatLabel && (
          <text
            x="11"
            y="7"
            fill={invertColor(fill, true)}
            dy=".3em"
            textAnchor="middle"
            fontSize="0.6em"
            textRendering="optimizeSpeed"
          >
            {label}
          </text>
        )}
      </g>
    );
  }
}

export type SeatProps<SE extends SeatEntityType> = {
  /** color of the seat */
  fill: string;

  /** callback when a seat is focused */
  onFocusSeat: (seatId: number, participant: CustomerType | null) => void;

  /** callback when a seat is unfocused */
  onUnfocusSeat: (seatId: number) => void;

  /** seat coordinates */
  coordinates: {
    X: string | number;
    Y: string | number;
  };

  /** seat label */
  label: null | string;

  /** rotation of the seat */
  angle: null | number;

  /** is the seat "normal" or "foldable" (or other) */
  type: SEAT_TYPE;

  icon: MpdIconType;

  /** if a seat is selectable : TODO investigate : What is a selectable seat */
  isSelectable: boolean;

  /** if a seat is selected */
  isSelected: boolean;

  /** if a seat is zoomed */
  isZoomed: boolean;

  /** if a seat is changing position */
  isMovingSeat: boolean;

  /** seat id, will be used as id. Must be unique */
  seatId: number;

  /** The full "seat-like" entity. It might be a Seat, an AvailableSeat or an AvailableSeatModel depending on the context */
  seatEntity: SeatEntityFromType<SE>;

  /** callback called when a seat is clicked */
  onClickSeat: OnClickSeatFunction<SE>;

  /** should we display the label of the seat */
  displaySeatLabel?: boolean;

  /** if we want to show dismissed seat. Used only in administation context. */
  displayDismissedSeat: boolean;

  /** the data from AvailableSeat::bookingStatus returned from the API */
  bookingStatus: AVAILABLE_SEAT_BOOKING_STATUS;

  /** if the seat has an obstructed view */
  hasObstructedView: boolean;

  /** if the seat is displayed on the Desk and not minisite */
  isDesk: boolean;
};

function BaseSeatContainer<SE extends SeatEntityType>(props: SeatProps<SE>) {
  const { currentDomain } = useDomainContext();
  const isDesk = currentDomain === DOMAIN_CONTEXT.DESK;

  return <Seat {...props} isDesk={isDesk} />;
}

export default BaseSeatContainer;
