/* eslint-disable no-use-before-define */
import { ThunkAction } from 'redux-thunk';
import { SellingDevice } from 'mapado-ticketing-js-sdk';
import {
  SET_BOOKING_ORIGIN_TYPE_ENV,
  SET_SELLING_DEVICE,
} from '../action/BookingChoiceActionTypes';
import {
  FREE_CART_ID,
  SET_CART_AS_SHOPPING_CART,
  SET_CART_ID,
} from '../action/CartActionTypes';
import {
  CartAtomId,
  CartId,
  ORIGIN_TYPE_ENV,
  SHOPPING_CART,
} from '../constants';
import innerBookingReducer, {
  baseInnerState,
  HasCartAtomId,
  InnerBookingActionTypes,
  InnerBookingStoreType,
} from './innerBookingReducer';

export type StoreWithBooking = { booking: BookingStoreType };

export type BookingThunkAction<ReturnType = void> = ThunkAction<
  ReturnType,
  StoreWithBooking,
  unknown,
  BookingActionTypes
>;

/** Test that the specified cartAtomId is the `SHOPPING_CART` already */
const cartAtomIdIsTheShoppingCart = (
  cartAtomId: CartAtomId,
  multiState: BookingStoreType
): boolean =>
  cartAtomId === multiState[SHOPPING_CART].getIn(['currentCart', '@id']);

function isActionWithCartAtomId(
  action: BookingActionTypes
): action is BookingActionTypes & HasCartAtomId {
  return 'cartAtomId' in action;
}

interface SET_SELLING_DEVICE_ACTION {
  type: typeof SET_SELLING_DEVICE;
  sellingDevice: null | SellingDevice;
}

interface SET_CART_ID_ACTION {
  type: typeof SET_CART_ID;
  cartAtomId: CartId;
}

interface FREE_CART_ID_ACTION {
  type: typeof FREE_CART_ID;
  cartAtomId: CartId;
}

interface SET_CART_AS_SHOPPING_CART {
  type: typeof SET_CART_AS_SHOPPING_CART;
  cartAtomId: CartId;
}

interface SET_BOOKING_ORIGIN_TYPE_ENV_ACTION {
  type: typeof SET_BOOKING_ORIGIN_TYPE_ENV;
  originTypeEnv: ORIGIN_TYPE_ENV;
}

export type BookingActionTypes =
  | SET_CART_ID_ACTION
  | FREE_CART_ID_ACTION
  | SET_CART_AS_SHOPPING_CART
  | SET_SELLING_DEVICE_ACTION
  | SET_BOOKING_ORIGIN_TYPE_ENV_ACTION
  | InnerBookingActionTypes;

export type BookingStoreType = {
  currentCartAtomId: CartAtomId;
  sellingDevice: null | SellingDevice;
  originTypeEnv: null | ORIGIN_TYPE_ENV;
  shoppingCart: InnerBookingStoreType;
  [cartId: CartId]: InnerBookingStoreType;
};

const baseState: BookingStoreType = {
  currentCartAtomId: SHOPPING_CART,
  sellingDevice: null,
  originTypeEnv: null,
  shoppingCart: baseInnerState,
};

function bookingReducer(
  // eslint-disable-next-line default-param-last
  multiState: BookingStoreType = baseState,
  action: BookingActionTypes
): BookingStoreType {
  switch (action.type) {
    case SET_CART_ID: {
      const { cartAtomId } = action;

      if (cartAtomId in multiState) {
        return multiState;
      }

      if (cartAtomIdIsTheShoppingCart(cartAtomId, multiState)) {
        return {
          ...multiState,
          currentCartAtomId: SHOPPING_CART,
        };
      }

      return {
        ...multiState,
        currentCartAtomId: cartAtomId,
        [cartAtomId]: baseInnerState,
      };
    }

    case FREE_CART_ID: {
      const { cartAtomId } = action;

      const nextKey =
        multiState.currentCartAtomId === cartAtomId
          ? SHOPPING_CART
          : multiState.currentCartAtomId;

      const nextState: BookingStoreType = {
        ...multiState,
        currentCartAtomId: nextKey,
      };

      delete nextState[cartAtomId];

      return nextState;
    }

    case SET_CART_AS_SHOPPING_CART: {
      const { cartAtomId } = action;

      if (cartAtomIdIsTheShoppingCart(cartAtomId, multiState)) {
        // if this is already the shopping cart, do nothing
        return {
          ...multiState,
          currentCartAtomId: SHOPPING_CART,
        };
      }

      if (!(cartAtomId in multiState)) {
        throw new Error(
          `Unable to set ${cartAtomId} as shopping cart as it has not be loaded in state already.`
        );
      }

      const innerState = multiState[cartAtomId];

      return {
        ...multiState,
        currentCartAtomId: SHOPPING_CART,
        [SHOPPING_CART]: innerState,
      };
    }

    case SET_SELLING_DEVICE: {
      if (!action.sellingDevice) {
        return {
          ...multiState,
          sellingDevice: null,
        };
      }

      return {
        ...multiState,
        sellingDevice: new SellingDevice(action.sellingDevice),
      };
    }

    case SET_BOOKING_ORIGIN_TYPE_ENV: {
      const { originTypeEnv } = action;

      return {
        ...multiState,
        originTypeEnv,
      };
    }

    default: {
      const cartAtomId =
        isActionWithCartAtomId(action) && action.cartAtomId in multiState
          ? action.cartAtomId
          : multiState.currentCartAtomId;
      const state = multiState[cartAtomId];

      const newState = innerBookingReducer(
        state,
        action,
        multiState.originTypeEnv
      );

      return { ...multiState, [cartAtomId]: newState };
    }
  }
}

export default bookingReducer;

export const testables = { baseState, baseInnerState };
